"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Celestic = void 0;
var Tags_1 = require("../Tags");
var ResourceType_1 = require("../../ResourceType");
var SelectCard_1 = require("../../inputs/SelectCard");
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var CardRenderItem_1 = require("../render/CardRenderItem");
var Celestic = (function (_super) {
    __extends(Celestic, _super);
    function Celestic() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.CELESTIC,
            tags: [Tags_1.Tags.VENUS],
            startingMegaCredits: 42,
            resourceType: ResourceType_1.ResourceType.FLOATER,
            cardType: CardType_1.CardType.CORPORATION,
            initialActionText: 'Draw 2 cards with a floater icon on it',
            metadata: {
                cardNumber: 'R05',
                description: 'You start with 42 M€. As your first action, reveal cards from the deck until you have revealed 2 cards with a floater icon on it. Take them into hand and discard the rest.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(42).nbsp.cards(2).secondaryTag(CardRenderItem_1.AltSecondaryTag.FLOATER);
                    b.corpBox('action', function (ce) {
                        ce.action('Add a floater to ANY card. 1 VP per 3 floaters on this card.', function (eb) {
                            eb.empty().startAction.floaters(1).asterix();
                        });
                        ce.vSpace();
                    });
                }),
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.floaters(1, 3),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    Celestic.prototype.initialAction = function (player) {
        player.drawCard(2, {
            include: function (card) { return Celestic.floaterCards.has(card.name) || card.resourceType === ResourceType_1.ResourceType.FLOATER; },
        });
        return undefined;
    };
    Celestic.prototype.play = function () {
        return undefined;
    };
    Celestic.prototype.canAct = function () {
        return true;
    };
    Celestic.prototype.getVictoryPoints = function () {
        return Math.floor(this.resourceCount / 3);
    };
    Celestic.prototype.action = function (player) {
        var floaterCards = player.getResourceCards(ResourceType_1.ResourceType.FLOATER);
        if (floaterCards.length === 1) {
            player.addResourceTo(this, { qty: 1, log: true });
            return undefined;
        }
        return new SelectCard_1.SelectCard('Select card to add 1 floater', 'Add floater', floaterCards, function (foundCards) {
            player.addResourceTo(foundCards[0], { log: true });
            return undefined;
        });
    };
    Celestic.floaterCards = new Set([
        CardName_1.CardName.AEROSPORT_TOURNAMENT,
        CardName_1.CardName.AIR_SCRAPPING_EXPEDITION,
        CardName_1.CardName.AIR_RAID,
        CardName_1.CardName.AIRLINERS,
        CardName_1.CardName.ATMOSCOOP,
        CardName_1.CardName.FLOATER_LEASING,
        CardName_1.CardName.FLOATER_PROTOTYPES,
        CardName_1.CardName.FLOATER_TECHNOLOGY,
        CardName_1.CardName.HYDROGEN_TO_VENUS,
        CardName_1.CardName.NITROGEN_FROM_TITAN,
        CardName_1.CardName.STRATOSPHERIC_BIRDS,
    ]);
    return Celestic;
}(Card_1.Card));
exports.Celestic = Celestic;
