"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.GrandLunaAcademy = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var GrandLunaAcademy = (function (_super) {
    __extends(GrandLunaAcademy, _super);
    function GrandLunaAcademy() {
        return _super.call(this, {
            name: CardName_1.CardName.GRAND_LUNA_ACADEMY,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.MOON],
            cost: 13,
            metadata: {
                description: 'Draw 1 card per 2 Moon tags you have, including this.',
                cardNumber: 'M83',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.cards(1).slash().moon(2).digit.played;
                }),
            },
        }) || this;
    }
    ;
    GrandLunaAcademy.prototype.play = function (player) {
        var tags = player.getTagCount(Tags_1.Tags.MOON);
        var gain = Math.floor((tags + 1) / 2);
        player.drawCard(gain);
        return undefined;
    };
    return GrandLunaAcademy;
}(Card_1.Card));
exports.GrandLunaAcademy = GrandLunaAcademy;
