"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VolcanicEruptions = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var VolcanicEruptions = (function () {
    function VolcanicEruptions() {
        this.name = GlobalEventName_1.GlobalEventName.VOLCANIC_ERUPTIONS;
        this.description = 'Increase temperature 2 steps. Increase heat production 1 step per influence.';
        this.revealedDelegate = PartyName_1.PartyName.SCIENTISTS;
        this.currentDelegate = PartyName_1.PartyName.KELVINISTS;
    }
    VolcanicEruptions.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.increaseTemperature(game.getPlayers()[0], 2);
        game.getPlayers().forEach(function (player) {
            var amount = turmoil.getPlayerInfluence(player);
            if (amount > 0) {
                player.addProduction(Resources_1.Resources.HEAT, amount, { log: true, from: _this.name });
            }
        });
    };
    return VolcanicEruptions;
}());
exports.VolcanicEruptions = VolcanicEruptions;
