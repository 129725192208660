"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Recyclon = void 0;
var Tags_1 = require("../Tags");
var ResourceType_1 = require("../../ResourceType");
var Resources_1 = require("../../Resources");
var SelectOption_1 = require("../../inputs/SelectOption");
var OrOptions_1 = require("../../inputs/OrOptions");
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var Recyclon = (function (_super) {
    __extends(Recyclon, _super);
    function Recyclon() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.RECYCLON,
            tags: [Tags_1.Tags.MICROBE, Tags_1.Tags.BUILDING],
            startingMegaCredits: 38,
            resourceType: ResourceType_1.ResourceType.MICROBE,
            productionBox: Units_1.Units.of({ steel: 1 }),
            metadata: {
                cardNumber: 'R26',
                description: 'You start with 38 M€ and 1 steel production.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br.br;
                    b.megacredits(38).nbsp.production(function (pb) { return pb.steel(1); });
                    b.corpBox('effect', function (ce) {
                        ce.effect('When you play a building tag, including this, gain 1 microbe to this card, or remove 2 microbes here and raise your plant production 1 step.', function (eb) {
                            eb.building().played.colon().microbes(1).or();
                            eb.microbes(2).digit.startEffect.production(function (pb) { return pb.plants(1); });
                        });
                    });
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    Recyclon.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.STEEL, 1);
        player.addResourceTo(this);
        return undefined;
    };
    Recyclon.prototype.onCardPlayed = function (player, card) {
        var _this = this;
        if (card.tags.includes(Tags_1.Tags.BUILDING) === false || !player.isCorporation(this.name)) {
            return undefined;
        }
        if (this.resourceCount < 2) {
            player.addResourceTo(this);
            return undefined;
        }
        var addResource = new SelectOption_1.SelectOption('Add a microbe resource to this card', 'Add microbe', function () {
            player.addResourceTo(_this);
            return undefined;
        });
        var spendResource = new SelectOption_1.SelectOption('Remove 2 microbes on this card and increase plant production 1 step', 'Remove microbes', function () {
            player.removeResourceFrom(_this, 2);
            player.addProduction(Resources_1.Resources.PLANTS, 1);
            return undefined;
        });
        return new OrOptions_1.OrOptions(spendResource, addResource);
    };
    return Recyclon;
}(Card_1.Card));
exports.Recyclon = Recyclon;
