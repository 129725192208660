"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiningComplex = void 0;
var CardName_1 = require("../../CardName");
var Tags_1 = require("../Tags");
var PreludeCard_1 = require("../prelude/PreludeCard");
var CardRenderer_1 = require("../render/CardRenderer");
var PlaceMoonMineTile_1 = require("../../moon/PlaceMoonMineTile");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var PlaceMoonRoadTile_1 = require("../../moon/PlaceMoonRoadTile");
var SpaceType_1 = require("../../SpaceType");
var Resources_1 = require("../../Resources");
var CardRenderItem_1 = require("../render/CardRenderItem");
var MiningComplex = (function (_super) {
    __extends(MiningComplex, _super);
    function MiningComplex() {
        return _super.call(this, {
            name: CardName_1.CardName.MINING_COMPLEX,
            tags: [Tags_1.Tags.MOON],
            metadata: {
                description: 'Place a mine tile on the Moon and raise the Mining Rate 1 step. ' +
                    'Place a road tile adjacent to placed mine tile and raise the Logistics Rate 1 step. ' +
                    'Pay 7 M€.',
                cardNumber: '',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    return b.moonMine().secondaryTag(CardRenderItem_1.AltSecondaryTag.MOON_MINING_RATE).moonRoad().secondaryTag(CardRenderItem_1.AltSecondaryTag.MOON_LOGISTICS_RATE).asterix().br.minus().megacredits(7);
                }),
            },
        }) || this;
    }
    ;
    MiningComplex.prototype.play = function (player) {
        player.game.defer(new PlaceMoonMineTile_1.PlaceMoonMineTile(player)
            .andThen(function (space) {
            var moon = MoonExpansion_1.MoonExpansion.moonData(player.game).moon;
            var spaces = moon.getAdjacentSpaces(space);
            var availableRoadSpaces = spaces.filter(function (space) {
                return space.player === undefined && space.spaceType === SpaceType_1.SpaceType.LAND;
            });
            player.game.defer(new PlaceMoonRoadTile_1.PlaceMoonRoadTile(player, 'Select a space next to the mine for a road', availableRoadSpaces));
        }));
        player.deductResource(Resources_1.Resources.MEGACREDITS, 7);
        return undefined;
    };
    return MiningComplex;
}(PreludeCard_1.PreludeCard));
exports.MiningComplex = MiningComplex;
