"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Comet = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var constants_1 = require("../../constants");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var PlaceOceanTile_1 = require("../../deferredActions/PlaceOceanTile");
var RemoveAnyPlants_1 = require("../../deferredActions/RemoveAnyPlants");
var CardRenderer_1 = require("../render/CardRenderer");
var Comet = (function (_super) {
    __extends(Comet, _super);
    function Comet() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.COMET,
            tags: [Tags_1.Tags.SPACE],
            cost: 21,
            metadata: {
                cardNumber: '010',
                description: 'Raise temperature 1 step and place an ocean tile. Remove up to 3 Plants from any player.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.temperature(1).oceans(1).br;
                    b.minus().plants(-3).any;
                }),
            },
        }) || this;
    }
    Comet.prototype.canPlay = function (player) {
        var temperatureStep = player.game.getTemperature() < constants_1.MAX_TEMPERATURE ? 1 : 0;
        var oceanStep = player.game.board.getOceansOnBoard() < constants_1.MAX_OCEAN_TILES ? 1 : 0;
        var totalSteps = temperatureStep + oceanStep;
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS)) {
            return player.canAfford(player.getCardCost(this) + constants_1.REDS_RULING_POLICY_COST * totalSteps, { titanium: true });
        }
        return true;
    };
    Comet.prototype.play = function (player) {
        player.game.increaseTemperature(player, 1);
        player.game.defer(new PlaceOceanTile_1.PlaceOceanTile(player));
        player.game.defer(new RemoveAnyPlants_1.RemoveAnyPlants(player, 3));
        return undefined;
    };
    return Comet;
}(Card_1.Card));
exports.Comet = Comet;
