"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalHeatTrapping = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var SelectCard_1 = require("../../inputs/SelectCard");
var ResourceType_1 = require("../../ResourceType");
var CardName_1 = require("../../CardName");
var Resources_1 = require("../../Resources");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var LocalHeatTrapping = (function (_super) {
    __extends(LocalHeatTrapping, _super);
    function LocalHeatTrapping() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.LOCAL_HEAT_TRAPPING,
            cost: 1,
            reserveUnits: Units_1.Units.of({ heat: 5 }),
            metadata: {
                cardNumber: '190',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().heat(5).digit;
                    b.plus().plants(4).digit;
                    b.or().animals(2).digit.asterix();
                }),
                description: 'Spend 5 heat to gain either 4 Plants, or to add 2 Animals to ANOTHER card.',
            },
        }) || this;
    }
    LocalHeatTrapping.prototype.play = function (player) {
        var animalCards = player.getResourceCards(ResourceType_1.ResourceType.ANIMAL);
        var availableActions = new OrOptions_1.OrOptions();
        var gain4Plants = function () {
            player.addResource(Resources_1.Resources.PLANTS, 4, { log: true });
            return undefined;
        };
        if (animalCards.length === 0) {
            availableActions.options.push(new SelectOption_1.SelectOption('Gain 4 plants', 'Gain plants', gain4Plants));
        }
        else if (animalCards.length === 1) {
            var targetCard_1 = animalCards[0];
            availableActions.options.push(new SelectOption_1.SelectOption('Gain 4 plants', 'Gain plants', gain4Plants), new SelectOption_1.SelectOption('Add 2 animals to ' + targetCard_1.name, 'Add animals', function () {
                player.addResourceTo(targetCard_1, { qty: 2, log: true });
                return undefined;
            }));
        }
        else {
            availableActions.options.push(new SelectOption_1.SelectOption('Gain 4 plants', 'Gain plants', gain4Plants), new SelectCard_1.SelectCard('Select card to add 2 animals', 'Add animals', animalCards, function (foundCards) {
                player.addResourceTo(foundCards[0], { qty: 2, log: true });
                return undefined;
            }));
        }
        return player.spendHeat(5, function () {
            if (availableActions.options.length === 1)
                return availableActions.options[0].cb();
            return availableActions;
        });
    };
    return LocalHeatTrapping;
}(Card_1.Card));
exports.LocalHeatTrapping = LocalHeatTrapping;
