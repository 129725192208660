"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MooncrateConvoysToMars = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var CardRequirements_1 = require("../CardRequirements");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var SellSteel_1 = require("../../moon/SellSteel");
var MooncrateConvoysToMars = (function (_super) {
    __extends(MooncrateConvoysToMars, _super);
    function MooncrateConvoysToMars() {
        return _super.call(this, {
            name: CardName_1.CardName.MOONCRATE_CONVOYS_TO_MARS,
            cardType: CardType_1.CardType.EVENT,
            cost: 13,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.party(PartyName_1.PartyName.MARS); }),
            metadata: {
                description: 'Requires that Mars First are ruling or that you have 2 delegates there. ' +
                    'Raise the Logistic Rate 1 step. All players may sell their steel resources for 3M€ each.',
                cardNumber: 'M60',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.moonLogisticsRate().br;
                    b.text('X').steel(1).any.colon().text('X').megacredits(3);
                }),
            },
        }) || this;
    }
    ;
    MooncrateConvoysToMars.prototype.play = function (player) {
        MoonExpansion_1.MoonExpansion.raiseLogisticRate(player, 1);
        var game = player.game;
        game.getPlayers().forEach(function (player) {
            game.defer(new SellSteel_1.SellSteel(player));
        });
        return undefined;
    };
    return MooncrateConvoysToMars;
}(Card_1.Card));
exports.MooncrateConvoysToMars = MooncrateConvoysToMars;
