"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportedHydrogen = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var SelectCard_1 = require("../../inputs/SelectCard");
var ResourceType_1 = require("../../ResourceType");
var CardName_1 = require("../../CardName");
var Resources_1 = require("../../Resources");
var constants_1 = require("../../constants");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var PlaceOceanTile_1 = require("../../deferredActions/PlaceOceanTile");
var CardRenderer_1 = require("../render/CardRenderer");
var ImportedHydrogen = (function (_super) {
    __extends(ImportedHydrogen, _super);
    function ImportedHydrogen() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.IMPORTED_HYDROGEN,
            tags: [Tags_1.Tags.EARTH, Tags_1.Tags.SPACE],
            cost: 16,
            metadata: {
                cardNumber: '019',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.plants(3).digit;
                    b.or();
                    b.microbes(3).digit.asterix().or();
                    b.animals(2).digit.asterix().br;
                    b.oceans(1);
                }),
                description: 'Gain 3 Plants, or add 3 Microbes or 2 Animals to ANOTHER card. Place an ocean tile.',
            },
        }) || this;
    }
    ImportedHydrogen.prototype.canPlay = function (player) {
        var oceansMaxed = player.game.board.getOceansOnBoard() === constants_1.MAX_OCEAN_TILES;
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS) && !oceansMaxed) {
            return player.canAfford(player.getCardCost(this) + constants_1.REDS_RULING_POLICY_COST, { titanium: true });
        }
        return true;
    };
    ImportedHydrogen.prototype.play = function (player) {
        var availableMicrobeCards = player.getResourceCards(ResourceType_1.ResourceType.MICROBE);
        var availableAnimalCards = player.getResourceCards(ResourceType_1.ResourceType.ANIMAL);
        var gainPlants = function () {
            player.addResource(Resources_1.Resources.PLANTS, 3, { log: true });
            player.game.defer(new PlaceOceanTile_1.PlaceOceanTile(player));
            return undefined;
        };
        if (availableMicrobeCards.length === 0 && availableAnimalCards.length === 0) {
            return gainPlants();
        }
        var availableActions = [];
        var gainPlantsOption = new SelectOption_1.SelectOption('Gain 3 plants', 'Gain plants', gainPlants);
        availableActions.push(gainPlantsOption);
        if (availableMicrobeCards.length === 1) {
            var targetMicrobeCard_1 = availableMicrobeCards[0];
            availableActions.push(new SelectOption_1.SelectOption('Add 3 microbes to ' + targetMicrobeCard_1.name, 'Add microbes', function () {
                player.addResourceTo(targetMicrobeCard_1, { qty: 3, log: true });
                player.game.defer(new PlaceOceanTile_1.PlaceOceanTile(player));
                return undefined;
            }));
        }
        else if (availableMicrobeCards.length > 1) {
            availableActions.push(new SelectCard_1.SelectCard('Add 3 microbes to a card', 'Add microbes', availableMicrobeCards, function (foundCards) {
                player.addResourceTo(foundCards[0], { qty: 3, log: true });
                player.game.defer(new PlaceOceanTile_1.PlaceOceanTile(player));
                return undefined;
            }));
        }
        if (availableAnimalCards.length === 1) {
            var targetAnimalCard_1 = availableAnimalCards[0];
            availableActions.push(new SelectOption_1.SelectOption('Add 2 animals to ' + targetAnimalCard_1.name, 'Add animals', function () {
                player.addResourceTo(targetAnimalCard_1, { qty: 2, log: true });
                player.game.defer(new PlaceOceanTile_1.PlaceOceanTile(player));
                return undefined;
            }));
        }
        else if (availableAnimalCards.length > 1) {
            availableActions.push(new SelectCard_1.SelectCard('Add 2 animals to a card', 'Add animals', availableAnimalCards, function (foundCards) {
                player.addResourceTo(foundCards[0], { qty: 2, log: true });
                player.game.defer(new PlaceOceanTile_1.PlaceOceanTile(player));
                return undefined;
            }));
        }
        return new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray([void 0], availableActions)))();
    };
    return ImportedHydrogen;
}(Card_1.Card));
exports.ImportedHydrogen = ImportedHydrogen;
