"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartyCardRequirement = exports.ProductionCardRequirement = exports.TagCardRequirement = exports.CardRequirement = void 0;
var RequirementType_1 = require("./RequirementType");
var Tags_1 = require("./Tags");
var Resources_1 = require("../Resources");
var ResourceType_1 = require("../ResourceType");
var TileType_1 = require("../TileType");
var GlobalParameter_1 = require("../GlobalParameter");
var MoonExpansion_1 = require("../moon/MoonExpansion");
var Turmoil_1 = require("../turmoil/Turmoil");
var firstLetterUpperCase = function (s) { return s.charAt(0).toUpperCase() + s.slice(1); };
var CardRequirement = (function () {
    function CardRequirement(_type, _amount, _isMax, _isAny) {
        if (_amount === void 0) { _amount = 1; }
        if (_isMax === void 0) { _isMax = false; }
        if (_isAny === void 0) { _isAny = false; }
        this._type = _type;
        this._amount = _amount;
        this._isMax = _isMax;
        this._isAny = _isAny;
    }
    CardRequirement.prototype.amountToString = function () {
        if (this._type === RequirementType_1.RequirementType.OXYGEN || this._type === RequirementType_1.RequirementType.VENUS) {
            return this._amount + "%";
        }
        else if (this._type === RequirementType_1.RequirementType.TEMPERATURE) {
            return this._amount + "\u00B0";
        }
        else {
            return (this._amount !== 1 || this._isMax) ? this._amount.toString() : '';
        }
    };
    CardRequirement.prototype.parseType = function () {
        var withPlural = [RequirementType_1.RequirementType.OCEANS, RequirementType_1.RequirementType.FLOATERS, RequirementType_1.RequirementType.GREENERIES, RequirementType_1.RequirementType.CITIES, RequirementType_1.RequirementType.COLONIES, RequirementType_1.RequirementType.RESOURCE_TYPES, RequirementType_1.RequirementType.PARTY_LEADERS];
        if (this._amount > 1 && withPlural.includes(this._type)) {
            return this.getTypePlural();
        }
        return this._type;
    };
    CardRequirement.prototype.getTypePlural = function () {
        if (this._type === RequirementType_1.RequirementType.CITIES) {
            return 'Cities';
        }
        else if (this._type === RequirementType_1.RequirementType.COLONIES) {
            return 'Colonies';
        }
        else if (this._type === RequirementType_1.RequirementType.GREENERIES) {
            return 'Greeneries';
        }
        else {
            return this._type + "s";
        }
    };
    CardRequirement.prototype.getLabel = function () {
        var result = this._isMax ? 'max ' : '';
        var amount = this.amountToString();
        if (amount !== '') {
            result += amount;
            result += ' ';
        }
        result += this.parseType();
        return result;
    };
    CardRequirement.prototype.max = function () {
        this._isMax = true;
        return this;
    };
    CardRequirement.prototype.any = function () {
        this._isAny = true;
        return this;
    };
    Object.defineProperty(CardRequirement.prototype, "isMax", {
        get: function () {
            return this._isMax;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CardRequirement.prototype, "isAny", {
        get: function () {
            return this._isAny;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CardRequirement.prototype, "type", {
        get: function () {
            return this._type;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CardRequirement.prototype, "amount", {
        get: function () {
            return this._amount;
        },
        enumerable: false,
        configurable: true
    });
    CardRequirement.prototype.satisfiesInequality = function (calculated) {
        if (this.isMax) {
            return calculated <= this.amount;
        }
        return calculated >= this.amount;
    };
    CardRequirement.prototype.satisfies = function (player) {
        var _this = this;
        switch (this.type) {
            case RequirementType_1.RequirementType.CHAIRMAN:
                return Turmoil_1.Turmoil.getTurmoil(player.game).chairman === player.id;
            case RequirementType_1.RequirementType.CITIES:
                if (this._isAny) {
                    return this.satisfiesInequality(player.game.getCitiesInPlay());
                }
                else {
                    return this.satisfiesInequality(player.getCitiesCount());
                }
            case RequirementType_1.RequirementType.COLONIES:
                var coloniesCount = player.game.colonies.map(function (colony) { return colony.colonies.filter(function (owner) { return owner === player.id; }).length; })
                    .reduce(function (sum, colonyCount) { return sum + colonyCount; });
                return this.satisfiesInequality(coloniesCount);
            case RequirementType_1.RequirementType.FLOATERS:
                return this.satisfiesInequality(player.getResourceCount(ResourceType_1.ResourceType.FLOATER));
            case RequirementType_1.RequirementType.GREENERIES:
                var greeneries = player.game.board.spaces.filter(function (space) { return space.tile !== undefined &&
                    space.tile.tileType === TileType_1.TileType.GREENERY &&
                    (space.player === player || _this._isAny); }).length;
                return this.satisfiesInequality(greeneries);
            case RequirementType_1.RequirementType.PARTY_LEADERS:
                var turmoil = Turmoil_1.Turmoil.getTurmoil(player.game);
                var parties = turmoil.parties.filter(function (party) { return party.partyLeader === player.id; }).length;
                return this.satisfiesInequality(parties);
            case RequirementType_1.RequirementType.OCEANS:
                return this.checkGlobalRequirement(player, GlobalParameter_1.GlobalParameter.OCEANS, this.amount, this.isMax);
            case RequirementType_1.RequirementType.OXYGEN:
                return this.checkGlobalRequirement(player, GlobalParameter_1.GlobalParameter.OXYGEN, this.amount, this.isMax);
            case RequirementType_1.RequirementType.TEMPERATURE:
                return this.checkGlobalRequirement(player, GlobalParameter_1.GlobalParameter.TEMPERATURE, this.amount, this.isMax);
            case RequirementType_1.RequirementType.VENUS:
                return this.checkGlobalRequirement(player, GlobalParameter_1.GlobalParameter.VENUS, this.amount, this.isMax);
            case RequirementType_1.RequirementType.TR:
                return this.satisfiesInequality(player.getTerraformRating());
            case RequirementType_1.RequirementType.REMOVED_PLANTS:
                return player.game.someoneHasRemovedOtherPlayersPlants;
            case RequirementType_1.RequirementType.RESOURCE_TYPES:
                var standardResources = [Resources_1.Resources.MEGACREDITS, Resources_1.Resources.STEEL, Resources_1.Resources.TITANIUM, Resources_1.Resources.PLANTS, Resources_1.Resources.ENERGY, Resources_1.Resources.HEAT]
                    .filter(function (res) { return player.getResource(res) > 0; }).length;
                var nonStandardResources = new Set(player.getCardsWithResources().map(function (card) { return card.resourceType; })).size;
                return this.satisfiesInequality(standardResources + nonStandardResources);
            case RequirementType_1.RequirementType.COLONY_RATE:
                return this.checkGlobalRequirement(player, GlobalParameter_1.GlobalParameter.MOON_COLONY_RATE, this.amount, this.isMax);
            case RequirementType_1.RequirementType.MINING_RATE:
                return this.checkGlobalRequirement(player, GlobalParameter_1.GlobalParameter.MOON_MINING_RATE, this.amount, this.isMax);
            case RequirementType_1.RequirementType.LOGISTIC_RATE:
                return this.checkGlobalRequirement(player, GlobalParameter_1.GlobalParameter.MOON_LOGISTICS_RATE, this.amount, this.isMax);
            case RequirementType_1.RequirementType.COLONY_TILES:
                return this.satisfiesInequality(MoonExpansion_1.MoonExpansion.tiles(player.game, TileType_1.TileType.MOON_COLONY, { surfaceOnly: true, ownedBy: this._isAny ? undefined : player }).length);
            case RequirementType_1.RequirementType.MINING_TILES:
                return this.satisfiesInequality(MoonExpansion_1.MoonExpansion.tiles(player.game, TileType_1.TileType.MOON_MINE, { surfaceOnly: true, ownedBy: this._isAny ? undefined : player }).length);
            case RequirementType_1.RequirementType.ROAD_TILES:
                return this.satisfiesInequality(MoonExpansion_1.MoonExpansion.tiles(player.game, TileType_1.TileType.MOON_ROAD, { surfaceOnly: true, ownedBy: this._isAny ? undefined : player }).length);
            case RequirementType_1.RequirementType.TAG:
            case RequirementType_1.RequirementType.PARTY:
            case RequirementType_1.RequirementType.PRODUCTION:
                throw "Use subclass satisfies() for requirement type " + this.type;
        }
    };
    CardRequirement.prototype.checkGlobalRequirement = function (player, parameter, level, max) {
        if (max === void 0) { max = false; }
        var currentLevel;
        var playerRequirementsBonus = player.getRequirementsBonus(parameter);
        switch (parameter) {
            case GlobalParameter_1.GlobalParameter.OCEANS:
                currentLevel = player.game.board.getOceansOnBoard();
                break;
            case GlobalParameter_1.GlobalParameter.OXYGEN:
                currentLevel = player.game.getOxygenLevel();
                break;
            case GlobalParameter_1.GlobalParameter.TEMPERATURE:
                currentLevel = player.game.getTemperature();
                playerRequirementsBonus *= 2;
                break;
            case GlobalParameter_1.GlobalParameter.VENUS:
                currentLevel = player.game.getVenusScaleLevel();
                playerRequirementsBonus *= 2;
                break;
            case GlobalParameter_1.GlobalParameter.MOON_COLONY_RATE:
                currentLevel = MoonExpansion_1.MoonExpansion.moonData(player.game).colonyRate;
                break;
            case GlobalParameter_1.GlobalParameter.MOON_MINING_RATE:
                currentLevel = MoonExpansion_1.MoonExpansion.moonData(player.game).miningRate;
                break;
            case GlobalParameter_1.GlobalParameter.MOON_LOGISTICS_RATE:
                currentLevel = MoonExpansion_1.MoonExpansion.moonData(player.game).logisticRate;
                break;
            default:
                console.warn("Unknown GlobalParameter provided: " + parameter);
                return false;
        }
        if (max) {
            return currentLevel <= level + playerRequirementsBonus;
        }
        else {
            return currentLevel >= level - playerRequirementsBonus;
        }
    };
    return CardRequirement;
}());
exports.CardRequirement = CardRequirement;
var TagCardRequirement = (function (_super) {
    __extends(TagCardRequirement, _super);
    function TagCardRequirement(tag, amount) {
        if (amount === void 0) { amount = 1; }
        var _this = _super.call(this, RequirementType_1.RequirementType.TAG, amount) || this;
        _this.tag = tag;
        return _this;
    }
    TagCardRequirement.prototype.parseType = function () {
        return firstLetterUpperCase(this.tag);
    };
    TagCardRequirement.prototype.satisfies = function (player) {
        var tagCount = player.getTagCount(this.tag);
        if (this.tag === Tags_1.Tags.SCIENCE && player.hasTurmoilScienceTagBonus)
            tagCount += 1;
        return this.satisfiesInequality(tagCount);
    };
    return TagCardRequirement;
}(CardRequirement));
exports.TagCardRequirement = TagCardRequirement;
var ProductionCardRequirement = (function (_super) {
    __extends(ProductionCardRequirement, _super);
    function ProductionCardRequirement(resource, amount) {
        if (amount === void 0) { amount = 1; }
        var _this = _super.call(this, RequirementType_1.RequirementType.RESOURCE_TYPES, amount) || this;
        _this.resource = resource;
        return _this;
    }
    ProductionCardRequirement.prototype.parseType = function () {
        return firstLetterUpperCase(this.resource) + " production";
    };
    ProductionCardRequirement.prototype.satisfies = function (player) {
        return this.satisfiesInequality(player.getProduction(this.resource));
    };
    return ProductionCardRequirement;
}(CardRequirement));
exports.ProductionCardRequirement = ProductionCardRequirement;
var PartyCardRequirement = (function (_super) {
    __extends(PartyCardRequirement, _super);
    function PartyCardRequirement(party) {
        var _this = _super.call(this, RequirementType_1.RequirementType.PARTY) || this;
        _this.party = party;
        return _this;
    }
    PartyCardRequirement.prototype.parseType = function () {
        return this.party.toLowerCase();
    };
    PartyCardRequirement.prototype.satisfies = function (player) {
        return Turmoil_1.Turmoil.getTurmoil(player.game).canPlay(player, this.party);
    };
    return PartyCardRequirement;
}(CardRequirement));
exports.PartyCardRequirement = PartyCardRequirement;
