"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.BioPrintingFacility = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../ResourceType");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../Resources");
var SelectCard_1 = require("../../inputs/SelectCard");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var CardRenderer_1 = require("../render/CardRenderer");
var BioPrintingFacility = (function (_super) {
    __extends(BioPrintingFacility, _super);
    function BioPrintingFacility() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.BIO_PRINTING_FACILITY,
            tags: [Tags_1.Tags.BUILDING],
            cost: 7,
            metadata: {
                cardNumber: 'X36',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend 2 energy to gain 2 plants OR to add 1 animal to ANOTHER card.', function (eb) {
                        eb.energy(2).digit.startAction.plants(2);
                        eb.or().animals(1).asterix();
                    });
                }),
            },
        }) || this;
    }
    BioPrintingFacility.prototype.play = function () {
        return undefined;
    };
    BioPrintingFacility.prototype.canAct = function (player) {
        return player.energy >= 2;
    };
    BioPrintingFacility.prototype.action = function (player) {
        var availableAnimalCards = player.getResourceCards(ResourceType_1.ResourceType.ANIMAL);
        player.deductResource(Resources_1.Resources.ENERGY, 2);
        if (availableAnimalCards.length === 0) {
            player.addResource(Resources_1.Resources.PLANTS, 2, { log: true });
            return undefined;
        }
        var gainPlantOption = new SelectOption_1.SelectOption('Gain 2 plants', 'Gain plants', function () {
            player.addResource(Resources_1.Resources.PLANTS, 2, { log: true });
            return undefined;
        });
        if (availableAnimalCards.length === 1) {
            var targetCard_1 = availableAnimalCards[0];
            return new OrOptions_1.OrOptions(new SelectOption_1.SelectOption('Add 1 animal to ' + targetCard_1.name, 'Add animal', function () {
                player.addResourceTo(targetCard_1, { log: true });
                return undefined;
            }), gainPlantOption);
        }
        return new OrOptions_1.OrOptions(new SelectCard_1.SelectCard('Select card to add 1 animal', 'Add animal', availableAnimalCards, function (foundCards) {
            player.addResourceTo(foundCards[0], { log: true });
            return undefined;
        }), gainPlantOption);
    };
    return BioPrintingFacility;
}(Card_1.Card));
exports.BioPrintingFacility = BioPrintingFacility;
