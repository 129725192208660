"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardRequirements = void 0;
var CardRequirement_1 = require("./CardRequirement");
var RequirementType_1 = require("./RequirementType");
var constants_1 = require("../constants");
var CardRequirements = (function () {
    function CardRequirements(requirements) {
        this.requirements = requirements;
    }
    CardRequirements.builder = function (f) {
        var builder = new Builder();
        f(builder);
        return builder.build();
    };
    CardRequirements.prototype.getRequirementsText = function () {
        var reqTexts = this.requirements.map(function (req) { return req.getLabel(); });
        if (this.hasAny()) {
            reqTexts.unshift('Any');
        }
        return reqTexts.join(' ');
    };
    CardRequirements.prototype.hasMax = function () {
        return this.requirements.some(function (req) { return req.isMax; });
    };
    CardRequirements.prototype.hasAny = function () {
        return this.requirements.some(function (req) { return req.isAny; });
    };
    CardRequirements.prototype.hasParty = function () {
        return this.requirements.some(function (req) { return req instanceof CardRequirement_1.PartyCardRequirement; });
    };
    CardRequirements.prototype.hasPlantsRemoved = function () {
        return this.requirements.some(function (req) { return req.type === RequirementType_1.RequirementType.REMOVED_PLANTS; });
    };
    CardRequirements.prototype.satisfies = function (player) {
        var tags = this.requirements.filter(function (requirement) { return requirement.type === RequirementType_1.RequirementType.TAG; })
            .map(function (requirement) { return requirement.tag; });
        if (!player.checkMultipleTagPresence(tags)) {
            return false;
        }
        return this.requirements.every(function (requirement) { return requirement.satisfies(player); });
    };
    return CardRequirements;
}());
exports.CardRequirements = CardRequirements;
var Builder = (function () {
    function Builder() {
        this.reqs = [];
    }
    Builder.prototype.build = function () {
        return new CardRequirements(this.reqs);
    };
    Builder.prototype.oceans = function (amount) {
        if (amount <= 0 || amount > constants_1.MAX_OCEAN_TILES) {
            throw new Error('Ocean tiles must be above 0 and below ' + constants_1.MAX_OCEAN_TILES);
        }
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.OCEANS, amount));
        return this;
    };
    Builder.prototype.oxygen = function (amount) {
        if (amount < constants_1.MIN_OXYGEN_LEVEL || amount > constants_1.MAX_OXYGEN_LEVEL) {
            throw new Error('Oxygen must be above ' + constants_1.MIN_OXYGEN_LEVEL + ' and below ' + constants_1.MAX_OXYGEN_LEVEL);
        }
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.OXYGEN, amount));
        return this;
    };
    Builder.prototype.temperature = function (amount) {
        if (amount < constants_1.MIN_TEMPERATURE || amount > constants_1.MAX_TEMPERATURE) {
            throw new Error('Temperature must be above ' + constants_1.MIN_TEMPERATURE + ' and below ' + constants_1.MAX_TEMPERATURE);
        }
        if (amount % 2 !== 0) {
            throw new Error('Temperature must be even');
        }
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.TEMPERATURE, amount));
        return this;
    };
    Builder.prototype.venus = function (amount) {
        if (amount < constants_1.MIN_VENUS_SCALE || amount > constants_1.MAX_VENUS_SCALE) {
            throw new Error('Venus must be above ' + constants_1.MIN_VENUS_SCALE + ' and below ' + constants_1.MAX_VENUS_SCALE);
        }
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.VENUS, amount));
        return this;
    };
    Builder.prototype.tr = function (amount) {
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.TR, amount));
        return this;
    };
    Builder.prototype.chairman = function () {
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.CHAIRMAN));
        return this;
    };
    Builder.prototype.resourceTypes = function (amount) {
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.RESOURCE_TYPES, amount));
        return this;
    };
    Builder.prototype.greeneries = function (amount) {
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.GREENERIES, amount));
        return this;
    };
    Builder.prototype.cities = function (amount) {
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.CITIES, amount));
        return this;
    };
    Builder.prototype.colonies = function (amount) {
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.COLONIES, amount));
        return this;
    };
    Builder.prototype.floaters = function (amount) {
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.FLOATERS, amount));
        return this;
    };
    Builder.prototype.partyLeaders = function (amount) {
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.PARTY_LEADERS, amount));
        return this;
    };
    Builder.prototype.tag = function (tag, amount) {
        this.reqs.push(new CardRequirement_1.TagCardRequirement(tag, amount));
        return this;
    };
    Builder.prototype.production = function (resource, amount) {
        this.reqs.push(new CardRequirement_1.ProductionCardRequirement(resource, amount));
        return this;
    };
    Builder.prototype.party = function (party) {
        this.reqs.push(new CardRequirement_1.PartyCardRequirement(party));
        return this;
    };
    Builder.prototype.plantsRemoved = function () {
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.REMOVED_PLANTS));
        return this;
    };
    Builder.prototype.colonyRate = function (amount) {
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.COLONY_RATE, amount));
        return this;
    };
    Builder.prototype.miningRate = function (amount) {
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.MINING_RATE, amount));
        return this;
    };
    Builder.prototype.logisticRate = function (amount) {
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.LOGISTIC_RATE, amount));
        return this;
    };
    Builder.prototype.colonyTiles = function (amount) {
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.COLONY_TILES, amount));
        return this;
    };
    Builder.prototype.miningTiles = function (amount) {
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.MINING_TILES, amount));
        return this;
    };
    Builder.prototype.roadTiles = function (amount) {
        this.reqs.push(new CardRequirement_1.CardRequirement(RequirementType_1.RequirementType.ROAD_TILES, amount));
        return this;
    };
    Builder.prototype.max = function () {
        var req = this.reqs.pop();
        if (req === undefined) {
            throw new Error('Called max without a CardRequirement.');
        }
        this.reqs.push(req.max());
        return this;
    };
    Builder.prototype.any = function () {
        var req = this.reqs.pop();
        if (req === undefined) {
            throw new Error('Called any without a CardRequirement.');
        }
        this.reqs.push(req.any());
        return this;
    };
    return Builder;
}());
