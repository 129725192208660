"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.NitriteReducingBacteria = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var OrOptions_1 = require("../../inputs/OrOptions");
var ResourceType_1 = require("../../ResourceType");
var SelectOption_1 = require("../../inputs/SelectOption");
var CardName_1 = require("../../CardName");
var LogHelper_1 = require("../../LogHelper");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var constants_1 = require("../../constants");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var CardRenderer_1 = require("../render/CardRenderer");
var NitriteReducingBacteria = (function (_super) {
    __extends(NitriteReducingBacteria, _super);
    function NitriteReducingBacteria() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.NITRITE_REDUCING_BACTERIA,
            tags: [Tags_1.Tags.MICROBE],
            cost: 11,
            resourceType: ResourceType_1.ResourceType.MICROBE,
            metadata: {
                cardNumber: '157',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 1 Microbe to this card.', function (eb) {
                        eb.empty().startAction.microbes(1);
                    }).br;
                    b.or().br;
                    b.action('Remove 3 Microbes to increase your TR 1 step.', function (eb) {
                        eb.microbes(3).startAction.tr(1);
                    }).br;
                    b.microbes(3);
                }),
                description: 'Add 3 Microbes to this card.',
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    NitriteReducingBacteria.prototype.play = function (player) {
        var _this = this;
        player.game.defer(new DeferredAction_1.DeferredAction(player, function () {
            player.addResourceTo(_this, 3);
            return undefined;
        }));
        return undefined;
    };
    NitriteReducingBacteria.prototype.canAct = function () {
        return true;
    };
    NitriteReducingBacteria.prototype.action = function (player) {
        var _this = this;
        if (this.resourceCount < 3) {
            player.addResourceTo(this, { log: true });
            return undefined;
        }
        var orOptions = new OrOptions_1.OrOptions();
        var redsAreRuling = PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS);
        if (!redsAreRuling || (redsAreRuling && player.canAfford(constants_1.REDS_RULING_POLICY_COST))) {
            orOptions.options.push(new SelectOption_1.SelectOption('Remove 3 microbes to increase your terraform rating 1 step', 'Remove microbes', function () {
                player.removeResourceFrom(_this, 3);
                LogHelper_1.LogHelper.logRemoveResource(player, _this, 3, 'gain 1 TR');
                player.increaseTerraformRating();
                return undefined;
            }));
        }
        orOptions.options.push(new SelectOption_1.SelectOption('Add 1 microbe to this card', 'Add microbe', function () {
            player.addResourceTo(_this, { log: true });
            return undefined;
        }));
        if (orOptions.options.length === 1)
            return orOptions.options[0].cb();
        return orOptions;
    };
    return NitriteReducingBacteria;
}(Card_1.Card));
exports.NitriteReducingBacteria = NitriteReducingBacteria;
