"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ByElection = void 0;
var Tags_1 = require("../Tags");
var PreludeCard_1 = require("../prelude/PreludeCard");
var CardName_1 = require("../../CardName");
var Turmoil_1 = require("../../turmoil/Turmoil");
var SelectOption_1 = require("../../inputs/SelectOption");
var OrOptions_1 = require("../../inputs/OrOptions");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var PoliticalAgendas_1 = require("../../turmoil/PoliticalAgendas");
var ByElection = (function (_super) {
    __extends(ByElection, _super);
    function ByElection() {
        return _super.call(this, {
            name: CardName_1.CardName.BY_ELECTION,
            tags: [Tags_1.Tags.WILDCARD],
            metadata: {
                cardNumber: 'Y02',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.text('set ruling party', Size_1.Size.SMALL, true).br;
                    b.plus().influence(1);
                }),
                description: 'Set the ruling party to one of your choice. Gain 1 influence.',
            },
        }) || this;
    }
    ByElection.prototype.canPlay = function () {
        return true;
    };
    ByElection.prototype.play = function (player) {
        var turmoil = Turmoil_1.Turmoil.getTurmoil(player.game);
        turmoil.addInfluenceBonus(player);
        var setRulingParty = new OrOptions_1.OrOptions();
        setRulingParty.title = 'Select new ruling party';
        setRulingParty.options = __spreadArray([], Turmoil_1.ALL_PARTIES.map(function (p) { return new SelectOption_1.SelectOption(p.partyName, 'Select', function () {
            turmoil.rulingParty = turmoil.getPartyByName(p.partyName);
            PoliticalAgendas_1.PoliticalAgendas.setNextAgenda(turmoil, player.game);
            return undefined;
        }); }));
        player.game.defer(new DeferredAction_1.DeferredAction(player, function () { return setRulingParty; }));
        return undefined;
    };
    return ByElection;
}(PreludeCard_1.PreludeCard));
exports.ByElection = ByElection;
