"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.NuclearZone = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var TileType_1 = require("../../TileType");
var CardName_1 = require("../../CardName");
var constants_1 = require("../../constants");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var CardRenderer_1 = require("../render/CardRenderer");
var NuclearZone = (function (_super) {
    __extends(NuclearZone, _super);
    function NuclearZone(name, cost, adjacencyBonus, metadata) {
        if (name === void 0) { name = CardName_1.CardName.NUCLEAR_ZONE; }
        if (cost === void 0) { cost = 10; }
        if (adjacencyBonus === void 0) { adjacencyBonus = undefined; }
        if (metadata === void 0) { metadata = {
            cardNumber: '097',
            renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                b.tile(TileType_1.TileType.NUCLEAR_ZONE, true).br;
                b.temperature(2);
            }),
            description: 'Place this tile and raise temperature 2 steps.',
            victoryPoints: -2,
        }; }
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: name,
            tags: [Tags_1.Tags.EARTH],
            cost: cost,
            adjacencyBonus: adjacencyBonus,
            metadata: metadata,
        }) || this;
    }
    NuclearZone.prototype.canPlay = function (player) {
        var canPlaceTile = player.game.board.getAvailableSpacesOnLand(player).length > 0;
        var remainingTemperatureSteps = (constants_1.MAX_TEMPERATURE - player.game.getTemperature()) / 2;
        var stepsRaised = Math.min(remainingTemperatureSteps, 2);
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS)) {
            return player.canAfford(player.getCardCost(this) + constants_1.REDS_RULING_POLICY_COST * stepsRaised) && canPlaceTile;
        }
        return canPlaceTile;
    };
    NuclearZone.prototype.play = function (player) {
        var _this = this;
        player.game.increaseTemperature(player, 2);
        return new SelectSpace_1.SelectSpace('Select space for special tile', player.game.board.getAvailableSpacesOnLand(player), function (foundSpace) {
            player.game.addTile(player, foundSpace.spaceType, foundSpace, { tileType: TileType_1.TileType.NUCLEAR_ZONE });
            foundSpace.adjacency = _this.adjacencyBonus;
            return undefined;
        });
    };
    NuclearZone.prototype.getVictoryPoints = function () {
        return -2;
    };
    return NuclearZone;
}(Card_1.Card));
exports.NuclearZone = NuclearZone;
