"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LakefrontResorts = void 0;
var Tags_1 = require("../Tags");
var TileType_1 = require("../../TileType");
var Resources_1 = require("../../Resources");
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var GainProduction_1 = require("../../deferredActions/GainProduction");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var LakefrontResorts = (function (_super) {
    __extends(LakefrontResorts, _super);
    function LakefrontResorts() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.LAKEFRONT_RESORTS,
            tags: [Tags_1.Tags.BUILDING],
            startingMegaCredits: 54,
            metadata: {
                cardNumber: 'R38',
                description: 'You start with 54 M€.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br.br.br;
                    b.megacredits(54);
                    b.corpBox('effect', function (ce) {
                        ce.vSpace(Size_1.Size.MEDIUM);
                        ce.effect('When any ocean tile is placed, increase your M€ production 1 step. Your bonus for placing adjacent to oceans is 3M€ instead of 2 M€.', function (eb) {
                            eb.oceans(1, Size_1.Size.SMALL).any.colon().production(function (pb) { return pb.megacredits(1); });
                            eb.emptyTile('normal', Size_1.Size.SMALL).oceans(1, Size_1.Size.SMALL);
                            eb.startEffect.megacredits(3);
                        });
                    });
                }),
            },
        }) || this;
    }
    LakefrontResorts.prototype.play = function (player) {
        player.oceanBonus = 3;
        return undefined;
    };
    LakefrontResorts.prototype.onTilePlaced = function (cardOwner, activePlayer, space) {
        var _a;
        if (((_a = space.tile) === null || _a === void 0 ? void 0 : _a.tileType) === TileType_1.TileType.OCEAN) {
            cardOwner.game.defer(new GainProduction_1.GainProduction(cardOwner, Resources_1.Resources.MEGACREDITS), cardOwner.id !== activePlayer.id ? DeferredAction_1.Priority.OPPONENT_TRIGGER : undefined);
        }
    };
    return LakefrontResorts;
}(Card_1.Card));
exports.LakefrontResorts = LakefrontResorts;
