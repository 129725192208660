"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DarksideIncubationPlant = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var ResourceType_1 = require("../../ResourceType");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var Units_1 = require("../../Units");
var MoonCard_1 = require("./MoonCard");
var LogHelper_1 = require("../../LogHelper");
var DarksideIncubationPlant = (function (_super) {
    __extends(DarksideIncubationPlant, _super);
    function DarksideIncubationPlant() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.DARKSIDE_INCUBATION_PLANT,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.MICROBE, Tags_1.Tags.MOON],
            cost: 11,
            resourceType: ResourceType_1.ResourceType.MICROBE,
            reserveUnits: Units_1.Units.of({ titanium: 1 }),
            metadata: {
                description: {
                    text: 'Spend 1 titanium. 1 VP for every 2 microbes here.',
                    align: 'left',
                },
                cardNumber: 'M45',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 1 microbe here.', function (eb) {
                        eb.empty().startAction.microbes(1);
                    }).br;
                    b.action('Spend 2 microbes to raise the Colony Rate 1 step.', function (eb) {
                        eb.microbes(2).startAction.moonColonyRate();
                    });
                    b.br;
                    b.minus().titanium(1);
                }),
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.microbes(1, 2),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    ;
    DarksideIncubationPlant.prototype.play = function (player) {
        _super.prototype.play.call(this, player);
        return undefined;
    };
    DarksideIncubationPlant.prototype.canAct = function () {
        return true;
    };
    DarksideIncubationPlant.prototype.action = function (player) {
        var _this = this;
        var options = [];
        options.push(new SelectOption_1.SelectOption('Add 1 microbe to this card', 'Select', function () {
            player.addResourceTo(_this, 1);
            return undefined;
        }));
        MoonExpansion_1.MoonExpansion.ifMoon(player.game, function (moonData) {
            if (_this.resourceCount >= 2 && moonData.colonyRate < 8) {
                options.push(new SelectOption_1.SelectOption('Spend 2 microbes to raise the Colony Rate 1 step.', 'Select', function () {
                    player.removeResourceFrom(_this, 2);
                    LogHelper_1.LogHelper.logRemoveResource(player, _this, 2, 'raise the Colony Rate');
                    MoonExpansion_1.MoonExpansion.raiseColonyRate(player);
                    return undefined;
                }));
            }
        });
        if (options.length === 1) {
            return options[0].cb();
        }
        else {
            return new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray([void 0], options)))();
        }
    };
    DarksideIncubationPlant.prototype.getVictoryPoints = function () {
        return Math.floor(this.resourceCount / 2);
    };
    return DarksideIncubationPlant;
}(MoonCard_1.MoonCard));
exports.DarksideIncubationPlant = DarksideIncubationPlant;
