"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FloaterLeasing = void 0;
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var Resources_1 = require("../../Resources");
var ResourceType_1 = require("../../ResourceType");
var Card_1 = require("../Card");
var CardRenderer_1 = require("../render/CardRenderer");
var FloaterLeasing = (function (_super) {
    __extends(FloaterLeasing, _super);
    function FloaterLeasing() {
        return _super.call(this, {
            cost: 3,
            name: CardName_1.CardName.FLOATER_LEASING,
            cardType: CardType_1.CardType.AUTOMATED,
            metadata: {
                cardNumber: 'C10',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.megacredits(1); }).slash().floaters(3).digit;
                }),
                description: 'Increase your M€ production 1 step PER 3 floaters you have.',
            },
        }) || this;
    }
    FloaterLeasing.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, Math.floor(player.getResourceCount(ResourceType_1.ResourceType.FLOATER) / 3), { log: true });
        return undefined;
    };
    return FloaterLeasing;
}(Card_1.Card));
exports.FloaterLeasing = FloaterLeasing;
