"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameModule = void 0;
var GameModule;
(function (GameModule) {
    GameModule["Override"] = "invalid";
    GameModule["Base"] = "base";
    GameModule["CorpEra"] = "corpera";
    GameModule["Promo"] = "promo";
    GameModule["Venus"] = "venus";
    GameModule["Colonies"] = "colonies";
    GameModule["Prelude"] = "prelude";
    GameModule["Turmoil"] = "turmoil";
    GameModule["Community"] = "community";
    GameModule["Ares"] = "ares";
    GameModule["Moon"] = "moon";
})(GameModule = exports.GameModule || (exports.GameModule = {}));
