"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MudSlides = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var TileType_1 = require("../../TileType");
var MudSlides = (function () {
    function MudSlides() {
        this.name = GlobalEventName_1.GlobalEventName.MUD_SLIDES;
        this.description = 'Lose 4 M€ for each tile adjacent to ocean (max 5, then reduced by influence).';
        this.revealedDelegate = PartyName_1.PartyName.KELVINISTS;
        this.currentDelegate = PartyName_1.PartyName.GREENS;
    }
    MudSlides.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            var tiles = game.board.spaces.filter(function (space) { return (space.player !== undefined && space.player === player && space.tile !== undefined) &&
                game.board.getAdjacentSpaces(space)
                    .filter(function (space) { return (space.tile !== undefined &&
                    space.tile.tileType === TileType_1.TileType.OCEAN); }).length > 0; }).length;
            var amount = Math.min(5, tiles) - turmoil.getPlayerInfluence(player);
            if (amount > 0) {
                player.deductResource(Resources_1.Resources.MEGACREDITS, 4 * amount, { log: true, from: _this.name });
            }
        });
    };
    return MudSlides;
}());
exports.MudSlides = MudSlides;
