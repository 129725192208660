"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParadigmBreakdown = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var DiscardCards_1 = require("../../deferredActions/DiscardCards");
var ParadigmBreakdown = (function () {
    function ParadigmBreakdown() {
        this.name = GlobalEventName_1.GlobalEventName.PARADIGM_BREAKDOWN;
        this.description = 'Discard 2 cards from hand. Gain 2 M€ per influence.';
        this.revealedDelegate = PartyName_1.PartyName.KELVINISTS;
        this.currentDelegate = PartyName_1.PartyName.REDS;
    }
    ParadigmBreakdown.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            if (player.cardsInHand.length >= 2) {
                game.defer(new DiscardCards_1.DiscardCards(player, 2, 'Global Event - Select 2 cards to discard'));
            }
            else if (player.cardsInHand.length === 1) {
                game.defer(new DiscardCards_1.DiscardCards(player, 1, 'Global Event - Select a card to discard'));
            }
            player.addResource(Resources_1.Resources.MEGACREDITS, 2 * (turmoil.getPlayerInfluence(player)), { log: true, from: _this.name });
        });
    };
    return ParadigmBreakdown;
}());
exports.ParadigmBreakdown = ParadigmBreakdown;
