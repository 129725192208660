"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonsInsurance = void 0;
var Resources_1 = require("../../Resources");
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var MonsInsurance = (function (_super) {
    __extends(MonsInsurance, _super);
    function MonsInsurance() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.MONS_INSURANCE,
            startingMegaCredits: 48,
            metadata: {
                cardNumber: 'R46',
                description: 'You start with 48 M€. Increase your M€ production 4 steps. ALL OPPONENTS DECREASE THEIR M€ production 2 STEPS. THIS DOES NOT TRIGGER THE EFFECT BELOW.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(48).production(function (pb) {
                        pb.megacredits(4).nbsp.megacredits(-2).any.asterix();
                    });
                    b.corpBox('effect', function (cb) {
                        cb.vSpace(Size_1.Size.SMALL);
                        cb.effect('When a player causes another player to decrease production or lose resources, pay 3M€ to the victim, or as much as possible.', function (eb) {
                            eb.production(function (pb) { return pb.wild(1).any; }).or().minus().wild(1).any;
                            eb.startEffect.text('pay', Size_1.Size.SMALL, true).megacredits(3);
                        });
                    });
                }),
            },
        }) || this;
    }
    MonsInsurance.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, 6);
        for (var _i = 0, _a = player.game.getPlayers(); _i < _a.length; _i++) {
            var p = _a[_i];
            p.addProduction(Resources_1.Resources.MEGACREDITS, -2, { log: true });
        }
        player.game.monsInsuranceOwner = player.id;
        return undefined;
    };
    return MonsInsurance;
}(Card_1.Card));
exports.MonsInsurance = MonsInsurance;
