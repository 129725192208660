"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
exports.default = vue_1.default.extend({
    name: 'CardResourceCounter',
    props: {
        amount: {
            type: Number,
            required: true,
        },
    },
});
