"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColonistShuttles = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var TileType_1 = require("../../TileType");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var MoonCard_1 = require("./MoonCard");
var Size_1 = require("../render/Size");
var Resources_1 = require("../../Resources");
var ColonistShuttles = (function (_super) {
    __extends(ColonistShuttles, _super);
    function ColonistShuttles() {
        return _super.call(this, {
            name: CardName_1.CardName.COLONIST_SHUTTLES,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.SPACE],
            cost: 12,
            reserveUnits: Units_1.Units.of({ titanium: 1 }),
            metadata: {
                description: 'Spend 1 titanium. Raise the Colony Rate 1 step. Gain 2M€ for each colony tile on the Moon.',
                cardNumber: 'M16',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().titanium(1).moonColonyRate().br;
                    b.megacredits(2).slash().moonColony({ size: Size_1.Size.SMALL }).any;
                }),
            },
        }, {
            tilesBuilt: [TileType_1.TileType.MOON_COLONY],
        }) || this;
    }
    ;
    ColonistShuttles.prototype.play = function (player) {
        _super.prototype.play.call(this, player);
        MoonExpansion_1.MoonExpansion.raiseColonyRate(player);
        var surfaceColonies = MoonExpansion_1.MoonExpansion.tiles(player.game, TileType_1.TileType.MOON_COLONY, { surfaceOnly: true }).length;
        player.addResource(Resources_1.Resources.MEGACREDITS, surfaceColonies * 2, { log: true });
        return undefined;
    };
    return ColonistShuttles;
}(MoonCard_1.MoonCard));
exports.ColonistShuttles = ColonistShuttles;
