"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplyDrop = void 0;
var PreludeCard_1 = require("./PreludeCard");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var SupplyDrop = (function (_super) {
    __extends(SupplyDrop, _super);
    function SupplyDrop() {
        return _super.call(this, {
            name: CardName_1.CardName.SUPPLY_DROP,
            metadata: {
                cardNumber: 'P33',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.titanium(3).digit.steel(8).digit.plants(3).digit;
                }),
                description: 'Gain 3 titanium, 8 steel and 3 plants.',
            },
        }) || this;
    }
    SupplyDrop.prototype.play = function (player) {
        player.titanium += 3;
        player.steel += 8;
        player.plants += 3;
        return undefined;
    };
    return SupplyDrop;
}(PreludeCard_1.PreludeCard));
exports.SupplyDrop = SupplyDrop;
