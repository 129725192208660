"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.GiantIceAsteroid = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var constants_1 = require("../../constants");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var PlaceOceanTile_1 = require("../../deferredActions/PlaceOceanTile");
var RemoveAnyPlants_1 = require("../../deferredActions/RemoveAnyPlants");
var CardRenderer_1 = require("../render/CardRenderer");
var GiantIceAsteroid = (function (_super) {
    __extends(GiantIceAsteroid, _super);
    function GiantIceAsteroid() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.GIANT_ICE_ASTEROID,
            tags: [Tags_1.Tags.SPACE],
            cost: 36,
            metadata: {
                description: 'Raise temperature 2 steps and place 2 ocean tiles. Remove up to 6 plants from any player.',
                cardNumber: '080',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.temperature(2).br;
                    b.oceans(2).br;
                    b.minus().plants(-6).any;
                }),
            },
        }) || this;
    }
    GiantIceAsteroid.prototype.canPlay = function (player) {
        var remainingOceans = constants_1.MAX_OCEAN_TILES - player.game.board.getOceansOnBoard();
        var remainingTemperatureSteps = (constants_1.MAX_TEMPERATURE - player.game.getTemperature()) / 2;
        var stepsRaised = Math.min(remainingTemperatureSteps, 2) + Math.min(remainingOceans, 2);
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS)) {
            return player.canAfford(player.getCardCost(this) + constants_1.REDS_RULING_POLICY_COST * stepsRaised, { titanium: true });
        }
        return true;
    };
    GiantIceAsteroid.prototype.play = function (player) {
        player.game.increaseTemperature(player, 2);
        player.game.defer(new PlaceOceanTile_1.PlaceOceanTile(player, 'Select space for first ocean'));
        player.game.defer(new PlaceOceanTile_1.PlaceOceanTile(player, 'Select space for second ocean'));
        player.game.defer(new RemoveAnyPlants_1.RemoveAnyPlants(player, 6));
        return undefined;
    };
    return GiantIceAsteroid;
}(Card_1.Card));
exports.GiantIceAsteroid = GiantIceAsteroid;
