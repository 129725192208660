"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuccessfulOrganisms = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var SuccessfulOrganisms = (function () {
    function SuccessfulOrganisms() {
        this.name = GlobalEventName_1.GlobalEventName.SUCCESSFUL_ORGANISMS;
        this.description = 'Gain 1 plant per plant production (max 5) and influence.';
        this.revealedDelegate = PartyName_1.PartyName.MARS;
        this.currentDelegate = PartyName_1.PartyName.SCIENTISTS;
    }
    SuccessfulOrganisms.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            player.addResource(Resources_1.Resources.PLANTS, Math.min(5, player.getProduction(Resources_1.Resources.PLANTS)) + turmoil.getPlayerInfluence(player), { log: true, from: _this.name });
        });
    };
    return SuccessfulOrganisms;
}());
exports.SuccessfulOrganisms = SuccessfulOrganisms;
