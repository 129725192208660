"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Card = exports.staticCardProperties = void 0;
var CardType_1 = require("./CardType");
var Units_1 = require("../Units");
exports.staticCardProperties = new Map();
var Card = (function () {
    function Card(properties) {
        var staticInstance = exports.staticCardProperties.get(properties.name);
        if (staticInstance === undefined) {
            if (properties.cardType === CardType_1.CardType.CORPORATION && properties.startingMegaCredits === undefined) {
                throw new Error('must define startingMegaCredits for corporation cards');
            }
            if (properties.cost === undefined) {
                if ([CardType_1.CardType.CORPORATION, CardType_1.CardType.PRELUDE, CardType_1.CardType.STANDARD_ACTION].includes(properties.cardType) === false) {
                    throw new Error(properties.name + " must have a cost property");
                }
            }
            exports.staticCardProperties.set(properties.name, properties);
            staticInstance = properties;
        }
        this.properties = staticInstance;
    }
    Object.defineProperty(Card.prototype, "adjacencyBonus", {
        get: function () {
            return this.properties.adjacencyBonus;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "cardCost", {
        get: function () {
            return this.properties.cardCost;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "cardType", {
        get: function () {
            return this.properties.cardType;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "cost", {
        get: function () {
            return this.properties.cost === undefined ? 0 : this.properties.cost;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "initialActionText", {
        get: function () {
            return this.properties.initialActionText;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "metadata", {
        get: function () {
            return this.properties.metadata;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "requirements", {
        get: function () {
            return this.properties.requirements;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "name", {
        get: function () {
            return this.properties.name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "resourceType", {
        get: function () {
            return this.properties.resourceType;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "startingMegaCredits", {
        get: function () {
            return this.properties.startingMegaCredits === undefined ? 0 : this.properties.startingMegaCredits;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "tags", {
        get: function () {
            return this.properties.tags === undefined ? [] : this.properties.tags;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "productionBox", {
        get: function () {
            return this.properties.productionBox || Units_1.Units.EMPTY;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "cardDiscount", {
        get: function () {
            return this.properties.cardDiscount;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Card.prototype, "reserveUnits", {
        get: function () {
            return this.properties.reserveUnits || Units_1.Units.EMPTY;
        },
        enumerable: false,
        configurable: true
    });
    Card.prototype.canPlay = function (player) {
        if (this.properties.requirements === undefined) {
            return true;
        }
        return this.properties.requirements.satisfies(player);
    };
    return Card;
}());
exports.Card = Card;
