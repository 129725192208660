"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuantumCommunications = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var Resources_1 = require("../../Resources");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRequirements_1 = require("../CardRequirements");
var Card_1 = require("../Card");
var Size_1 = require("../render/Size");
var QuantumCommunications = (function (_super) {
    __extends(QuantumCommunications, _super);
    function QuantumCommunications() {
        return _super.call(this, {
            cost: 8,
            name: CardName_1.CardName.QUANTUM_COMMUNICATIONS,
            cardType: CardType_1.CardType.AUTOMATED,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.SCIENCE, 4); }),
            metadata: {
                cardNumber: '079',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.megacredits(1).slash().colonies(1, Size_1.Size.SMALL).any;
                    });
                }),
                description: 'Requires 4 Science tags. Increase your M€ production 1 step for each colony in play.',
                victoryPoints: 1,
            },
        }) || this;
    }
    QuantumCommunications.prototype.play = function (player) {
        var coloniesCount = 0;
        player.game.colonies.forEach(function (colony) {
            coloniesCount += colony.colonies.length;
        });
        player.addProduction(Resources_1.Resources.MEGACREDITS, coloniesCount, { log: true });
        return undefined;
    };
    QuantumCommunications.prototype.getVictoryPoints = function () {
        return 1;
    };
    return QuantumCommunications;
}(Card_1.Card));
exports.QuantumCommunications = QuantumCommunications;
