"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThoriumRush = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var PlaceMoonColonyTile_1 = require("../../moon/PlaceMoonColonyTile");
var PlaceMoonRoadTile_1 = require("../../moon/PlaceMoonRoadTile");
var PlaceMoonMineTile_1 = require("../../moon/PlaceMoonMineTile");
var Card_1 = require("../Card");
var CardRenderItem_1 = require("../render/CardRenderItem");
var ThoriumRush = (function (_super) {
    __extends(ThoriumRush, _super);
    function ThoriumRush() {
        return _super.call(this, {
            name: CardName_1.CardName.THORIUM_RUSH,
            cardType: CardType_1.CardType.EVENT,
            tags: [Tags_1.Tags.MOON, Tags_1.Tags.BUILDING],
            cost: 39,
            metadata: {
                description: 'Place 1 colony tile, 1 mining tile and 1 road tile on the Moon. ' +
                    'Raise the Colony Rate, Mining Rate and Logistic Rate 1 step.',
                cardNumber: 'M56',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.moonColony().secondaryTag(CardRenderItem_1.AltSecondaryTag.MOON_COLONY_RATE)
                        .moonMine().secondaryTag(CardRenderItem_1.AltSecondaryTag.MOON_MINING_RATE)
                        .moonRoad().secondaryTag(CardRenderItem_1.AltSecondaryTag.MOON_LOGISTICS_RATE);
                }),
            },
        }) || this;
    }
    ;
    ThoriumRush.prototype.play = function (player) {
        player.game.defer(new PlaceMoonColonyTile_1.PlaceMoonColonyTile(player));
        player.game.defer(new PlaceMoonMineTile_1.PlaceMoonMineTile(player));
        player.game.defer(new PlaceMoonRoadTile_1.PlaceMoonRoadTile(player));
        return undefined;
    };
    return ThoriumRush;
}(Card_1.Card));
exports.ThoriumRush = ThoriumRush;
