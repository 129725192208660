"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AncientShipyards = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var ResourceType_1 = require("../../ResourceType");
var StealResources_1 = require("../../deferredActions/StealResources");
var Resources_1 = require("../../Resources");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var Units_1 = require("../../Units");
var MoonCard_1 = require("./MoonCard");
var AncientShipyards = (function (_super) {
    __extends(AncientShipyards, _super);
    function AncientShipyards() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.ANCIENT_SHIPYARDS,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.MOON, Tags_1.Tags.SPACE],
            cost: 6,
            resourceType: ResourceType_1.ResourceType.RESOURCE_CUBE,
            reserveUnits: Units_1.Units.of({ titanium: 3 }),
            metadata: {
                description: 'Spend 3 titanium. -1 VP for every 2 resources here.',
                cardNumber: 'M19',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Steal 8 M€ from any player and add a resource cube here.', function (eb) {
                        eb.empty().startAction.text('Steal').nbsp.megacredits(8).any.colon().resourceCube(1);
                    }).br.br;
                    b.minus().titanium(3);
                }),
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.resourceCube(-1, 2),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    ;
    AncientShipyards.prototype.play = function (player) {
        _super.prototype.play.call(this, player);
        return undefined;
    };
    AncientShipyards.prototype.canAct = function () {
        return true;
    };
    AncientShipyards.prototype.action = function (player) {
        var _this = this;
        var deferredAction = new StealResources_1.StealResources(player, Resources_1.Resources.MEGACREDITS, 8);
        deferredAction.stealComplete = function () {
            player.addResourceTo(_this, 1);
        };
        player.game.defer(deferredAction);
        return undefined;
    };
    AncientShipyards.prototype.getVictoryPoints = function () {
        return -Math.floor(this.resourceCount / 2);
    };
    return AncientShipyards;
}(MoonCard_1.MoonCard));
exports.AncientShipyards = AncientShipyards;
