"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MareSerenitatisMine = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var MoonSpaces_1 = require("../../moon/MoonSpaces");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var PlaceMoonRoadTile_1 = require("../../moon/PlaceMoonRoadTile");
var Units_1 = require("../../Units");
var SpaceType_1 = require("../../SpaceType");
var TileType_1 = require("../../TileType");
var MoonCard_1 = require("./MoonCard");
var CardRenderItem_1 = require("../render/CardRenderItem");
var MareSerenitatisMine = (function (_super) {
    __extends(MareSerenitatisMine, _super);
    function MareSerenitatisMine() {
        return _super.call(this, {
            name: CardName_1.CardName.MARE_SERENITATIS_MINE,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.MOON, Tags_1.Tags.BUILDING],
            cost: 21,
            productionBox: Units_1.Units.of({ steel: 1, titanium: 1 }),
            reserveUnits: Units_1.Units.of({ steel: 1, titanium: 2 }),
            metadata: {
                description: 'Spend 2 titanium and 1 steel. Increase your steel and titanium production 1 step ' +
                    'Place a mine ON THE RESERVED AREA and a road tile adjacent to it. Raise the Mining Rate 1 step and the Logistic Rate 1 step.',
                cardNumber: 'M04',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().titanium(2).minus().steel(1).br;
                    b.production(function (pb) { return pb.steel(1).titanium(1); }).br;
                    b.moonMine().secondaryTag(CardRenderItem_1.AltSecondaryTag.MOON_MINING_RATE).asterix().nbsp.moonRoad().secondaryTag(CardRenderItem_1.AltSecondaryTag.MOON_MINING_RATE).asterix();
                }),
            },
        }, {
            tilesBuilt: [TileType_1.TileType.MOON_MINE, TileType_1.TileType.MOON_ROAD],
        }) || this;
    }
    MareSerenitatisMine.prototype.play = function (player) {
        _super.prototype.play.call(this, player);
        MoonExpansion_1.MoonExpansion.addMineTile(player, MoonSpaces_1.MoonSpaces.MARE_SERENITATIS, this.name);
        MoonExpansion_1.MoonExpansion.raiseMiningRate(player);
        var moon = MoonExpansion_1.MoonExpansion.moonData(player.game).moon;
        var spaces = moon.getAdjacentSpaces(moon.getSpace(MoonSpaces_1.MoonSpaces.MARE_SERENITATIS));
        var availableRoadSpaces = spaces.filter(function (space) {
            return space.player === undefined && space.spaceType === SpaceType_1.SpaceType.LAND;
        });
        player.game.defer(new PlaceMoonRoadTile_1.PlaceMoonRoadTile(player, 'Select a space next to Mare Serintatis to play a road', availableRoadSpaces));
        return undefined;
    };
    return MareSerenitatisMine;
}(MoonCard_1.MoonCard));
exports.MareSerenitatisMine = MareSerenitatisMine;
