"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Election = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Tags_1 = require("../../cards/Tags");
var Board_1 = require("../../boards/Board");
var LogHelper_1 = require("../../LogHelper");
var Election = (function () {
    function Election() {
        this.name = GlobalEventName_1.GlobalEventName.ELECTION;
        this.description = 'Count your influence plus Building tags and City tiles (no limits). The player with most (or 10 in solo) gains 2 TR, the 2nd (or 5 in solo) gains 1 TR (ties are friendly).';
        this.revealedDelegate = PartyName_1.PartyName.GREENS;
        this.currentDelegate = PartyName_1.PartyName.MARS;
    }
    Election.prototype.resolve = function (game, turmoil) {
        var _this = this;
        if (game.isSoloMode()) {
            if (this.getScore(game.getPlayers()[0], turmoil, game) >= 10) {
                game.getPlayers()[0].increaseTerraformRatingSteps(2);
            }
            else if (this.getScore(game.getPlayers()[0], turmoil, game) >= 1) {
                game.getPlayers()[0].increaseTerraformRatingSteps(1);
            }
        }
        else {
            var players = __spreadArray([], game.getPlayers()).sort(function (p1, p2) { return _this.getScore(p2, turmoil, game) - _this.getScore(p1, turmoil, game); });
            if (this.getScore(players[0], turmoil, game) > this.getScore(players[1], turmoil, game)) {
                players[0].increaseTerraformRatingSteps(2);
                LogHelper_1.LogHelper.logTRIncrease(players[0], 2);
                players.shift();
                if (players.length === 1) {
                    players[0].increaseTerraformRatingSteps(1);
                    LogHelper_1.LogHelper.logTRIncrease(players[0], 1);
                }
                else if (players.length > 1) {
                    if (this.getScore(players[0], turmoil, game) > this.getScore(players[1], turmoil, game)) {
                        players[0].increaseTerraformRatingSteps(1);
                        LogHelper_1.LogHelper.logTRIncrease(players[0], 1);
                    }
                    else {
                        var score = this.getScore(players[0], turmoil, game);
                        while (players.length > 0 && this.getScore(players[0], turmoil, game) === score) {
                            players[0].increaseTerraformRatingSteps(1);
                            LogHelper_1.LogHelper.logTRIncrease(players[0], 1);
                            players.shift();
                        }
                    }
                }
            }
            else {
                var score = this.getScore(players[0], turmoil, game);
                while (players.length > 0 && this.getScore(players[0], turmoil, game) === score) {
                    players[0].increaseTerraformRatingSteps(2);
                    LogHelper_1.LogHelper.logTRIncrease(players[0], 2);
                    players.shift();
                }
            }
        }
    };
    Election.prototype.getScore = function (player, turmoil, game) {
        var score = player.getTagCount(Tags_1.Tags.BUILDING, false, false) + turmoil.getPlayerInfluence(player);
        var cities = game.board.spaces.filter(function (space) { return Board_1.Board.isCitySpace(space) && space.player === player; }).length;
        return score + cities;
    };
    return Election;
}());
exports.Election = Election;
