"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TheGrandLunaCapitalGroup = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var TileType_1 = require("../../TileType");
var PlaceMoonColonyTile_1 = require("../../moon/PlaceMoonColonyTile");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var Resources_1 = require("../../Resources");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var Size_1 = require("../render/Size");
var CardRenderItem_1 = require("../render/CardRenderItem");
var Card_1 = require("../Card");
var TheGrandLunaCapitalGroup = (function (_super) {
    __extends(TheGrandLunaCapitalGroup, _super);
    function TheGrandLunaCapitalGroup() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.THE_GRAND_LUNA_CAPITAL_GROUP,
            tags: [Tags_1.Tags.CITY, Tags_1.Tags.MOON],
            startingMegaCredits: 32,
            initialActionText: 'Place a colony tile',
            metadata: {
                description: {
                    text: 'You start with 32 M€ and 1 titanium. As your first action, place a colony tile on the Moon and raise the Colony Rate 1 step.',
                    align: 'left',
                },
                cardNumber: 'MC7',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(32).titanium(1).moonColony().secondaryTag(CardRenderItem_1.AltSecondaryTag.MOON_COLONY_RATE).br;
                    b.effect('When you place a colony tile, gain 2 M€ for each adjacent colony tile.', function (eb) {
                        eb.moonColony({ size: Size_1.Size.SMALL }).any.moonColony({ size: Size_1.Size.SMALL }).asterix()
                            .startEffect
                            .megacredits(2).slash().moonColony({ size: Size_1.Size.SMALL }).any;
                    }).br,
                        b.vpText('1 VP for each colony tile adjacent to your colony tiles.').br;
                }),
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.moonColonyTile(1),
            },
        }) || this;
    }
    TheGrandLunaCapitalGroup.prototype.play = function (player) {
        player.titanium++;
        return undefined;
    };
    TheGrandLunaCapitalGroup.prototype.initialAction = function (player) {
        player.game.defer(new PlaceMoonColonyTile_1.PlaceMoonColonyTile(player));
        return undefined;
    };
    TheGrandLunaCapitalGroup.prototype.onTilePlaced = function (cardOwner, activePlayer, space) {
        if (cardOwner.id !== activePlayer.id) {
            return;
        }
        if (!MoonExpansion_1.MoonExpansion.spaceHasType(space, TileType_1.TileType.MOON_COLONY)) {
            return;
        }
        var adjacentSpaces = MoonExpansion_1.MoonExpansion.moonData(cardOwner.game).moon.getAdjacentSpaces(space);
        var filtered = adjacentSpaces.filter(function (space) { return MoonExpansion_1.MoonExpansion.spaceHasType(space, TileType_1.TileType.MOON_COLONY); });
        cardOwner.addResource(Resources_1.Resources.MEGACREDITS, filtered.length * 2, { log: true });
    };
    TheGrandLunaCapitalGroup.prototype.getVictoryPoints = function (player) {
        var moon = MoonExpansion_1.MoonExpansion.moonData(player.game).moon;
        var neighboringColonyTiles = new Set();
        var colonyTiles = MoonExpansion_1.MoonExpansion.tiles(player.game, TileType_1.TileType.MOON_COLONY, { ownedBy: player });
        colonyTiles.forEach(function (tile) {
            return moon.getAdjacentSpaces(tile).forEach(function (neighbor) {
                if (MoonExpansion_1.MoonExpansion.spaceHasType(neighbor, TileType_1.TileType.MOON_COLONY)) {
                    neighboringColonyTiles.add(neighbor.id);
                }
            });
        });
        return neighboringColonyTiles.size;
    };
    return TheGrandLunaCapitalGroup;
}(Card_1.Card));
exports.TheGrandLunaCapitalGroup = TheGrandLunaCapitalGroup;
