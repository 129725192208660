"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermafrostExtraction = void 0;
var CardType_1 = require("../CardType");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var constants_1 = require("../../constants");
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var PermafrostExtraction = (function (_super) {
    __extends(PermafrostExtraction, _super);
    function PermafrostExtraction() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.PERMAFROST_EXTRACTION,
            cost: 8,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.temperature(-8); }),
            metadata: {
                cardNumber: '191',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.oceans(1);
                }),
                description: 'Requires -8 C or warmer. Place 1 ocean tile.',
            },
        }) || this;
    }
    PermafrostExtraction.prototype.canPlay = function (player) {
        var meetsTemperatureRequirements = _super.prototype.canPlay.call(this, player);
        var oceansMaxed = player.game.board.getOceansOnBoard() === constants_1.MAX_OCEAN_TILES;
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS) && !oceansMaxed) {
            return player.canAfford(player.getCardCost(this) + constants_1.REDS_RULING_POLICY_COST) && meetsTemperatureRequirements;
        }
        return meetsTemperatureRequirements;
    };
    PermafrostExtraction.prototype.play = function (player) {
        if (player.game.board.getOceansOnBoard() === constants_1.MAX_OCEAN_TILES) {
            return undefined;
        }
        return new SelectSpace_1.SelectSpace('Select space for ocean tile', player.game.board.getAvailableSpacesForOcean(player), function (space) {
            player.game.addOceanTile(player, space.id);
            return undefined;
        });
    };
    return PermafrostExtraction;
}(Card_1.Card));
exports.PermafrostExtraction = PermafrostExtraction;
