"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.NitrophilicMoss = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var NitrophilicMoss = (function (_super) {
    __extends(NitrophilicMoss, _super);
    function NitrophilicMoss() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.NITROPHILIC_MOSS,
            tags: [Tags_1.Tags.PLANT],
            cost: 8,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.oceans(3); }),
            metadata: {
                cardNumber: '146',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.plants(2);
                    }).nbsp.minus().plants(2);
                }),
                description: 'Requires 3 ocean tiles and that you lose 2 plants. Increase your plant production 2 steps.',
            },
        }) || this;
    }
    NitrophilicMoss.prototype.canPlay = function (player) {
        var meetsCardRequirements = _super.prototype.canPlay.call(this, player);
        var hasViralEnhancers = player.playedCards.find(function (card) { return card.name === CardName_1.CardName.VIRAL_ENHANCERS; });
        var hasEnoughPlants = player.plants >= 2 || player.isCorporation(CardName_1.CardName.MANUTECH) || player.plants >= 1 && hasViralEnhancers !== undefined;
        return meetsCardRequirements && hasEnoughPlants;
    };
    NitrophilicMoss.prototype.play = function (player) {
        player.plants -= 2;
        player.addProduction(Resources_1.Resources.PLANTS, 2);
        return undefined;
    };
    return NitrophilicMoss;
}(Card_1.Card));
exports.NitrophilicMoss = NitrophilicMoss;
