"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardRenderItemType = void 0;
var CardRenderItemType;
(function (CardRenderItemType) {
    CardRenderItemType["TEMPERATURE"] = "temperature";
    CardRenderItemType["OXYGEN"] = "oxygen";
    CardRenderItemType["OCEANS"] = "oceans";
    CardRenderItemType["VENUS"] = "venus";
    CardRenderItemType["PLANTS"] = "plants";
    CardRenderItemType["TR"] = "tr";
    CardRenderItemType["HEAT"] = "heat";
    CardRenderItemType["ENERGY"] = "energy";
    CardRenderItemType["TITANIUM"] = "titanium";
    CardRenderItemType["STEEL"] = "steel";
    CardRenderItemType["MEGACREDITS"] = "megacredits";
    CardRenderItemType["CARDS"] = "cards";
    CardRenderItemType["FLOATERS"] = "floaters";
    CardRenderItemType["ASTEROIDS"] = "asteroids";
    CardRenderItemType["MICROBES"] = "microbes";
    CardRenderItemType["ANIMALS"] = "animals";
    CardRenderItemType["EVENT"] = "event";
    CardRenderItemType["SPACE"] = "space";
    CardRenderItemType["EARTH"] = "earth";
    CardRenderItemType["JOVIAN"] = "jovian";
    CardRenderItemType["BUILDING"] = "building";
    CardRenderItemType["COLONIES"] = "colonies";
    CardRenderItemType["SCIENCE"] = "science";
    CardRenderItemType["TRADE"] = "trade";
    CardRenderItemType["TRADE_DISCOUNT"] = "trade_discount";
    CardRenderItemType["TRADE_FLEET"] = "trade_fleet";
    CardRenderItemType["PLACE_COLONY"] = "place_colony";
    CardRenderItemType["CHAIRMAN"] = "chairman";
    CardRenderItemType["PARTY_LEADERS"] = "party_leaders";
    CardRenderItemType["DELEGATES"] = "delegates";
    CardRenderItemType["INFLUENCE"] = "influence";
    CardRenderItemType["NO_TAGS"] = "no_tags";
    CardRenderItemType["PRESERVATION"] = "preservation";
    CardRenderItemType["WILD"] = "wild";
    CardRenderItemType["FIGHTER"] = "fighter";
    CardRenderItemType["CAMPS"] = "camps";
    CardRenderItemType["DIVERSE_TAG"] = "diverse_tag";
    CardRenderItemType["CITY"] = "city";
    CardRenderItemType["GREENERY"] = "greenery";
    CardRenderItemType["PLATE"] = "plate";
    CardRenderItemType["TEXT"] = "text";
    CardRenderItemType["NBSP"] = "nbsp";
    CardRenderItemType["EMPTY_TILE"] = "empty_tile";
    CardRenderItemType["EMPTY_TILE_GOLDEN"] = "empty_tile_golden";
    CardRenderItemType["SELF_REPLICATING"] = "self_replicating";
    CardRenderItemType["MULTIPLIER_WHITE"] = "multiplier_white";
    CardRenderItemType["PROJECT_REQUIREMENTS"] = "project_requirements";
    CardRenderItemType["PRELUDE"] = "prelude";
    CardRenderItemType["AWARD"] = "award";
    CardRenderItemType["VP"] = "vp";
    CardRenderItemType["COMMUNITY"] = "community";
    CardRenderItemType["DISEASE"] = "disease";
    CardRenderItemType["MOON"] = "moon";
    CardRenderItemType["RESOURCE_CUBE"] = "resource_cube";
    CardRenderItemType["DATA_RESOURCE"] = "data resource";
    CardRenderItemType["MOON_COLONY"] = "moon-colony";
    CardRenderItemType["MOON_COLONY_RATE"] = "moon-colony-rate";
    CardRenderItemType["MOON_ROAD"] = "moon-road";
    CardRenderItemType["MOON_LOGISTICS_RATE"] = "moon-logistics-rate";
    CardRenderItemType["MOON_MINE"] = "moon-mine";
    CardRenderItemType["MOON_MINING_RATE"] = "moon-mine-rate";
    CardRenderItemType["SYNDICATE_FLEET"] = "syndicate-fleet";
})(CardRenderItemType = exports.CardRenderItemType || (exports.CardRenderItemType = {}));
