"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.WaterImportFromEuropa = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var constants_1 = require("../../constants");
var CardName_1 = require("../../CardName");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var PlaceOceanTile_1 = require("../../deferredActions/PlaceOceanTile");
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var WaterImportFromEuropa = (function (_super) {
    __extends(WaterImportFromEuropa, _super);
    function WaterImportFromEuropa() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.WATER_IMPORT_FROM_EUROPA,
            tags: [Tags_1.Tags.JOVIAN, Tags_1.Tags.SPACE],
            cost: 25,
            metadata: {
                cardNumber: '012',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Pay 12 M€ to place an ocean tile. TITANIUM MAY BE USED as if playing a Space card.', function (eb) {
                        eb.megacredits(12).titanium(1).brackets.startAction.oceans(1);
                    }).br;
                    b.vpText('1 VP for each Jovian tag you have.');
                }),
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.jovians(1, 1),
            },
        }) || this;
    }
    WaterImportFromEuropa.prototype.getVictoryPoints = function (player) {
        return player.getTagCount(Tags_1.Tags.JOVIAN, false, false);
    };
    WaterImportFromEuropa.prototype.play = function () {
        return undefined;
    };
    WaterImportFromEuropa.prototype.canAct = function (player) {
        var oceansMaxed = player.game.board.getOceansOnBoard() === constants_1.MAX_OCEAN_TILES;
        var oceanCost = 12;
        if (oceansMaxed)
            return player.canAfford(oceanCost, { titanium: true });
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS)) {
            return player.canAfford(oceanCost + constants_1.REDS_RULING_POLICY_COST, { titanium: true });
        }
        return player.canAfford(oceanCost, { titanium: true });
        ;
    };
    WaterImportFromEuropa.prototype.action = function (player) {
        player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, 12, { canUseTitanium: true, title: 'Select how to pay for action', afterPay: function () {
                player.game.defer(new PlaceOceanTile_1.PlaceOceanTile(player));
            } }));
        return undefined;
    };
    return WaterImportFromEuropa;
}(Card_1.Card));
exports.WaterImportFromEuropa = WaterImportFromEuropa;
