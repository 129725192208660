"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Poseidon = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var BuildColony_1 = require("../../deferredActions/BuildColony");
var CardRenderer_1 = require("../render/CardRenderer");
var Poseidon = (function (_super) {
    __extends(Poseidon, _super);
    function Poseidon() {
        return _super.call(this, {
            name: CardName_1.CardName.POSEIDON,
            startingMegaCredits: 45,
            cardType: CardType_1.CardType.CORPORATION,
            initialActionText: 'Place a colony',
            metadata: {
                cardNumber: 'R02',
                description: 'You start with 45 M€. As your first action, place a colony.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br.br;
                    b.megacredits(45).nbsp.colonies(1);
                    b.corpBox('effect', function (ce) {
                        ce.effect('When any colony is placed, including this, raise your M€ production 1 step.', function (eb) {
                            eb.colonies(1).any.startEffect.production(function (pb) { return pb.megacredits(1); });
                        });
                    });
                }),
            },
        }) || this;
    }
    Poseidon.prototype.initialAction = function (player) {
        if (player.game.gameOptions.coloniesExtension) {
            player.game.defer(new BuildColony_1.BuildColony(player, false, 'Poseidon first action - Select where to build colony'));
            return undefined;
        }
        else {
            console.warn('Colonies extension isn\'t selected.');
            return undefined;
        }
    };
    Poseidon.prototype.play = function () {
        return undefined;
    };
    return Poseidon;
}(Card_1.Card));
exports.Poseidon = Poseidon;
