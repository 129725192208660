"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Herbivores = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../ResourceType");
var TileType_1 = require("../../TileType");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var DecreaseAnyProduction_1 = require("../../deferredActions/DecreaseAnyProduction");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var Size_1 = require("../render/Size");
var Herbivores = (function (_super) {
    __extends(Herbivores, _super);
    function Herbivores() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.HERBIVORES,
            tags: [Tags_1.Tags.ANIMAL],
            cost: 12,
            resourceType: ResourceType_1.ResourceType.ANIMAL,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.oxygen(8); }),
            metadata: {
                cardNumber: '147',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('When you place a greenery tile, add an Animal to this card.', function (eb) {
                        eb.greenery(Size_1.Size.MEDIUM, false).startEffect.animals(1);
                    }).br;
                    b.vpText('1 VP per 2 Animals on this card.');
                    b.animals(1).production(function (pb) { return pb.minus().plants(1).any; });
                }),
                description: {
                    text: 'Requires 8% oxygen. +1 animal to this card. -1 any plant production',
                    align: 'left',
                },
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.animals(1, 2),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    Herbivores.prototype.canPlay = function (player) {
        return _super.prototype.canPlay.call(this, player) && player.game.someoneHasResourceProduction(Resources_1.Resources.PLANTS, 1);
    };
    Herbivores.prototype.getVictoryPoints = function () {
        return Math.floor(this.resourceCount / 2);
    };
    Herbivores.prototype.onTilePlaced = function (cardOwner, activePlayer, space) {
        var _this = this;
        var _a;
        if (cardOwner.id === activePlayer.id && ((_a = space.tile) === null || _a === void 0 ? void 0 : _a.tileType) === TileType_1.TileType.GREENERY) {
            cardOwner.game.defer(new AddResourcesToCard_1.AddResourcesToCard(cardOwner, ResourceType_1.ResourceType.ANIMAL, { filter: function (c) { return c.name === _this.name; } }));
        }
    };
    Herbivores.prototype.play = function (player) {
        player.addResourceTo(this);
        player.game.defer(new DecreaseAnyProduction_1.DecreaseAnyProduction(player, Resources_1.Resources.PLANTS, 1));
        return undefined;
    };
    return Herbivores;
}(Card_1.Card));
exports.Herbivores = Herbivores;
