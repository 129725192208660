"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var CardName_1 = require("@/CardName");
var Resources_1 = require("@/Resources");
exports.default = vue_1.default.extend({
    name: 'CardExtraContent',
    props: {
        card: {
            type: Object,
            required: true,
        },
    },
    methods: {
        lifeFound: function (card) {
            return card.name === CardName_1.CardName.SEARCH_FOR_LIFE && card.resources !== undefined && card.resources > 0;
        },
        isMiningTileOnMetal: function (card, metal) {
            var miningCard = [CardName_1.CardName.MINING_RIGHTS, CardName_1.CardName.MINING_AREA, CardName_1.CardName.MINING_RIGHTS_ARES, CardName_1.CardName.MINING_AREA_ARES];
            if (miningCard.includes(card.name)) {
                if (metal === Resources_1.Resources.TITANIUM) {
                    return card.bonusResource === Resources_1.Resources.TITANIUM;
                }
                else if (metal === Resources_1.Resources.STEEL) {
                    return card.bonusResource === Resources_1.Resources.STEEL;
                }
            }
            return false;
        },
        miningTileOnTitanium: function (card) {
            return card.name === CardName_1.CardName.SEARCH_FOR_LIFE && card.resources !== undefined && card.resources > 0;
        },
    },
});
