"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImprovedEnergyTemplates = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var Tags_1 = require("../../cards/Tags");
var ImprovedEnergyTemplates = (function () {
    function ImprovedEnergyTemplates() {
        this.name = GlobalEventName_1.GlobalEventName.IMPROVED_ENERGY_TEMPLATES;
        this.description = 'Increase energy production 1 step per 2 power tags (no limit). Influence counts as power tags.';
        this.revealedDelegate = PartyName_1.PartyName.SCIENTISTS;
        this.currentDelegate = PartyName_1.PartyName.KELVINISTS;
    }
    ImprovedEnergyTemplates.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            player.addProduction(Resources_1.Resources.ENERGY, Math.floor((player.getTagCount(Tags_1.Tags.ENERGY, false, false) + turmoil.getPlayerInfluence(player)) / 2), { log: true, from: _this.name });
        });
    };
    return ImprovedEnergyTemplates;
}());
exports.ImprovedEnergyTemplates = ImprovedEnergyTemplates;
