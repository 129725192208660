"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DryDeserts = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var RemoveOceanTile_1 = require("../../deferredActions/RemoveOceanTile");
var SelectResourcesDeferred_1 = require("../../deferredActions/SelectResourcesDeferred");
var constants_1 = require("../../constants");
var DryDeserts = (function () {
    function DryDeserts() {
        this.name = GlobalEventName_1.GlobalEventName.DRY_DESERTS;
        this.description = 'First player removes 1 ocean tile from the gameboard. Gain 1 standard resource per influence.';
        this.revealedDelegate = PartyName_1.PartyName.REDS;
        this.currentDelegate = PartyName_1.PartyName.UNITY;
    }
    DryDeserts.prototype.resolve = function (game, turmoil) {
        var oceansPlaced = game.board.getOceansOnBoard();
        var canRemoveOcean = oceansPlaced > 0 && oceansPlaced !== constants_1.MAX_OCEAN_TILES;
        if (canRemoveOcean) {
            game.defer(new RemoveOceanTile_1.RemoveOceanTile(game.getPlayers()[0], 'Dry Deserts Global Event - Remove an Ocean tile from the board'));
        }
        game.getPlayers().forEach(function (player) {
            var count = turmoil.getPlayerInfluence(player);
            if (count > 0) {
                game.defer(new SelectResourcesDeferred_1.SelectResourcesDeferred(player, count, 'Dry Deserts Global Event - Gain ' + count + ' resource(s) for influence'));
            }
        });
    };
    return DryDeserts;
}());
exports.DryDeserts = DryDeserts;
