"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.StripMine = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var constants_1 = require("../../constants");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var StripMine = (function (_super) {
    __extends(StripMine, _super);
    function StripMine() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.STRIP_MINE,
            tags: [Tags_1.Tags.BUILDING],
            cost: 25,
            productionBox: Units_1.Units.of({ energy: -2, steel: 2, titanium: 1 }),
            metadata: {
                cardNumber: '138',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.minus().energy(2).br;
                        pb.plus().steel(2).titanium(1);
                    }).br;
                    b.oxygen(2);
                }),
                description: 'Decrease your Energy production 2 steps. Increase your steel production 2 steps and your titanium production 1 step. Raise oxygen 2 steps.',
            },
        }) || this;
    }
    StripMine.prototype.canPlay = function (player) {
        var hasEnergyProduction = player.getProduction(Resources_1.Resources.ENERGY) >= 2;
        var remainingOxygenSteps = constants_1.MAX_OXYGEN_LEVEL - player.game.getOxygenLevel();
        var stepsRaised = Math.min(remainingOxygenSteps, 2);
        var requiredMC = constants_1.REDS_RULING_POLICY_COST * stepsRaised;
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS)) {
            return player.canAfford(player.getCardCost(this) + requiredMC, { steel: true }) && player.canAfford(requiredMC) && hasEnergyProduction;
        }
        return hasEnergyProduction;
    };
    StripMine.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.ENERGY, -2);
        player.addProduction(Resources_1.Resources.STEEL, 2);
        player.addProduction(Resources_1.Resources.TITANIUM, 1);
        return player.game.increaseOxygenLevel(player, 2);
    };
    return StripMine;
}(Card_1.Card));
exports.StripMine = StripMine;
