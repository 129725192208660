"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Hackers = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var DecreaseAnyProduction_1 = require("../../deferredActions/DecreaseAnyProduction");
var CardRenderer_1 = require("../render/CardRenderer");
var Hackers = (function (_super) {
    __extends(Hackers, _super);
    function Hackers() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.HACKERS,
            cost: 3,
            metadata: {
                cardNumber: '125',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.minus().energy(1).megacredits(2).any.br;
                        pb.plus().megacredits(2);
                    });
                }),
                description: 'Decrease your energy production 1 step and any M€ production 2 steps. increase your M€ production 2 steps.',
                victoryPoints: -1,
            },
        }) || this;
    }
    Hackers.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.ENERGY) >= 1;
    };
    Hackers.prototype.play = function (player) {
        player.game.defer(new DecreaseAnyProduction_1.DecreaseAnyProduction(player, Resources_1.Resources.MEGACREDITS, 2));
        player.addProduction(Resources_1.Resources.MEGACREDITS, 2);
        player.addProduction(Resources_1.Resources.ENERGY, -1);
        return undefined;
    };
    Hackers.prototype.getVictoryPoints = function () {
        return -1;
    };
    return Hackers;
}(Card_1.Card));
exports.Hackers = Hackers;
