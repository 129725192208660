"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResearchOutpost = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var ResearchOutpost = (function (_super) {
    __extends(ResearchOutpost, _super);
    function ResearchOutpost() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.RESEARCH_OUTPOST,
            tags: [Tags_1.Tags.SCIENCE, Tags_1.Tags.CITY, Tags_1.Tags.BUILDING],
            cost: 18,
            cardDiscount: { amount: 1 },
            metadata: {
                cardNumber: '020',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('When you play a card, you pay 1 M€ less for it.', function (eb) {
                        eb.empty().startEffect.megacredits(-1);
                    }).br;
                    b.city();
                }),
                description: 'Place a city tile NEXT TO NO OTHER TILE.',
            },
        }) || this;
    }
    ResearchOutpost.prototype.getAvailableSpaces = function (player) {
        return player.game.board.getAvailableSpacesOnLand(player)
            .filter(function (space) {
            var adjacentSpaces = player.game.board.getAdjacentSpaces(space);
            return adjacentSpaces.filter(function (space) { return space.tile !== undefined; }).length === 0;
        });
    };
    ResearchOutpost.prototype.canPlay = function (player) {
        return this.getAvailableSpaces(player).length > 0;
    };
    ResearchOutpost.prototype.getCardDiscount = function () {
        return 1;
    };
    ResearchOutpost.prototype.play = function (player) {
        return new SelectSpace_1.SelectSpace('Select place next to no other tile for city', this.getAvailableSpaces(player), function (foundSpace) {
            player.game.addCityTile(player, foundSpace.id);
            return undefined;
        });
    };
    return ResearchOutpost;
}(Card_1.Card));
exports.ResearchOutpost = ResearchOutpost;
