"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArtificialLake = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var SpaceType_1 = require("../../SpaceType");
var CardName_1 = require("../../CardName");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var constants_1 = require("../../constants");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var ArtificialLake = (function (_super) {
    __extends(ArtificialLake, _super);
    function ArtificialLake() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.ARTIFICIAL_LAKE,
            tags: [Tags_1.Tags.BUILDING],
            cost: 15,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.temperature(-6); }),
            metadata: {
                description: 'Requires -6 C or warmer. Place 1 ocean tile ON AN AREA NOT RESERVED FOR OCEAN.',
                cardNumber: '116',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) { return b.oceans(1).asterix(); }),
                victoryPoints: 1,
            },
        }) || this;
    }
    ArtificialLake.prototype.canPlay = function (player) {
        if (!_super.prototype.canPlay.call(this, player)) {
            return false;
        }
        var oceansMaxed = player.game.board.getOceansOnBoard() === constants_1.MAX_OCEAN_TILES;
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS) && !oceansMaxed) {
            return player.canAfford(player.getCardCost(this) + constants_1.REDS_RULING_POLICY_COST, { steel: true });
        }
        return true;
    };
    ArtificialLake.prototype.play = function (player) {
        if (player.game.board.getOceansOnBoard() >= constants_1.MAX_OCEAN_TILES)
            return undefined;
        return new SelectSpace_1.SelectSpace('Select a land space to place an ocean', player.game.board.getAvailableSpacesOnLand(player), function (foundSpace) {
            player.game.addOceanTile(player, foundSpace.id, SpaceType_1.SpaceType.LAND);
            return undefined;
        });
    };
    ArtificialLake.prototype.getVictoryPoints = function () {
        return 1;
    };
    return ArtificialLake;
}(Card_1.Card));
exports.ArtificialLake = ArtificialLake;
