"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpecialDesign = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var SpecialDesign = (function (_super) {
    __extends(SpecialDesign, _super);
    function SpecialDesign() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.SPECIAL_DESIGN,
            tags: [Tags_1.Tags.SCIENCE],
            cost: 4,
            metadata: {
                cardNumber: '206',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.plate('Global requirements').colon().text('+/- 2');
                }),
                description: 'The next card you play this generation is +2 or -2 steps in global requirements, your choice.',
            },
        }) || this;
    }
    SpecialDesign.prototype.getRequirementBonus = function (player) {
        if (player.lastCardPlayed !== undefined && player.lastCardPlayed.name === this.name) {
            return 2;
        }
        return 0;
    };
    SpecialDesign.prototype.play = function () {
        return undefined;
    };
    return SpecialDesign;
}(Card_1.Card));
exports.SpecialDesign = SpecialDesign;
