"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.HydrogenToVenus = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../ResourceType");
var SelectCard_1 = require("../../inputs/SelectCard");
var CardName_1 = require("../../CardName");
var constants_1 = require("../../constants");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var HydrogenToVenus = (function (_super) {
    __extends(HydrogenToVenus, _super);
    function HydrogenToVenus() {
        return _super.call(this, {
            name: CardName_1.CardName.HYDROGEN_TO_VENUS,
            cardType: CardType_1.CardType.EVENT,
            tags: [Tags_1.Tags.SPACE],
            cost: 11,
            metadata: {
                cardNumber: '231',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.venus(1).br.br;
                    b.floaters(1).secondaryTag(Tags_1.Tags.VENUS).slash().jovian().played;
                }),
                description: 'Raise Venus 1 step. Add 1 Floater to A Venus CARD for each Jovian tag you have.',
            },
        }) || this;
    }
    ;
    HydrogenToVenus.prototype.canPlay = function (player) {
        var venusMaxed = player.game.getVenusScaleLevel() === constants_1.MAX_VENUS_SCALE;
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS) && !venusMaxed) {
            return player.canAfford(player.getCardCost(this) + constants_1.REDS_RULING_POLICY_COST, { titanium: true });
        }
        return true;
    };
    HydrogenToVenus.prototype.play = function (player) {
        var jovianTags = player.getTagCount(Tags_1.Tags.JOVIAN);
        var floatersCards = player.getResourceCards(ResourceType_1.ResourceType.FLOATER).filter(function (card) { return card.tags.includes(Tags_1.Tags.VENUS); });
        if (jovianTags > 0) {
            if (floatersCards.length === 1) {
                player.addResourceTo(floatersCards[0], { qty: jovianTags, log: true });
            }
            if (floatersCards.length > 1) {
                return new SelectCard_1.SelectCard('Select card to add ' + jovianTags + ' floater(s)', 'Add floater(s)', floatersCards, function (foundCards) {
                    player.addResourceTo(foundCards[0], { qty: jovianTags, log: true });
                    player.game.increaseVenusScaleLevel(player, 1);
                    return undefined;
                });
            }
        }
        player.game.increaseVenusScaleLevel(player, 1);
        return undefined;
    };
    return HydrogenToVenus;
}(Card_1.Card));
exports.HydrogenToVenus = HydrogenToVenus;
