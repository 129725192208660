"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImprovedMoonConcrete = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var MoonCard_1 = require("./MoonCard");
var ImprovedMoonConcrete = (function (_super) {
    __extends(ImprovedMoonConcrete, _super);
    function ImprovedMoonConcrete() {
        return _super.call(this, {
            name: CardName_1.CardName.IMPROVED_MOON_CONCRETE,
            cardType: CardType_1.CardType.AUTOMATED,
            cost: 12,
            reserveUnits: Units_1.Units.of({ steel: 2 }),
            metadata: {
                description: 'Spend 2 steel. Raise the Mining Rate 1 step.',
                cardNumber: 'M37',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('When you build a mine on the Moon, you spend 1 titanium less.', function (eb) {
                        eb.moonMine().startEffect.minus().titanium(1);
                    }).br;
                    b.minus().steel(2).moonMiningRate();
                }),
            },
        }) || this;
    }
    ImprovedMoonConcrete.prototype.play = function (player) {
        _super.prototype.play.call(this, player);
        MoonExpansion_1.MoonExpansion.raiseMiningRate(player);
        return undefined;
    };
    return ImprovedMoonConcrete;
}(MoonCard_1.MoonCard));
exports.ImprovedMoonConcrete = ImprovedMoonConcrete;
