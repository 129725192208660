"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dirigibles = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../ResourceType");
var SelectCard_1 = require("../../inputs/SelectCard");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var Dirigibles = (function (_super) {
    __extends(Dirigibles, _super);
    function Dirigibles() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.DIRIGIBLES,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.VENUS],
            cost: 11,
            resourceType: ResourceType_1.ResourceType.FLOATER,
            metadata: {
                cardNumber: '222',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add 1 Floater to ANY card', function (eb) {
                        eb.empty().startAction.floaters(1).asterix();
                    }).br;
                    b.effect('When playing a Venus tag, Floaters here may be used as payment, and are worth 3M€ each.', function (eb) {
                        eb.venus(1).played.startEffect.floaters(1).equals().megacredits(3);
                    });
                }),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    ;
    Dirigibles.prototype.play = function () {
        return undefined;
    };
    Dirigibles.prototype.canAct = function () {
        return true;
    };
    Dirigibles.prototype.action = function (player) {
        var floaterCards = player.getResourceCards(ResourceType_1.ResourceType.FLOATER);
        if (floaterCards.length === 1) {
            player.addResourceTo(this, { log: true });
            return undefined;
        }
        return new SelectCard_1.SelectCard('Select card to add 1 floater', 'Add floater', floaterCards, function (foundCards) {
            player.addResourceTo(foundCards[0], { log: true });
            return undefined;
        });
    };
    return Dirigibles;
}(Card_1.Card));
exports.Dirigibles = Dirigibles;
