"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArcadianCommunities = void 0;
var Card_1 = require("../Card");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var ArcadianCommunities = (function (_super) {
    __extends(ArcadianCommunities, _super);
    function ArcadianCommunities() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.ARCADIAN_COMMUNITIES,
            startingMegaCredits: 40,
            initialActionText: 'Place a community (player marker) on a non-reserved area',
            metadata: {
                cardNumber: 'R44',
                description: 'You start with 40 M€ and 10 steel. AS YOUR FIRST ACTION, PLACE A COMMUNITY [PLAYER MARKER] ON A NON-RESERVED AREA.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br;
                    b.megacredits(40).nbsp.steel(10).digit.nbsp.community().asterix();
                    b.corpBox('action', function (ce) {
                        ce.text('ACTION: PLACE A COMMUNITY (PLAYER MARKER) ON A NON-RESERVED AREA ADJACENT TO ONE OF YOUR TILES OR MARKED AREAS', Size_1.Size.TINY, true);
                        ce.vSpace(Size_1.Size.MEDIUM);
                        ce.text('EFFECT: MARKED AREAS ARE RESERVED FOR YOU. WHEN YOU PLACE A TILE THERE, GAIN 3 M€', Size_1.Size.TINY, true);
                    });
                }),
            },
        }) || this;
    }
    ArcadianCommunities.prototype.initialAction = function (player) {
        return new SelectSpace_1.SelectSpace('Select space for claim', player.game.board.getAvailableSpacesOnLand(player), function (foundSpace) {
            foundSpace.player = player;
            player.game.log('${0} placed a Community (player marker)', function (b) { return b.player(player); });
            return undefined;
        });
    };
    ArcadianCommunities.prototype.canAct = function (player) {
        return player.game.board.getAvailableSpacesForMarker(player).length > 0;
    };
    ArcadianCommunities.prototype.action = function (player) {
        return new SelectSpace_1.SelectSpace('Select space for claim', player.game.board.getAvailableSpacesForMarker(player), function (foundSpace) {
            foundSpace.player = player;
            return undefined;
        });
    };
    ArcadianCommunities.prototype.play = function (player) {
        player.steel = 10;
        return undefined;
    };
    return ArcadianCommunities;
}(Card_1.Card));
exports.ArcadianCommunities = ArcadianCommunities;
