"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardFinder = void 0;
var ColoniesCardManifest_1 = require("./cards/colonies/ColoniesCardManifest");
var PreludeCardManifest_1 = require("./cards/prelude/PreludeCardManifest");
var PromoCardManifest_1 = require("./cards/promo/PromoCardManifest");
var StandardCardManifests_1 = require("./cards/StandardCardManifests");
var TurmoilCardManifest_1 = require("./cards/turmoil/TurmoilCardManifest");
var VenusCardManifest_1 = require("./cards/venusNext/VenusCardManifest");
var CommunityCardManifest_1 = require("./cards/community/CommunityCardManifest");
var AresCardManifest_1 = require("./cards/ares/AresCardManifest");
var MoonCardManifest_1 = require("./cards/moon/MoonCardManifest");
var CardFinder = (function () {
    function CardFinder() {
    }
    CardFinder.getDecks = function () {
        if (CardFinder.decks === undefined) {
            CardFinder.decks = [
                StandardCardManifests_1.BASE_CARD_MANIFEST,
                StandardCardManifests_1.CORP_ERA_CARD_MANIFEST,
                PromoCardManifest_1.PROMO_CARD_MANIFEST,
                VenusCardManifest_1.VENUS_CARD_MANIFEST,
                ColoniesCardManifest_1.COLONIES_CARD_MANIFEST,
                PreludeCardManifest_1.PRELUDE_CARD_MANIFEST,
                TurmoilCardManifest_1.TURMOIL_CARD_MANIFEST,
                AresCardManifest_1.ARES_CARD_MANIFEST,
                CommunityCardManifest_1.COMMUNITY_CARD_MANIFEST,
                MoonCardManifest_1.MOON_CARD_MANIFEST,
            ];
        }
        return CardFinder.decks;
    };
    CardFinder.prototype.getCardByName = function (cardName, decks) {
        var found;
        CardFinder.getDecks().some(function (manifest) {
            decks(manifest).some(function (deck) {
                found = deck.findByCardName(cardName);
                return found;
            });
            return found;
        });
        if (found !== undefined) {
            return new found.Factory();
        }
        console.warn("card not found " + cardName);
        return undefined;
    };
    CardFinder.prototype.getCorporationCardByName = function (cardName) {
        return this.getCardByName(cardName, function (manifest) { return [manifest.corporationCards]; });
    };
    CardFinder.prototype.getProjectCardByName = function (cardName) {
        return this.getCardByName(cardName, function (manifest) { return [manifest.projectCards, manifest.preludeCards]; });
    };
    CardFinder.prototype.cardsFromJSON = function (cards) {
        var _this = this;
        if (cards === undefined) {
            console.warn('missing cards calling cardsFromJSON');
            return [];
        }
        var result = [];
        cards.forEach(function (element) {
            if (typeof element !== 'string') {
                element = element.name;
            }
            var card = _this.getProjectCardByName(element);
            if (card !== undefined) {
                result.push(card);
            }
            else {
                console.warn("card " + element + " not found while loading game.");
            }
        });
        return result;
    };
    CardFinder.prototype.corporationCardsFromJSON = function (cards) {
        var _this = this;
        if (cards === undefined) {
            console.warn('missing cards calling corporationCardsFromJSON');
            return [];
        }
        var result = [];
        cards.forEach(function (element) {
            if (typeof element !== 'string') {
                element = element.name;
            }
            var card = _this.getCorporationCardByName(element);
            if (card !== undefined) {
                result.push(card);
            }
            else {
                console.warn("corporation " + element + " not found while loading game.");
            }
        });
        return result;
    };
    return CardFinder;
}());
exports.CardFinder = CardFinder;
