"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TitanFloatingLaunchPad = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var ResourceType_1 = require("../../ResourceType");
var SelectOption_1 = require("../../inputs/SelectOption");
var OrOptions_1 = require("../../inputs/OrOptions");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var SelectColony_1 = require("../../inputs/SelectColony");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var TitanFloatingLaunchPad = (function (_super) {
    __extends(TitanFloatingLaunchPad, _super);
    function TitanFloatingLaunchPad() {
        var _this = _super.call(this, {
            cost: 18,
            tags: [Tags_1.Tags.JOVIAN],
            name: CardName_1.CardName.TITAN_FLOATING_LAUNCHPAD,
            cardType: CardType_1.CardType.ACTIVE,
            resourceType: ResourceType_1.ResourceType.FLOATER,
            metadata: {
                cardNumber: 'C44',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action(undefined, function (eb) {
                        eb.empty().startAction.floaters(1).secondaryTag(Tags_1.Tags.JOVIAN).nbsp.or();
                    }).br;
                    b.action('Add 1 floater to ANY JOVIAN CARD or spend 1 floater here to trade for free.', function (eb) {
                        eb.floaters(1).startAction.trade();
                    }).br.br;
                    b.floaters(2).secondaryTag(Tags_1.Tags.JOVIAN);
                }),
                description: {
                    text: 'Add two floaters to ANY JOVIAN CARD.',
                    align: 'left',
                },
                victoryPoints: 1,
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    TitanFloatingLaunchPad.prototype.canAct = function () {
        return true;
    };
    TitanFloatingLaunchPad.prototype.action = function (player) {
        var _this = this;
        var openColonies = player.game.colonies.filter(function (colony) { return colony.isActive && colony.visitor === undefined; });
        if (this.resourceCount === 0 || openColonies.length === 0 || player.getFleetSize() <= player.tradesThisGeneration) {
            player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.FLOATER, { restrictedTag: Tags_1.Tags.JOVIAN, title: 'Add 1 floater to a Jovian card' }));
            return undefined;
        }
        return new OrOptions_1.OrOptions(new SelectOption_1.SelectOption('Add 1 floater to a Jovian card', 'Add floater', function () {
            player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.FLOATER, { restrictedTag: Tags_1.Tags.JOVIAN }));
            return undefined;
        }), new SelectOption_1.SelectOption('Remove 1 floater on this card to trade for free', 'Remove floater', function () {
            var coloniesModel = player.game.getColoniesModel(openColonies);
            player.game.defer(new DeferredAction_1.DeferredAction(player, function () { return new SelectColony_1.SelectColony('Select colony tile to trade with for free', 'Select', coloniesModel, function (colonyName) {
                openColonies.forEach(function (colony) {
                    if (colony.name === colonyName) {
                        _this.resourceCount--;
                        player.game.log('${0} spent 1 floater to trade with ${1}', function (b) { return b.player(player).colony(colony); });
                        colony.trade(player);
                        return undefined;
                    }
                    return undefined;
                });
                return undefined;
            }); }));
            return undefined;
        }));
    };
    TitanFloatingLaunchPad.prototype.play = function (player) {
        player.game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.FLOATER, { count: 2, restrictedTag: Tags_1.Tags.JOVIAN }));
        return undefined;
    };
    TitanFloatingLaunchPad.prototype.getVictoryPoints = function () {
        return 1;
    };
    return TitanFloatingLaunchPad;
}(Card_1.Card));
exports.TitanFloatingLaunchPad = TitanFloatingLaunchPad;
