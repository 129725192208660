"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Productivity = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var Productivity = (function () {
    function Productivity() {
        this.name = GlobalEventName_1.GlobalEventName.PRODUCTIVITY;
        this.description = 'Gain 1 steel for each steel production (max 5) and influence.';
        this.revealedDelegate = PartyName_1.PartyName.SCIENTISTS;
        this.currentDelegate = PartyName_1.PartyName.MARS;
    }
    Productivity.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            player.addResource(Resources_1.Resources.STEEL, Math.min(5, player.getProduction(Resources_1.Resources.STEEL)) + turmoil.getPlayerInfluence(player), { log: true, from: _this.name });
        });
    };
    return Productivity;
}());
exports.Productivity = Productivity;
