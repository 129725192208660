"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MareNectarisMine = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var MoonSpaces_1 = require("../../moon/MoonSpaces");
var Units_1 = require("../../Units");
var TileType_1 = require("../../TileType");
var MoonCard_1 = require("./MoonCard");
var CardRenderItem_1 = require("../render/CardRenderItem");
var MareNectarisMine = (function (_super) {
    __extends(MareNectarisMine, _super);
    function MareNectarisMine() {
        return _super.call(this, {
            name: CardName_1.CardName.MARE_NECTARIS_MINE,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.MOON, Tags_1.Tags.BUILDING],
            cost: 14,
            productionBox: Units_1.Units.of({ steel: 1 }),
            reserveUnits: Units_1.Units.of({ titanium: 1 }),
            metadata: {
                description: 'Spend 1 titanium. Increase your steel production 1 step. Place a mine ON THE RESERVED AREA and raise the Mining Rate 1 step.',
                cardNumber: 'M01',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().titanium(1).nbsp;
                    b.production(function (pb) { return pb.steel(1); });
                    b.moonMine().secondaryTag(CardRenderItem_1.AltSecondaryTag.MOON_MINING_RATE).asterix();
                }),
            },
        }, {
            tilesBuilt: [TileType_1.TileType.MOON_MINE],
        }) || this;
    }
    MareNectarisMine.prototype.play = function (player) {
        _super.prototype.play.call(this, player);
        MoonExpansion_1.MoonExpansion.addMineTile(player, MoonSpaces_1.MoonSpaces.MARE_NECTARIS, this.name);
        MoonExpansion_1.MoonExpansion.raiseMiningRate(player);
        return undefined;
    };
    return MareNectarisMine;
}(MoonCard_1.MoonCard));
exports.MareNectarisMine = MareNectarisMine;
