"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.WaterTreatmentComplex = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var MoonCard_1 = require("./MoonCard");
var CardRequirements_1 = require("../CardRequirements");
var WaterTreatmentComplex = (function (_super) {
    __extends(WaterTreatmentComplex, _super);
    function WaterTreatmentComplex() {
        return _super.call(this, {
            name: CardName_1.CardName.WATER_TREATMENT_COMPLEX,
            cardType: CardType_1.CardType.AUTOMATED,
            cost: 12,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.colonyTiles(1).any(); }),
            reserveUnits: Units_1.Units.of({ titanium: 1 }),
            metadata: {
                description: 'Requires 1 colony tile on the Moon. Spend 1 titanium. Raise the Colony Rate 2 steps.',
                cardNumber: 'M46',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) { return b.minus().titanium(1).br.moonColonyRate({ amount: 2 }); }),
            },
        }) || this;
    }
    ;
    WaterTreatmentComplex.prototype.play = function (player) {
        _super.prototype.play.call(this, player);
        MoonExpansion_1.MoonExpansion.raiseColonyRate(player, 2);
        return undefined;
    };
    return WaterTreatmentComplex;
}(MoonCard_1.MoonCard));
exports.WaterTreatmentComplex = WaterTreatmentComplex;
