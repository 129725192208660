"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardRenderItem = exports.AltSecondaryTag = void 0;
var AltSecondaryTag;
(function (AltSecondaryTag) {
    AltSecondaryTag["REQ"] = "req";
    AltSecondaryTag["OXYGEN"] = "oxygen";
    AltSecondaryTag["TURMOIL"] = "turmoil";
    AltSecondaryTag["FLOATER"] = "floater";
    AltSecondaryTag["BLUE"] = "blue";
    AltSecondaryTag["MOON_MINING_RATE"] = "moon-mine";
    AltSecondaryTag["MOON_COLONY_RATE"] = "moon-colony";
    AltSecondaryTag["MOON_LOGISTICS_RATE"] = "moon-road";
})(AltSecondaryTag = exports.AltSecondaryTag || (exports.AltSecondaryTag = {}));
var CardRenderItem = (function () {
    function CardRenderItem(type, amount) {
        if (amount === void 0) { amount = -1; }
        this.type = type;
        this.amount = amount;
        this.multiplier = false;
        this.cancelled = false;
        if (Math.abs(this.amount) > 5) {
            this.showDigit = true;
        }
    }
    CardRenderItem.prototype.withOptions = function (options) {
        if (options === undefined) {
            return this;
        }
        this.size = options.size;
        if (options.amount !== undefined) {
            this.amount = options.amount;
        }
        return this;
    };
    return CardRenderItem;
}());
exports.CardRenderItem = CardRenderItem;
