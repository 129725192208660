"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.BactoviralResearch = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var ResourceType_1 = require("../../ResourceType");
var SelectCard_1 = require("../../inputs/SelectCard");
var BactoviralResearch = (function (_super) {
    __extends(BactoviralResearch, _super);
    function BactoviralResearch() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.BACTOVIRAL_RESEARCH,
            tags: [Tags_1.Tags.MICROBE, Tags_1.Tags.SCIENCE],
            cost: 10,
            metadata: {
                cardNumber: 'X35',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.cards(1).br.br;
                    b.microbes(1).asterix().slash().science().played;
                }),
                description: 'Draw 1 card. Choose 1 of your played cards and add 1 microbe to it for each science tag you have, including this.',
            },
        }) || this;
    }
    BactoviralResearch.prototype.play = function (player) {
        player.drawCard();
        var scienceTags = player.getTagCount(Tags_1.Tags.SCIENCE) + 1;
        var microbeCards = player.getResourceCards(ResourceType_1.ResourceType.MICROBE);
        if (microbeCards.length === 0) {
            return undefined;
        }
        else if (microbeCards.length === 1) {
            player.addResourceTo(microbeCards[0], { qty: scienceTags, log: true });
        }
        else if (microbeCards.length > 1) {
            return new SelectCard_1.SelectCard('Select card to add ' + scienceTags + ' microbe(s)', 'Add microbe(s)', microbeCards, function (foundCards) {
                player.addResourceTo(foundCards[0], { qty: scienceTags, log: true });
                return undefined;
            });
        }
        return undefined;
    };
    return BactoviralResearch;
}(Card_1.Card));
exports.BactoviralResearch = BactoviralResearch;
