"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CityStandardProject = void 0;
var CardName_1 = require("../../../CardName");
var CardRenderer_1 = require("../../render/CardRenderer");
var StandardProjectCard_1 = require("../../StandardProjectCard");
var PlaceCityTile_1 = require("../../../deferredActions/PlaceCityTile");
var Resources_1 = require("../../../Resources");
var CityStandardProject = (function (_super) {
    __extends(CityStandardProject, _super);
    function CityStandardProject() {
        return _super.call(this, {
            name: CardName_1.CardName.CITY_STANDARD_PROJECT,
            cost: 25,
            metadata: {
                cardNumber: 'SP4',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    return b.standardProject('Spend 25 M€ to place a city tile and increase your M€ production 1 step.', function (eb) {
                        eb.megacredits(25).startAction.city().production(function (pb) {
                            pb.megacredits(1);
                        });
                    });
                }),
            },
        }) || this;
    }
    CityStandardProject.prototype.canAct = function (player) {
        return player.canAfford(this.cost) && player.game.board.getAvailableSpacesForCity(player).length > 0;
    };
    CityStandardProject.prototype.actionEssence = function (player) {
        player.game.defer(new PlaceCityTile_1.PlaceCityTile(player));
        player.addProduction(Resources_1.Resources.MEGACREDITS, 1);
    };
    return CityStandardProject;
}(StandardProjectCard_1.StandardProjectCard));
exports.CityStandardProject = CityStandardProject;
