"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.InterplanetaryCinematics = void 0;
var Card_1 = require("../Card");
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var InterplanetaryCinematics = (function (_super) {
    __extends(InterplanetaryCinematics, _super);
    function InterplanetaryCinematics() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.INTERPLANETARY_CINEMATICS,
            tags: [Tags_1.Tags.BUILDING],
            startingMegaCredits: 30,
            metadata: {
                cardNumber: 'R19',
                description: 'You start with 20 steel and 30 M€.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br.br.br;
                    b.megacredits(30).nbsp.steel(20).digit;
                    b.corpBox('effect', function (ce) {
                        ce.effect('Each time you play an event, you gain 2 M€.', function (eb) {
                            eb.event().played.startEffect.megacredits(2);
                        });
                    });
                }),
            },
        }) || this;
    }
    InterplanetaryCinematics.prototype.onCardPlayed = function (player, card) {
        if (player.corporationCard !== undefined && player.corporationCard.name === this.name && card.cardType === CardType_1.CardType.EVENT) {
            player.megaCredits += 2;
        }
    };
    InterplanetaryCinematics.prototype.play = function (player) {
        player.steel = 20;
        return undefined;
    };
    return InterplanetaryCinematics;
}(Card_1.Card));
exports.InterplanetaryCinematics = InterplanetaryCinematics;
