"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardRenderCorpBoxAction = exports.CardRenderCorpBoxEffect = exports.CardRenderEffect = exports.CardRenderTile = exports.CardRenderProductionBox = exports.CardRenderer = void 0;
var CardRenderItem_1 = require("./CardRenderItem");
var CardRenderSymbol_1 = require("./CardRenderSymbol");
var Size_1 = require("./Size");
var CardRenderItemType_1 = require("./CardRenderItemType");
var CardRenderer = (function () {
    function CardRenderer(_rows) {
        if (_rows === void 0) { _rows = [[]]; }
        this._rows = _rows;
    }
    Object.defineProperty(CardRenderer.prototype, "rows", {
        get: function () {
            return this._rows;
        },
        enumerable: false,
        configurable: true
    });
    CardRenderer.builder = function (f) {
        var builder = new Builder();
        f(builder);
        return builder.build();
    };
    return CardRenderer;
}());
exports.CardRenderer = CardRenderer;
var CardRenderProductionBox = (function (_super) {
    __extends(CardRenderProductionBox, _super);
    function CardRenderProductionBox(rows) {
        return _super.call(this, rows) || this;
    }
    CardRenderProductionBox.builder = function (f) {
        var builder = new ProductionBoxBuilder();
        f(builder);
        return builder.build();
    };
    return CardRenderProductionBox;
}(CardRenderer));
exports.CardRenderProductionBox = CardRenderProductionBox;
var CardRenderTile = (function () {
    function CardRenderTile(tile, hasSymbol, isAres) {
        this.tile = tile;
        this.hasSymbol = hasSymbol;
        this.isAres = isAres;
    }
    ;
    return CardRenderTile;
}());
exports.CardRenderTile = CardRenderTile;
var CardRenderEffect = (function (_super) {
    __extends(CardRenderEffect, _super);
    function CardRenderEffect(rows) {
        return _super.call(this, rows) || this;
    }
    CardRenderEffect.builder = function (f) {
        var builder = new EffectBuilder();
        f(builder);
        return builder.build();
    };
    CardRenderEffect.prototype._validate = function () {
        if (this.rows.length !== 3) {
            throw new Error('Card effect must have 3 arrays representing cause, delimiter and effect');
        }
        if (this.rows[1].length !== 1) {
            throw new Error('Card effect delimiter array must contain exactly 1 item');
        }
        if (!(this.rows[1][0] instanceof CardRenderSymbol_1.CardRenderSymbol)) {
            throw new Error('Effect delimiter must be a symbol');
        }
    };
    Object.defineProperty(CardRenderEffect.prototype, "cause", {
        get: function () {
            this._validate();
            return this.rows[0];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CardRenderEffect.prototype, "delimiter", {
        get: function () {
            var _a;
            this._validate();
            if (((_a = this.cause) === null || _a === void 0 ? void 0 : _a.length) === 0) {
                return undefined;
            }
            return this.rows[1][0];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CardRenderEffect.prototype, "effect", {
        get: function () {
            this._validate();
            return this.rows[2];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CardRenderEffect.prototype, "description", {
        get: function () {
            this._validate();
            return this.rows[2].slice(-1)[0];
        },
        set: function (content) {
            this.rows[2].push(content);
        },
        enumerable: false,
        configurable: true
    });
    return CardRenderEffect;
}(CardRenderer));
exports.CardRenderEffect = CardRenderEffect;
var CardRenderCorpBoxEffect = (function (_super) {
    __extends(CardRenderCorpBoxEffect, _super);
    function CardRenderCorpBoxEffect(rows) {
        return _super.call(this, rows) || this;
    }
    CardRenderCorpBoxEffect.builder = function (f) {
        var builder = new CorpEffectBuilderEffect();
        f(builder);
        return builder.build();
    };
    return CardRenderCorpBoxEffect;
}(CardRenderer));
exports.CardRenderCorpBoxEffect = CardRenderCorpBoxEffect;
var CardRenderCorpBoxAction = (function (_super) {
    __extends(CardRenderCorpBoxAction, _super);
    function CardRenderCorpBoxAction(rows) {
        return _super.call(this, rows) || this;
    }
    CardRenderCorpBoxAction.builder = function (f) {
        var builder = new CorpEffectBuilderAction();
        f(builder);
        return builder.build();
    };
    return CardRenderCorpBoxAction;
}(CardRenderer));
exports.CardRenderCorpBoxAction = CardRenderCorpBoxAction;
var Builder = (function () {
    function Builder() {
        this._data = [[]];
    }
    Builder.prototype.build = function () {
        return new CardRenderer(this._data);
    };
    Builder.prototype._getCurrentRow = function () {
        return this._data.pop();
    };
    Builder.prototype._addRowItem = function (item) {
        var currentRow = this._getCurrentRow();
        if (currentRow !== undefined) {
            currentRow.push(item);
            this._data.push(currentRow);
        }
    };
    Builder.prototype._checkExistingItem = function () {
        if (this._data.length === 0) {
            throw new Error('No items in builder data!');
        }
    };
    Builder.prototype._addSymbol = function (symbol) {
        var row = this._getCurrentRow();
        if (row !== undefined) {
            row.push(symbol);
            this._data.push(row);
        }
    };
    Builder.prototype._addTile = function (tile, hasSymbol, isAres) {
        var row = this._getCurrentRow();
        if (row !== undefined) {
            row.push(new CardRenderTile(tile, hasSymbol, isAres));
            this._data.push(row);
        }
    };
    Builder.prototype.temperature = function (amount) {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.TEMPERATURE, amount));
        return this;
    };
    Builder.prototype.oceans = function (amount, size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.OCEANS, amount);
        item.size = size;
        this._addRowItem(item);
        return this;
    };
    Builder.prototype.oxygen = function (amount) {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.OXYGEN, amount));
        return this;
    };
    Builder.prototype.venus = function (amount) {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.VENUS, amount));
        return this;
    };
    Builder.prototype.plants = function (amount) {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.PLANTS, amount));
        return this;
    };
    Builder.prototype.microbes = function (amount) {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MICROBES, amount));
        return this;
    };
    Builder.prototype.animals = function (amount) {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.ANIMALS, amount));
        return this;
    };
    Builder.prototype.heat = function (amount) {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.HEAT, amount));
        return this;
    };
    Builder.prototype.energy = function (amount) {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.ENERGY, amount));
        return this;
    };
    Builder.prototype.titanium = function (amount, bigAmountShowDigit) {
        if (bigAmountShowDigit === void 0) { bigAmountShowDigit = true; }
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.TITANIUM, amount);
        if (amount > 5 && bigAmountShowDigit === false) {
            item.showDigit = false;
        }
        this._addRowItem(item);
        return this;
    };
    Builder.prototype.steel = function (amount) {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.STEEL, amount));
        return this;
    };
    Builder.prototype.tr = function (amount, size, cancelled) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        if (cancelled === void 0) { cancelled = false; }
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.TR, amount);
        item.size = size;
        item.cancelled = cancelled;
        this._addRowItem(item);
        return this;
    };
    Builder.prototype.megacredits = function (amount, size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MEGACREDITS, amount);
        item.amountInside = true;
        item.showDigit = false;
        item.size = size;
        this._addRowItem(item);
        return this;
    };
    Builder.prototype.cards = function (amount) {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.CARDS, amount));
        return this;
    };
    Builder.prototype.floaters = function (amount) {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.FLOATERS, amount));
        return this;
    };
    Builder.prototype.asteroids = function (amount) {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.ASTEROIDS, amount));
        return this;
    };
    Builder.prototype.event = function () {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.EVENT));
        return this;
    };
    Builder.prototype.space = function () {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.SPACE));
        return this;
    };
    Builder.prototype.earth = function (amount) {
        if (amount === void 0) { amount = -1; }
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.EARTH, amount));
        return this;
    };
    Builder.prototype.building = function (amount) {
        if (amount === void 0) { amount = -1; }
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.BUILDING, amount));
        return this;
    };
    Builder.prototype.jovian = function () {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.JOVIAN));
        return this;
    };
    Builder.prototype.science = function (amount) {
        if (amount === void 0) { amount = 1; }
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.SCIENCE, amount));
        return this;
    };
    Builder.prototype.trade = function () {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.TRADE));
        return this;
    };
    Builder.prototype.tradeFleet = function () {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.TRADE_FLEET));
        return this;
    };
    Builder.prototype.colonies = function (amount, size) {
        if (amount === void 0) { amount = 1; }
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.COLONIES, amount);
        item.size = size;
        this._addRowItem(item);
        return this;
    };
    Builder.prototype.tradeDiscount = function (amount) {
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.TRADE_DISCOUNT, amount * -1);
        item.amountInside = true;
        this._addRowItem(item);
        return this;
    };
    Builder.prototype.placeColony = function () {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.PLACE_COLONY));
        return this;
    };
    Builder.prototype.influence = function (amount) {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.INFLUENCE, amount));
        return this;
    };
    Builder.prototype.city = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.CITY);
        item.size = size;
        this._addRowItem(item);
        return this;
    };
    Builder.prototype.greenery = function (size, withO2) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        if (withO2 === void 0) { withO2 = true; }
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.GREENERY);
        item.size = size;
        if (withO2) {
            item.secondaryTag = CardRenderItem_1.AltSecondaryTag.OXYGEN;
        }
        this._addRowItem(item);
        return this;
    };
    Builder.prototype.delegates = function (amount) {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.DELEGATES, amount));
        return this;
    };
    Builder.prototype.partyLeaders = function (amount) {
        if (amount === void 0) { amount = -1; }
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.PARTY_LEADERS, amount));
        return this;
    };
    Builder.prototype.chairman = function () {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.CHAIRMAN));
        return this;
    };
    Builder.prototype.noTags = function () {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.NO_TAGS, -1));
        return this;
    };
    Builder.prototype.wild = function (amount) {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.WILD, amount));
        return this;
    };
    Builder.prototype.preservation = function (amount) {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.PRESERVATION, amount));
        return this;
    };
    Builder.prototype.diverseTag = function (amount) {
        if (amount === void 0) { amount = 1; }
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.DIVERSE_TAG, amount);
        item.isPlayed = true;
        this._addRowItem(item);
        return this;
    };
    Builder.prototype.fighter = function (amount) {
        if (amount === void 0) { amount = 1; }
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.FIGHTER, amount));
        return this;
    };
    Builder.prototype.camps = function (amount) {
        if (amount === void 0) { amount = 1; }
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.CAMPS, amount));
        return this;
    };
    Builder.prototype.selfReplicatingRobots = function () {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.SELF_REPLICATING));
        return this;
    };
    Builder.prototype.prelude = function () {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.PRELUDE));
        return this;
    };
    Builder.prototype.award = function () {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.AWARD));
        return this;
    };
    Builder.prototype.vpIcon = function () {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.VP));
        return this;
    };
    Builder.prototype.community = function () {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.COMMUNITY));
        return this;
    };
    Builder.prototype.disease = function () {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.DISEASE));
        return this;
    };
    Builder.prototype.data = function (amount) {
        if (amount === void 0) { amount = 1; }
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.DATA_RESOURCE, amount));
        return this;
    };
    Builder.prototype.multiplierWhite = function () {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MULTIPLIER_WHITE));
        return this;
    };
    Builder.prototype.description = function (description) {
        if (description === void 0) { description = undefined; }
        this._checkExistingItem();
        this._addRowItem(description);
        return this;
    };
    Builder.prototype.moon = function (amount) {
        if (amount === void 0) { amount = -1; }
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MOON, amount));
        return this;
    };
    Builder.prototype.resourceCube = function (amount) {
        if (amount === void 0) { amount = 1; }
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.RESOURCE_CUBE, amount));
        return this;
    };
    Builder.prototype.moonColony = function (options) {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MOON_COLONY).withOptions(options));
        return this;
    };
    Builder.prototype.moonColonyRate = function (options) {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MOON_COLONY_RATE).withOptions(options));
        return this;
    };
    Builder.prototype.moonRoad = function (options) {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MOON_ROAD).withOptions(options));
        return this;
    };
    Builder.prototype.moonLogisticsRate = function (options) {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MOON_LOGISTICS_RATE).withOptions(options));
        return this;
    };
    Builder.prototype.moonMine = function (options) {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MOON_MINE).withOptions(options));
        return this;
    };
    Builder.prototype.moonMiningRate = function (options) {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.MOON_MINING_RATE).withOptions(options));
        return this;
    };
    Builder.prototype.syndicateFleet = function (amount) {
        if (amount === void 0) { amount = 1; }
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.SYNDICATE_FLEET, amount));
        return this;
    };
    Builder.prototype.emptyTile = function (type, size) {
        if (type === void 0) { type = 'normal'; }
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        if (type === 'normal') {
            var normal = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.EMPTY_TILE, -1);
            normal.size = size;
            this._addRowItem(normal);
        }
        else if (type === 'golden') {
            var golden = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.EMPTY_TILE_GOLDEN, -1);
            golden.size = size;
            this._addRowItem(golden);
        }
        return this;
    };
    Builder.prototype.production = function (pb) {
        this._addRowItem(CardRenderProductionBox.builder(pb));
        return this;
    };
    Builder.prototype.standardProject = function (description, eb) {
        var builder = CardRenderEffect.builder(eb);
        builder.description = description;
        this._addRowItem(builder);
        return this;
    };
    Builder.prototype.action = function (description, eb) {
        var builder = CardRenderEffect.builder(eb);
        builder.description = description !== undefined ? 'Action: ' + description : undefined;
        this._addRowItem(builder);
        return this;
    };
    Builder.prototype.effect = function (description, eb) {
        var builder = CardRenderEffect.builder(eb);
        builder.description = description !== undefined ? 'Effect: ' + description : undefined;
        this._addRowItem(builder);
        return this;
    };
    Builder.prototype.corpBox = function (type, eb) {
        this.br;
        if (type === 'action') {
            this._addRowItem(CardRenderCorpBoxAction.builder(eb));
        }
        else {
            this._addRowItem(CardRenderCorpBoxEffect.builder(eb));
        }
        return this;
    };
    Builder.prototype.or = function (size) {
        if (size === void 0) { size = Size_1.Size.SMALL; }
        this._checkExistingItem();
        this._addSymbol(CardRenderSymbol_1.CardRenderSymbol.or(size));
        return this;
    };
    Builder.prototype.asterix = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        this._checkExistingItem();
        this._addSymbol(CardRenderSymbol_1.CardRenderSymbol.asterix(size));
        return this;
    };
    Builder.prototype.plus = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        this._checkExistingItem();
        this._addSymbol(CardRenderSymbol_1.CardRenderSymbol.plus(size));
        return this;
    };
    Builder.prototype.minus = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        this._checkExistingItem();
        this._addSymbol(CardRenderSymbol_1.CardRenderSymbol.minus(size));
        return this;
    };
    Builder.prototype.slash = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        this._checkExistingItem();
        this._addSymbol(CardRenderSymbol_1.CardRenderSymbol.slash(size));
        return this;
    };
    Builder.prototype.colon = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        this._checkExistingItem();
        this._addSymbol(CardRenderSymbol_1.CardRenderSymbol.colon(size));
        return this;
    };
    Builder.prototype.arrow = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        this._checkExistingItem();
        this._addSymbol(CardRenderSymbol_1.CardRenderSymbol.arrow(size));
        return this;
    };
    Builder.prototype.equals = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        this._checkExistingItem();
        this._addSymbol(CardRenderSymbol_1.CardRenderSymbol.equals(size));
        return this;
    };
    Builder.prototype.empty = function () {
        this._checkExistingItem();
        this._addSymbol(CardRenderSymbol_1.CardRenderSymbol.empty());
        return this;
    };
    Builder.prototype.plate = function (text) {
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.PLATE);
        item.text = text;
        item.isPlate = true;
        item.isBold = true;
        this._addRowItem(item);
        return this;
    };
    Builder.prototype.text = function (text, size, uppercase, isBold) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        if (uppercase === void 0) { uppercase = false; }
        if (isBold === void 0) { isBold = true; }
        var item = new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.TEXT);
        item.text = text;
        item.size = size;
        item.isUppercase = uppercase;
        item.isBold = isBold;
        this._addRowItem(item);
        return this;
    };
    Builder.prototype.vpText = function (text) {
        return this.text(text, Size_1.Size.TINY, true);
    };
    Object.defineProperty(Builder.prototype, "br", {
        get: function () {
            var newRow = [];
            this._data.push(newRow);
            return this;
        },
        enumerable: false,
        configurable: true
    });
    Builder.prototype.tile = function (tile, hasSymbol, isAres) {
        if (hasSymbol === void 0) { hasSymbol = false; }
        if (isAres === void 0) { isAres = false; }
        this._addTile(tile, hasSymbol, isAres);
        return this;
    };
    Builder.prototype.projectRequirements = function () {
        this._addRowItem(new CardRenderItem_1.CardRenderItem(CardRenderItemType_1.CardRenderItemType.PROJECT_REQUIREMENTS));
        return this;
    };
    Object.defineProperty(Builder.prototype, "nbsp", {
        get: function () {
            this._checkExistingItem();
            this._addSymbol(CardRenderSymbol_1.CardRenderSymbol.nbsp());
            return this;
        },
        enumerable: false,
        configurable: true
    });
    Builder.prototype.vSpace = function (size) {
        if (size === void 0) { size = Size_1.Size.MEDIUM; }
        this._addSymbol(CardRenderSymbol_1.CardRenderSymbol.vSpace(size));
        return this;
    };
    Object.defineProperty(Builder.prototype, "any", {
        get: function () {
            this._checkExistingItem();
            var row = this._getCurrentRow();
            if (row !== undefined) {
                var item = row.pop();
                if (item === undefined) {
                    throw new Error('Called "any" without a CardRenderItem.');
                }
                if (!(item instanceof CardRenderItem_1.CardRenderItem)) {
                    throw new Error('"any" could be called on CardRenderItem only');
                }
                item.anyPlayer = true;
                row.push(item);
                this._data.push(row);
            }
            return this;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Builder.prototype, "played", {
        get: function () {
            this._checkExistingItem();
            var row = this._getCurrentRow();
            if (row !== undefined) {
                var item = row.pop();
                if (item === undefined) {
                    throw new Error('Called "played" without a CardRenderItem.');
                }
                if (!(item instanceof CardRenderItem_1.CardRenderItem)) {
                    throw new Error('"played" could be called on CardRenderItem only');
                }
                item.isPlayed = true;
                row.push(item);
                this._data.push(row);
            }
            return this;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Builder.prototype, "digit", {
        get: function () {
            this._checkExistingItem();
            var row = this._getCurrentRow();
            if (row !== undefined) {
                var item = row.pop();
                if (item === undefined) {
                    throw new Error('Called "digit" without a CardRenderItem.');
                }
                if (!(item instanceof CardRenderItem_1.CardRenderItem)) {
                    throw new Error('"digit" could be called on CardRenderItem only');
                }
                item.showDigit = true;
                row.push(item);
                this._data.push(row);
            }
            return this;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Builder.prototype, "multiplier", {
        get: function () {
            this._checkExistingItem();
            var row = this._getCurrentRow();
            if (row !== undefined) {
                var item = row.pop();
                if (item === undefined) {
                    throw new Error('Called "multiplier" without a CardRenderItem.');
                }
                if (!(item instanceof CardRenderItem_1.CardRenderItem)) {
                    throw new Error('"multiplier" could be called on CardRenderItem only');
                }
                item.amountInside = true;
                item.multiplier = true;
                row.push(item);
                this._data.push(row);
            }
            return this;
        },
        enumerable: false,
        configurable: true
    });
    Builder.prototype.secondaryTag = function (tag) {
        this._checkExistingItem();
        var row = this._getCurrentRow();
        if (row !== undefined) {
            var item = row.pop();
            if (item === undefined) {
                throw new Error('Called "secondaryTag" without a CardRenderItem.');
            }
            if (!(item instanceof CardRenderItem_1.CardRenderItem)) {
                throw new Error('"secondaryTag" could be called on CardRenderItem only');
            }
            item.secondaryTag = tag;
            row.push(item);
            this._data.push(row);
        }
        return this;
    };
    Object.defineProperty(Builder.prototype, "brackets", {
        get: function () {
            this._checkExistingItem();
            var row = this._getCurrentRow();
            if (row !== undefined) {
                var item = row.pop();
                if (!(item instanceof CardRenderItem_1.CardRenderItem)) {
                    throw new Error('"brackets" could be called on CardRenderItem only');
                }
                if (item === undefined) {
                    throw new Error('Called "brackets" without a CardRenderItem.');
                }
                row.push(CardRenderSymbol_1.CardRenderSymbol.bracketOpen());
                row.push(item);
                row.push(CardRenderSymbol_1.CardRenderSymbol.bracketClose());
                this._data.push(row);
            }
            return this;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Builder.prototype, "startEffect", {
        get: function () {
            this.br;
            this._addSymbol(CardRenderSymbol_1.CardRenderSymbol.colon());
            this.br;
            return this;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Builder.prototype, "startAction", {
        get: function () {
            this.br;
            this._addSymbol(CardRenderSymbol_1.CardRenderSymbol.arrow());
            this.br;
            return this;
        },
        enumerable: false,
        configurable: true
    });
    return Builder;
}());
var ProductionBoxBuilder = (function (_super) {
    __extends(ProductionBoxBuilder, _super);
    function ProductionBoxBuilder() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._data = [[]];
        return _this;
    }
    ProductionBoxBuilder.prototype.build = function () {
        return new CardRenderProductionBox(this._data);
    };
    return ProductionBoxBuilder;
}(Builder));
var EffectBuilder = (function (_super) {
    __extends(EffectBuilder, _super);
    function EffectBuilder() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._data = [[]];
        return _this;
    }
    EffectBuilder.prototype.build = function () {
        return new CardRenderEffect(this._data);
    };
    return EffectBuilder;
}(Builder));
var CorpEffectBuilderEffect = (function (_super) {
    __extends(CorpEffectBuilderEffect, _super);
    function CorpEffectBuilderEffect() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._data = [[]];
        return _this;
    }
    CorpEffectBuilderEffect.prototype.build = function () {
        return new CardRenderCorpBoxEffect(this._data);
    };
    return CorpEffectBuilderEffect;
}(Builder));
var CorpEffectBuilderAction = (function (_super) {
    __extends(CorpEffectBuilderAction, _super);
    function CorpEffectBuilderAction() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._data = [[]];
        return _this;
    }
    CorpEffectBuilderAction.prototype.build = function () {
        return new CardRenderCorpBoxAction(this._data);
    };
    return CorpEffectBuilderAction;
}(Builder));
