"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Playwrights = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var SelectCard_1 = require("../../inputs/SelectCard");
var Resources_1 = require("../../Resources");
var SelectHowToPayDeferred_1 = require("../../deferredActions/SelectHowToPayDeferred");
var DeferredAction_1 = require("../../deferredActions/DeferredAction");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var Playwrights = (function (_super) {
    __extends(Playwrights, _super);
    function Playwrights() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.PLAYWRIGHTS,
            tags: [Tags_1.Tags.ENERGY],
            startingMegaCredits: 38,
            cardType: CardType_1.CardType.CORPORATION,
            metadata: {
                cardNumber: 'R40',
                description: 'You start with 38 M€ and 1 Energy production.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br.br;
                    b.megacredits(38).production(function (pb) { return pb.energy(1); });
                    b.corpBox('action', function (cb) {
                        cb.action('Replay a played event from any player by paying its cost ONLY in M€ (discounts and rebates apply), then REMOVE IT FROM PLAY.', function (eb) {
                            eb.megacredits(1000).startAction;
                            eb.text('replay', Size_1.Size.SMALL, true);
                            eb.nbsp.cards(1).any.secondaryTag(Tags_1.Tags.EVENT);
                        });
                    });
                }),
            },
        }) || this;
        _this.checkLoops = 0;
        return _this;
    }
    Playwrights.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.ENERGY, 1);
        return undefined;
    };
    Playwrights.prototype.canAct = function (player) {
        var replayableEvents = this.getReplayableEvents(player);
        return replayableEvents.length > 0;
    };
    Playwrights.prototype.action = function (player) {
        var players = player.game.getPlayers();
        var replayableEvents = this.getReplayableEvents(player);
        return new SelectCard_1.SelectCard('Select event card to replay at cost in M€ and remove from play', 'Select', replayableEvents, function (foundCards) {
            var selectedCard = foundCards[0];
            players.forEach(function (p) {
                var cardIndex = p.playedCards.findIndex(function (c) { return c.name === selectedCard.name; });
                if (cardIndex !== -1) {
                    p.playedCards.splice(cardIndex, 1);
                }
            });
            var cost = player.getCardCost(selectedCard);
            player.game.defer(new SelectHowToPayDeferred_1.SelectHowToPayDeferred(player, cost, {
                title: 'Select how to pay to replay the event',
                afterPay: function () {
                    player.playCard(selectedCard, undefined, false);
                    player.removedFromPlayCards.push(selectedCard);
                    if (selectedCard.name === CardName_1.CardName.LAW_SUIT) {
                        player.game.defer(new DeferredAction_1.DeferredAction(player, function () {
                            player.game.getPlayers().some(function (p) {
                                var card = p.playedCards[p.playedCards.length - 1];
                                if ((card === null || card === void 0 ? void 0 : card.name) === selectedCard.name) {
                                    p.playedCards.pop();
                                    return true;
                                }
                                return false;
                            });
                            return undefined;
                        }));
                    }
                },
            }));
            return undefined;
        });
    };
    Playwrights.prototype.getCheckLoops = function () {
        return this.checkLoops;
    };
    Playwrights.prototype.getReplayableEvents = function (player) {
        var playedEvents = [];
        this.checkLoops++;
        player.game.getPlayers().forEach(function (p) {
            playedEvents.push.apply(playedEvents, p.playedCards.filter(function (card) {
                return card.cardType === CardType_1.CardType.EVENT &&
                    player.canAfford(player.getCardCost(card), {
                        reserveUnits: MoonExpansion_1.MoonExpansion.adjustedReserveCosts(player, card),
                    }) &&
                    (card.canPlay === undefined || card.canPlay(player));
            }));
        });
        this.checkLoops--;
        return playedEvents;
    };
    return Playwrights;
}(Card_1.Card));
exports.Playwrights = Playwrights;
