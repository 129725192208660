"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pandemic = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var Tags_1 = require("../../cards/Tags");
var Pandemic = (function () {
    function Pandemic() {
        this.name = GlobalEventName_1.GlobalEventName.PANDEMIC;
        this.description = 'Lose 3 M€ for each Building tag (max 5, then reduced by influence).';
        this.revealedDelegate = PartyName_1.PartyName.GREENS;
        this.currentDelegate = PartyName_1.PartyName.MARS;
    }
    Pandemic.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            var maxedSteelTags = Math.min(5, player.getTagCount(Tags_1.Tags.BUILDING, false, false));
            player.deductResource(Resources_1.Resources.MEGACREDITS, 3 * Math.max(0, maxedSteelTags - turmoil.getPlayerInfluence(player)), { log: true, from: _this.name });
        });
    };
    return Pandemic;
}());
exports.Pandemic = Pandemic;
