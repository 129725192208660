"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.$t = exports.translateTextNode = exports.translateTextWithParams = exports.translateText = exports.translateMessage = void 0;
var LogMessageDataType_1 = require("@/LogMessageDataType");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
var Log_1 = require("@/Log");
function translateMessage(message) {
    message.message = translateText(message.message);
    return Log_1.Log.applyData(message, function (datum) {
        if (datum !== undefined && datum.type === LogMessageDataType_1.LogMessageDataType.RAW_STRING) {
            return datum.value;
        }
        return '';
    });
}
exports.translateMessage = translateMessage;
function translateText(englishText) {
    var lang = PreferencesManager_1.PreferencesManager.load('lang') || 'en';
    if (lang === 'en' || window._translations === undefined) {
        return englishText;
    }
    englishText = normalizeText(englishText);
    if (/^(\W|\d)*$/.test(englishText)) {
        return englishText;
    }
    var translatedText = window._translations[englishText];
    if (translatedText === undefined) {
        var isTextInBrackets = englishText.startsWith('(') && englishText.endsWith(')');
        if (isTextInBrackets) {
            var translationAttempt = window._translations[englishText.slice(1, -1)];
            if (translationAttempt) {
                translatedText = "(" + translationAttempt + ")";
            }
        }
    }
    if (translatedText === undefined) {
        console.log(lang + " - please translate: " + englishText);
    }
    return translatedText || englishText;
}
exports.translateText = translateText;
function translateTextWithParams(englishText, params) {
    var data = params.map(function (p) {
        return {
            type: LogMessageDataType_1.LogMessageDataType.RAW_STRING,
            value: p,
        };
    });
    var message = {
        message: englishText,
        data: data,
    };
    return translateMessage(message);
}
exports.translateTextWithParams = translateTextWithParams;
function normalizeText(text) {
    return text.replace(/[\n\r]/g, '').replace(/[ ]+/g, ' ').trim();
}
function translateChildren(node) {
    for (var i = 0, length = node.childNodes.length; i < length; i++) {
        var child = node.childNodes[i];
        if (child.nodeType === Node.TEXT_NODE) {
            var text = child;
            var translatedText = translateText(text.data);
            if (translatedText !== text.data) {
                text.data = translatedText;
            }
        }
        else {
            translateChildren(child);
        }
    }
}
function translateTextNode(el) {
    translateChildren(el);
}
exports.translateTextNode = translateTextNode;
var $t = function (msg) {
    if (!msg)
        return '';
    if (typeof msg === 'number')
        return msg.toString();
    if (typeof msg === 'string')
        return translateText(msg);
    return translateMessage(msg);
};
exports.$t = $t;
