"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DustSeals = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var DustSeals = (function (_super) {
    __extends(DustSeals, _super);
    function DustSeals() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.DUST_SEALS,
            cost: 2,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.oceans(3).max(); }),
            metadata: {
                description: 'Requires 3 or less ocean tiles.',
                cardNumber: '119',
                victoryPoints: 1,
            },
        }) || this;
    }
    DustSeals.prototype.play = function () {
        return undefined;
    };
    DustSeals.prototype.getVictoryPoints = function () {
        return 1;
    };
    return DustSeals;
}(Card_1.Card));
exports.DustSeals = DustSeals;
