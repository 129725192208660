"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloudSocieties = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var ResourceType_1 = require("../../ResourceType");
var AddResourcesToCard_1 = require("../../deferredActions/AddResourcesToCard");
var CloudSocieties = (function () {
    function CloudSocieties() {
        this.name = GlobalEventName_1.GlobalEventName.CLOUD_SOCIETIES;
        this.description = 'Add a floater to each card that can collect floaters. Add 1 floater for each influence to a card.';
        this.revealedDelegate = PartyName_1.PartyName.UNITY;
        this.currentDelegate = PartyName_1.PartyName.REDS;
    }
    CloudSocieties.prototype.resolve = function (game, turmoil) {
        game.getPlayers().forEach(function (player) {
            var resourceCards = player.getResourceCards(ResourceType_1.ResourceType.FLOATER);
            resourceCards.forEach(function (card) {
                player.addResourceTo(card, 1);
            });
            var amount = turmoil.getPlayerInfluence(player);
            if (amount > 0) {
                game.defer(new AddResourcesToCard_1.AddResourcesToCard(player, ResourceType_1.ResourceType.FLOATER, { count: amount }));
            }
        });
    };
    return CloudSocieties;
}());
exports.CloudSocieties = CloudSocieties;
