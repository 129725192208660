"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EcoLine = void 0;
var Card_1 = require("../Card");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var EcoLine = (function (_super) {
    __extends(EcoLine, _super);
    function EcoLine() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.ECOLINE,
            tags: [Tags_1.Tags.PLANT],
            startingMegaCredits: 36,
            metadata: {
                cardNumber: 'R17',
                description: 'You start with 2 plant production, 3 plants, and 36 M€.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br;
                    b.production(function (pb) { return pb.plants(2); }).nbsp.megacredits(36).plants(3).digit;
                    b.corpBox('effect', function (ce) {
                        ce.effect('You may always pay 7 plants, instead of 8, to place greenery.', function (eb) {
                            eb.plants(7).digit.startAction.greenery();
                        });
                    });
                }),
            },
        }) || this;
    }
    EcoLine.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.PLANTS, 2);
        player.plants = 3;
        player.plantsNeededForGreenery = 7;
        return undefined;
    };
    return EcoLine;
}(Card_1.Card));
exports.EcoLine = EcoLine;
