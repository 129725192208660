"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScientificCommunity = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var ScientificCommunity = (function () {
    function ScientificCommunity() {
        this.name = GlobalEventName_1.GlobalEventName.SCIENTIFIC_COMMUNITY;
        this.description = 'Gain 1 M€ for each card in hand (no limit) and influence.';
        this.revealedDelegate = PartyName_1.PartyName.REDS;
        this.currentDelegate = PartyName_1.PartyName.SCIENTISTS;
    }
    ScientificCommunity.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            var amount = player.cardsInHand.length + turmoil.getPlayerInfluence(player);
            player.addResource(Resources_1.Resources.MEGACREDITS, amount, { log: true, from: _this.name });
        });
    };
    return ScientificCommunity;
}());
exports.ScientificCommunity = ScientificCommunity;
