"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PoliticalUprising = void 0;
var PreludeCard_1 = require("../prelude/PreludeCard");
var CardName_1 = require("../../CardName");
var TurmoilCardManifest_1 = require("../turmoil/TurmoilCardManifest");
var SendDelegateToArea_1 = require("../../deferredActions/SendDelegateToArea");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRenderItem_1 = require("../render/CardRenderItem");
var PoliticalUprising = (function (_super) {
    __extends(PoliticalUprising, _super);
    function PoliticalUprising() {
        return _super.call(this, {
            name: CardName_1.CardName.POLITICAL_UPRISING,
            metadata: {
                cardNumber: 'Y03',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.delegates(4).br.br;
                    b.cards(1).secondaryTag(CardRenderItem_1.AltSecondaryTag.TURMOIL);
                }),
                description: 'Place 4 delegates. Draw a Turmoil card.',
            },
        }) || this;
    }
    PoliticalUprising.prototype.play = function (player) {
        this.drawTurmoilCard(player);
        for (var i = 0; i < 4; i++) {
            player.game.defer(new SendDelegateToArea_1.SendDelegateToArea(player, 'Select where to send delegate', { source: 'reserve' }));
        }
        return undefined;
    };
    PoliticalUprising.prototype.drawTurmoilCard = function (player) {
        var turmoilCards = [];
        TurmoilCardManifest_1.TURMOIL_CARD_MANIFEST.projectCards.factories.forEach(function (cf) { return turmoilCards.push(cf.cardName); });
        var drawnCard = player.game.dealer.deck.find(function (card) { return turmoilCards.includes(card.name); });
        if (drawnCard) {
            var cardIndex = player.game.dealer.deck.findIndex(function (c) { return c.name === drawnCard.name; });
            player.game.dealer.deck.splice(cardIndex, 1);
            player.cardsInHand.push(drawnCard);
            player.game.log('${0} drew ${1}', function (b) { return b.player(player).card(drawnCard); });
        }
        return undefined;
    };
    return PoliticalUprising;
}(PreludeCard_1.PreludeCard));
exports.PoliticalUprising = PoliticalUprising;
