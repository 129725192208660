"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SolarnetShutdown = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var CardType_1 = require("../../cards/CardType");
var SolarnetShutdown = (function () {
    function SolarnetShutdown() {
        this.name = GlobalEventName_1.GlobalEventName.SOLARNET_SHUTDOWN;
        this.description = 'Lose 3 M€ for each blue card (max 5, then reduced by influence).';
        this.revealedDelegate = PartyName_1.PartyName.SCIENTISTS;
        this.currentDelegate = PartyName_1.PartyName.MARS;
    }
    SolarnetShutdown.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            var amount = Math.min(5, player.playedCards.filter(function (card) { return card.cardType === CardType_1.CardType.ACTIVE; }).length) - turmoil.getPlayerInfluence(player);
            if (amount > 0) {
                player.addResource(Resources_1.Resources.MEGACREDITS, amount * -3, { log: true, from: _this.name });
            }
        });
    };
    return SolarnetShutdown;
}());
exports.SolarnetShutdown = SolarnetShutdown;
