"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.HypersensitiveSiliconChipFactory = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var MoonCard_1 = require("./MoonCard");
var CardRequirements_1 = require("../CardRequirements");
var HypersensitiveSiliconChipFactory = (function (_super) {
    __extends(HypersensitiveSiliconChipFactory, _super);
    function HypersensitiveSiliconChipFactory() {
        return _super.call(this, {
            name: CardName_1.CardName.HYPERSENSITIVE_SILICON_CHIP_FACTORY,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.BUILDING],
            cost: 11,
            productionBox: Units_1.Units.of({ megacredits: 4 }),
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.miningTiles(2).any(); }),
            reserveUnits: Units_1.Units.of({ titanium: 2 }),
            metadata: {
                description: 'Requires 2 mining tiles on the Moon. Spend 2 titanium. Increase your M€ production 4 steps.',
                cardNumber: 'M43',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.minus().titanium(2).nbsp;
                    b.production(function (pb) { return pb.megacredits(4); }).br;
                }),
            },
        }) || this;
    }
    ;
    return HypersensitiveSiliconChipFactory;
}(MoonCard_1.MoonCard));
exports.HypersensitiveSiliconChipFactory = HypersensitiveSiliconChipFactory;
