"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Plantation = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var CardName_1 = require("../../CardName");
var constants_1 = require("../../constants");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Plantation = (function (_super) {
    __extends(Plantation, _super);
    function Plantation() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.PLANTATION,
            tags: [Tags_1.Tags.PLANT],
            cost: 15,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.tag(Tags_1.Tags.SCIENCE, 2); }),
            metadata: {
                cardNumber: '193',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.greenery();
                }),
                description: 'Requires 2 Science tags. Place a greenery tile and raise oxygen 1 step.',
            },
        }) || this;
    }
    Plantation.prototype.canPlay = function (player) {
        if (!_super.prototype.canPlay.call(this, player)) {
            return false;
        }
        if (player.game.board.getAvailableSpacesOnLand(player).length === 0) {
            return false;
        }
        var oxygenMaxed = player.game.getOxygenLevel() === constants_1.MAX_OXYGEN_LEVEL;
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS) && !oxygenMaxed) {
            return player.canAfford(player.getCardCost(this) + constants_1.REDS_RULING_POLICY_COST, { microbes: true });
        }
        return true;
    };
    Plantation.prototype.play = function (player) {
        return new SelectSpace_1.SelectSpace('Select space for greenery tile', player.game.board.getAvailableSpacesForGreenery(player), function (space) {
            return player.game.addGreenery(player, space.id);
        });
    };
    return Plantation;
}(Card_1.Card));
exports.Plantation = Plantation;
