"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LunarDustProcessingPlant = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var CardRenderer_1 = require("../render/CardRenderer");
var TileType_1 = require("../../TileType");
var Units_1 = require("../../Units");
var MoonCard_1 = require("./MoonCard");
var LunarDustProcessingPlant = (function (_super) {
    __extends(LunarDustProcessingPlant, _super);
    function LunarDustProcessingPlant() {
        return _super.call(this, {
            name: CardName_1.CardName.LUNAR_DUST_PROCESSING_PLANT,
            cardType: CardType_1.CardType.ACTIVE,
            tags: [Tags_1.Tags.BUILDING],
            cost: 6,
            reserveUnits: Units_1.Units.of({ titanium: 1 }),
            metadata: {
                description: 'Spend 1 titanium. Raise the Logistic Rate 1 step.',
                cardNumber: 'M17',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('When you place a road tile on the Moon, you spend no steel on it.', function (eb) {
                        eb.startEffect.tile(TileType_1.TileType.MOON_ROAD, false).colon().text('0').steel(1);
                    }).br;
                    b.minus().titanium(1).moonLogisticsRate();
                }),
            },
        }) || this;
    }
    ;
    LunarDustProcessingPlant.prototype.play = function (player) {
        _super.prototype.play.call(this, player);
        MoonExpansion_1.MoonExpansion.raiseLogisticRate(player);
        return undefined;
    };
    return LunarDustProcessingPlant;
}(MoonCard_1.MoonCard));
exports.LunarDustProcessingPlant = LunarDustProcessingPlant;
