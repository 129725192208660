"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StealResources = void 0;
var Resources_1 = require("../Resources");
var OrOptions_1 = require("../inputs/OrOptions");
var SelectOption_1 = require("../inputs/SelectOption");
var DeferredAction_1 = require("./DeferredAction");
var StealResources = (function () {
    function StealResources(player, resource, count, title) {
        if (count === void 0) { count = 1; }
        if (title === void 0) { title = 'Select player to steal up to ' + count + ' ' + resource + ' from'; }
        this.player = player;
        this.resource = resource;
        this.count = count;
        this.title = title;
        this.priority = DeferredAction_1.Priority.ATTACK_OPPONENT;
        this.stealComplete = function () { };
    }
    StealResources.prototype.execute = function () {
        var _this = this;
        if (this.player.game.isSoloMode()) {
            this.player.addResource(this.resource, this.count);
            this.stealComplete();
            return undefined;
        }
        var candidates = this.player.game.getPlayers().filter(function (p) { return p.id !== _this.player.id && p.getResource(_this.resource) > 0; });
        if (this.resource === Resources_1.Resources.PLANTS) {
            candidates = candidates.filter(function (p) { return !p.plantsAreProtected(); });
        }
        if (this.resource === Resources_1.Resources.STEEL || this.resource === Resources_1.Resources.TITANIUM) {
            candidates = candidates.filter(function (p) { return !p.alloysAreProtected(); });
        }
        if (candidates.length === 0) {
            return undefined;
        }
        var stealOptions = candidates.map(function (candidate) {
            var qtyToSteal = Math.min(candidate.getResource(_this.resource), _this.count);
            return new SelectOption_1.SelectOption('Steal ' + qtyToSteal + ' ' + _this.resource + ' from ' + candidate.name, 'Steal', function () {
                candidate.deductResource(_this.resource, qtyToSteal, { log: true, from: _this.player });
                _this.player.addResource(_this.resource, qtyToSteal);
                _this.stealComplete();
                return undefined;
            });
        });
        return new (OrOptions_1.OrOptions.bind.apply(OrOptions_1.OrOptions, __spreadArray(__spreadArray([void 0], stealOptions), [new SelectOption_1.SelectOption('Do not steal', 'Confirm', function () {
                return undefined;
            })])))();
    };
    return StealResources;
}());
exports.StealResources = StealResources;
