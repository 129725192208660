"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MediaGroup = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var MediaGroup = (function (_super) {
    __extends(MediaGroup, _super);
    function MediaGroup() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.MEDIA_GROUP,
            tags: [Tags_1.Tags.EARTH],
            cost: 6,
            metadata: {
                cardNumber: '109',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('After you play an event card, you gain 3 M€.', function (eb) {
                        eb.event().played.startEffect.megacredits(3);
                    });
                }),
            },
        }) || this;
    }
    MediaGroup.prototype.onCardPlayed = function (player, card) {
        if (card.cardType === CardType_1.CardType.EVENT) {
            player.megaCredits += 3;
        }
    };
    MediaGroup.prototype.play = function () {
        return undefined;
    };
    return MediaGroup;
}(Card_1.Card));
exports.MediaGroup = MediaGroup;
