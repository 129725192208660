"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlackPolarDust = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var constants_1 = require("../../constants");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var PlaceOceanTile_1 = require("../../deferredActions/PlaceOceanTile");
var CardRenderer_1 = require("../render/CardRenderer");
var BlackPolarDust = (function (_super) {
    __extends(BlackPolarDust, _super);
    function BlackPolarDust() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.BLACK_POLAR_DUST,
            cost: 15,
            metadata: {
                cardNumber: '022',
                description: 'Place an ocean tile. Decrease your M€ production 2 steps and increase your heat production 3 steps.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.minus().megacredits(2).br;
                        pb.plus().heat(3);
                    }).oceans(1);
                }),
            },
        }) || this;
    }
    BlackPolarDust.prototype.canPlay = function (player) {
        var meetsMcProdRequirement = player.getProduction(Resources_1.Resources.MEGACREDITS) >= -3;
        var oceansMaxed = player.game.board.getOceansOnBoard() === constants_1.MAX_OCEAN_TILES;
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS) && !oceansMaxed) {
            return player.canAfford(player.getCardCost(this) + constants_1.REDS_RULING_POLICY_COST) && meetsMcProdRequirement;
        }
        return meetsMcProdRequirement;
    };
    BlackPolarDust.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, -2);
        player.addProduction(Resources_1.Resources.HEAT, 3);
        player.game.defer(new PlaceOceanTile_1.PlaceOceanTile(player));
        return undefined;
    };
    return BlackPolarDust;
}(Card_1.Card));
exports.BlackPolarDust = BlackPolarDust;
