"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
exports.default = vue_1.default.extend({
    name: 'global-event',
    props: {
        globalEvent: {
            type: Object,
        },
        type: {
            type: String,
        },
    },
    methods: {
        partyNameClass: function (partyName) {
            if (partyName === undefined) {
                return '';
            }
            return 'event-party--' + partyName.toLowerCase().split(' ').join('_');
        },
        getClass: function () {
            var common = 'global-event';
            switch (this.type) {
                case 'coming':
                    return common + ' global-event--coming';
                case 'current':
                    return common + ' global-event--current';
                default:
                    return common;
            }
        },
    },
});
