"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Flooding = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var SelectPlayer_1 = require("../../inputs/SelectPlayer");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var CardName_1 = require("../../CardName");
var Resources_1 = require("../../Resources");
var constants_1 = require("../../constants");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var PlaceOceanTile_1 = require("../../deferredActions/PlaceOceanTile");
var CardRenderer_1 = require("../render/CardRenderer");
var Flooding = (function (_super) {
    __extends(Flooding, _super);
    function Flooding() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.FLOODING,
            cost: 7,
            metadata: {
                cardNumber: '188',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.oceans(1).nbsp.minus().megacredits(4).any.asterix();
                }),
                description: 'Place an ocean tile. IF THERE ARE TILES ADJACENT TO THIS OCEAN TILE, YOU MAY REMOVE 4 M€ FROM THE OWNER OF ONE OF THOSE TILES.',
                victoryPoints: -1,
            },
        }) || this;
    }
    Flooding.prototype.canPlay = function (player) {
        var oceansMaxed = player.game.board.getOceansOnBoard() === constants_1.MAX_OCEAN_TILES;
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS) && !oceansMaxed) {
            return player.canAfford(player.getCardCost(this) + constants_1.REDS_RULING_POLICY_COST);
        }
        return true;
    };
    Flooding.prototype.play = function (player) {
        if (player.game.isSoloMode()) {
            player.game.defer(new PlaceOceanTile_1.PlaceOceanTile(player));
            return undefined;
        }
        var oceansMaxedBeforePlacement = player.game.board.getOceansOnBoard() === constants_1.MAX_OCEAN_TILES;
        if (oceansMaxedBeforePlacement === true)
            return undefined;
        return new SelectSpace_1.SelectSpace('Select space for ocean tile', player.game.board.getAvailableSpacesForOcean(player), function (space) {
            var adjacentPlayers = new Set();
            player.game.addOceanTile(player, space.id);
            player.game.board.getAdjacentSpaces(space).forEach(function (space) {
                if (space.player && space.player !== player && space.tile) {
                    adjacentPlayers.add(space.player);
                }
            });
            if (adjacentPlayers.size > 0) {
                return new OrOptions_1.OrOptions(new SelectPlayer_1.SelectPlayer(Array.from(adjacentPlayers), 'Select adjacent player to remove 4 M€ from', 'Remove credits', function (selectedPlayer) {
                    selectedPlayer.deductResource(Resources_1.Resources.MEGACREDITS, 4, { log: true, from: player });
                    return undefined;
                }), new SelectOption_1.SelectOption('Don\'t remove M€ from adjacent player', 'Confirm', function () {
                    return undefined;
                }));
            }
            return undefined;
        });
    };
    Flooding.prototype.getVictoryPoints = function () {
        return -1;
    };
    return Flooding;
}(Card_1.Card));
exports.Flooding = Flooding;
