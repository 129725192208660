"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Decomposers = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var ResourceType_1 = require("../../ResourceType");
var CardName_1 = require("../../CardName");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var Phase_1 = require("../../Phase");
var Decomposers = (function (_super) {
    __extends(Decomposers, _super);
    function Decomposers() {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.DECOMPOSERS,
            tags: [Tags_1.Tags.MICROBE],
            cost: 5,
            resourceType: ResourceType_1.ResourceType.MICROBE,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.oxygen(3); }),
            metadata: {
                cardNumber: '131',
                description: 'Requires 3% oxygen.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.effect('When you play an Animal, Plant, or Microbe tag, including this, add a Microbe to this card.', function (be) {
                        be.animals(1).played.slash();
                        be.plants(1).played.slash();
                        be.microbes(1).played;
                        be.startEffect.microbes(1);
                    }).br;
                    b.vpText('1 VP per 3 Microbes on this card.');
                }),
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.microbes(1, 3),
            },
        }) || this;
        _this.resourceCount = 0;
        return _this;
    }
    Decomposers.prototype.onCardPlayed = function (player, card) {
        player.addResourceTo(this, card.tags.filter(function (tag) { return tag === Tags_1.Tags.ANIMAL || tag === Tags_1.Tags.PLANT || tag === Tags_1.Tags.MICROBE; }).length);
    };
    Decomposers.prototype.getVictoryPoints = function () {
        return Math.floor(this.resourceCount / 3);
    };
    Decomposers.prototype.play = function (player) {
        if (player.game.phase === Phase_1.Phase.PRELUDES && player.playedCards.length > 0 && player.playedCards[player.playedCards.length - 1].name === CardName_1.CardName.ECOLOGY_EXPERTS) {
            player.addResourceTo(this, 2);
        }
        return undefined;
    };
    return Decomposers;
}(Card_1.Card));
exports.Decomposers = Decomposers;
