"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SaturnSystems = void 0;
var Card_1 = require("../Card");
var Tags_1 = require("../Tags");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var CardRenderer_1 = require("../render/CardRenderer");
var SaturnSystems = (function (_super) {
    __extends(SaturnSystems, _super);
    function SaturnSystems() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.SATURN_SYSTEMS,
            tags: [Tags_1.Tags.JOVIAN],
            startingMegaCredits: 42,
            metadata: {
                cardNumber: 'R03',
                description: 'You start with 1 titanium production and 42 M€.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.br;
                    b.production(function (pb) { return pb.titanium(1); }).nbsp.megacredits(42);
                    b.corpBox('effect', function (ce) {
                        ce.effect('Each time any Jovian tag is put into play, including this, increase your M€ production 1 step.', function (eb) {
                            eb.jovian().played.any.startEffect.production(function (pb) { return pb.megacredits(1); });
                        });
                    });
                }),
            },
        }) || this;
    }
    SaturnSystems.prototype.onCardPlayed = function (player, card) {
        this._onCardPlayed(player, card);
    };
    SaturnSystems.prototype.onCorpCardPlayed = function (player, card) {
        return this._onCardPlayed(player, card);
    };
    SaturnSystems.prototype._onCardPlayed = function (player, card) {
        for (var _i = 0, _a = card.tags; _i < _a.length; _i++) {
            var tag = _a[_i];
            if (tag === Tags_1.Tags.JOVIAN) {
                player.game.getCardPlayer(this.name).addProduction(Resources_1.Resources.MEGACREDITS, 1, { log: true });
            }
        }
    };
    SaturnSystems.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.TITANIUM, 1);
        player.addProduction(Resources_1.Resources.MEGACREDITS, 1);
        return undefined;
    };
    return SaturnSystems;
}(Card_1.Card));
exports.SaturnSystems = SaturnSystems;
