"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConvoyFromEuropa = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var constants_1 = require("../../constants");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var PlaceOceanTile_1 = require("../../deferredActions/PlaceOceanTile");
var CardRenderer_1 = require("../render/CardRenderer");
var ConvoyFromEuropa = (function (_super) {
    __extends(ConvoyFromEuropa, _super);
    function ConvoyFromEuropa() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.CONVOY_FROM_EUROPA,
            tags: [Tags_1.Tags.SPACE],
            cost: 15,
            metadata: {
                cardNumber: '161',
                description: 'Place 1 ocean tile and draw 1 card.',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) { return b.oceans(1).cards(1); }),
            },
        }) || this;
    }
    ConvoyFromEuropa.prototype.canPlay = function (player) {
        var oceansMaxed = player.game.board.getOceansOnBoard() === constants_1.MAX_OCEAN_TILES;
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS) && !oceansMaxed) {
            return player.canAfford(player.getCardCost(this) + constants_1.REDS_RULING_POLICY_COST, { titanium: true });
        }
        return true;
    };
    ConvoyFromEuropa.prototype.play = function (player) {
        player.drawCard();
        player.game.defer(new PlaceOceanTile_1.PlaceOceanTile(player));
        return undefined;
    };
    return ConvoyFromEuropa;
}(Card_1.Card));
exports.ConvoyFromEuropa = ConvoyFromEuropa;
