"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.OptimalAerobraking = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var OptimalAerobraking = (function (_super) {
    __extends(OptimalAerobraking, _super);
    function OptimalAerobraking() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.OPTIMAL_AEROBRAKING,
            tags: [Tags_1.Tags.SPACE],
            cost: 7,
            metadata: {
                cardNumber: '031',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) { return b.effect('When you play a Space Event, you gain 3 M€ and 3 heat.', function (be) {
                    be.space().played.event().played.startEffect.megacredits(3).heat(3);
                }); }),
            },
        }) || this;
    }
    OptimalAerobraking.prototype.onCardPlayed = function (player, card) {
        if (card.cardType === CardType_1.CardType.EVENT && card.tags.includes(Tags_1.Tags.SPACE)) {
            player.megaCredits += 3;
            player.heat += 3;
        }
    };
    OptimalAerobraking.prototype.play = function () {
        return undefined;
    };
    return OptimalAerobraking;
}(Card_1.Card));
exports.OptimalAerobraking = OptimalAerobraking;
