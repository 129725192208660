"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Capital = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var TileType_1 = require("../../TileType");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var SpaceType_1 = require("../../SpaceType");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var Board_1 = require("../../boards/Board");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var Units_1 = require("../../Units");
var Capital = (function (_super) {
    __extends(Capital, _super);
    function Capital(name, adjacencyBonus, metadata) {
        if (name === void 0) { name = CardName_1.CardName.CAPITAL; }
        if (adjacencyBonus === void 0) { adjacencyBonus = undefined; }
        if (metadata === void 0) { metadata = {
            cardNumber: '008',
            description: {
                text: 'Requires 4 ocean tiles. Place this tile. Decrease your Energy production 2 steps and increase your M€ production 5 steps.',
                align: 'left',
            },
            renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                b.production(function (pb) {
                    pb.minus().energy(2).br;
                    pb.plus().megacredits(5);
                }).nbsp.tile(TileType_1.TileType.CAPITAL, false).br;
                b.vpText('1 additional VP for each ocean tile adjacent to this city tile.');
            }),
            victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.oceans(1, 1),
        }; }
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: name,
            tags: [Tags_1.Tags.CITY, Tags_1.Tags.BUILDING],
            cost: 26,
            adjacencyBonus: adjacencyBonus,
            productionBox: Units_1.Units.of({ energy: -2, megacredits: 5 }),
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.oceans(4); }),
            metadata: metadata,
        }) || this;
    }
    Capital.prototype.canPlay = function (player) {
        return player.getProduction(Resources_1.Resources.ENERGY) >= 2 &&
            _super.prototype.canPlay.call(this, player) &&
            player.game.board.getAvailableSpacesForCity(player).length > 0;
    };
    Capital.prototype.getVictoryPoints = function (player) {
        var usedSpace = player.game.board.getSpaceByTileCard(this.name);
        if (usedSpace !== undefined) {
            return player.game.board.getAdjacentSpaces(usedSpace)
                .filter(function (s) { return Board_1.Board.isOceanSpace(s); }).length;
        }
        return 0;
    };
    Capital.prototype.play = function (player) {
        var _this = this;
        player.addProduction(Resources_1.Resources.ENERGY, -2);
        player.addProduction(Resources_1.Resources.MEGACREDITS, 5);
        return new SelectSpace_1.SelectSpace('Select space for special city tile', player.game.board.getAvailableSpacesForCity(player), function (space) {
            player.game.addTile(player, SpaceType_1.SpaceType.LAND, space, {
                tileType: TileType_1.TileType.CAPITAL,
                card: _this.name,
            });
            space.adjacency = _this.adjacencyBonus;
            return undefined;
        });
    };
    return Capital;
}(Card_1.Card));
exports.Capital = Capital;
