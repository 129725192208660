"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenerousFunding = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var GenerousFunding = (function () {
    function GenerousFunding() {
        this.name = GlobalEventName_1.GlobalEventName.GENEROUS_FUNDING;
        this.description = 'Gain 2 M€ for each influence and set of 5 TR over 15 (max 5 sets).';
        this.revealedDelegate = PartyName_1.PartyName.KELVINISTS;
        this.currentDelegate = PartyName_1.PartyName.UNITY;
    }
    GenerousFunding.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            var trSets = Math.max(0, Math.floor((player.getTerraformRating() - 15) / 5));
            var maxTRSets = 5;
            var totalSets = Math.min(maxTRSets, trSets) + turmoil.getPlayerInfluence(player);
            player.addResource(Resources_1.Resources.MEGACREDITS, 2 * totalSets, { log: true, from: _this.name });
        });
    };
    return GenerousFunding;
}());
exports.GenerousFunding = GenerousFunding;
