"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AquiferReleasedByPublicCouncil = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var PlaceOceanTile_1 = require("../../deferredActions/PlaceOceanTile");
var AquiferReleasedByPublicCouncil = (function () {
    function AquiferReleasedByPublicCouncil() {
        this.name = GlobalEventName_1.GlobalEventName.AQUIFER_RELEASED_BY_PUBLIC_COUNCIL;
        this.description = 'First player places an ocean tile. Gain 1 plant and 1 steel per influence.';
        this.revealedDelegate = PartyName_1.PartyName.MARS;
        this.currentDelegate = PartyName_1.PartyName.GREENS;
    }
    AquiferReleasedByPublicCouncil.prototype.resolve = function (game, turmoil) {
        game.defer(new PlaceOceanTile_1.PlaceOceanTile(game.getPlayers()[0], 'Select Ocean for Global Event'));
        game.getPlayers().forEach(function (player) {
            player.addResource(Resources_1.Resources.PLANTS, turmoil.getPlayerInfluence(player), { log: true, from: GlobalEventName_1.GlobalEventName.CORROSIVE_RAIN });
            player.addResource(Resources_1.Resources.STEEL, turmoil.getPlayerInfluence(player), { log: true, from: GlobalEventName_1.GlobalEventName.CORROSIVE_RAIN });
        });
    };
    return AquiferReleasedByPublicCouncil;
}());
exports.AquiferReleasedByPublicCouncil = AquiferReleasedByPublicCouncil;
