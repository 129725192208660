"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SellPatentsStandardProject = void 0;
var CardName_1 = require("../../../CardName");
var CardRenderer_1 = require("../../render/CardRenderer");
var StandardProjectCard_1 = require("../../StandardProjectCard");
var SelectCard_1 = require("../../../inputs/SelectCard");
var SellPatentsStandardProject = (function (_super) {
    __extends(SellPatentsStandardProject, _super);
    function SellPatentsStandardProject() {
        return _super.call(this, {
            name: CardName_1.CardName.SELL_PATENTS_STANDARD_PROJECT,
            cost: 0,
            metadata: {
                cardNumber: 'SP8',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    return b.standardProject('Discard any number of cards to gain that amount of M€.', function (eb) {
                        eb.text('X').cards(1).startAction.megacredits(0).multiplier;
                    });
                }),
            },
        }) || this;
    }
    SellPatentsStandardProject.prototype.canAct = function (player) {
        return player.cardsInHand.length > 0;
    };
    SellPatentsStandardProject.prototype.actionEssence = function () {
    };
    SellPatentsStandardProject.prototype.action = function (player) {
        var _this = this;
        return new SelectCard_1.SelectCard('Sell patents', 'Sell', player.cardsInHand, function (foundCards) {
            player.megaCredits += foundCards.length;
            foundCards.forEach(function (card) {
                for (var i = 0; i < player.cardsInHand.length; i++) {
                    if (player.cardsInHand[i].name === card.name) {
                        player.cardsInHand.splice(i, 1);
                        break;
                    }
                }
                player.game.dealer.discard(card);
            });
            _this.projectPlayed(player);
            player.game.log('${0} sold ${1} patents', function (b) { return b.player(player).number(foundCards.length); });
            return undefined;
        }, player.cardsInHand.length);
    };
    return SellPatentsStandardProject;
}(StandardProjectCard_1.StandardProjectCard));
exports.SellPatentsStandardProject = SellPatentsStandardProject;
