"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoonRoadStandardProject = void 0;
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var StandardProjectCard_1 = require("../StandardProjectCard");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var PlaceMoonRoadTile_1 = require("../../moon/PlaceMoonRoadTile");
var Units_1 = require("../../Units");
var TileType_1 = require("../../TileType");
var CardRenderItem_1 = require("../render/CardRenderItem");
var MoonRoadStandardProject = (function (_super) {
    __extends(MoonRoadStandardProject, _super);
    function MoonRoadStandardProject(properties) {
        if (properties === void 0) { properties = {
            name: CardName_1.CardName.MOON_ROAD_STANDARD_PROJECT,
            cost: 18,
            reserveUnits: Units_1.Units.of({ steel: 1 }),
            metadata: {
                cardNumber: '',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    return b.standardProject('Spend 18 M€ and 1 steel to place a road on the moon and raise the Logistics Rate 1 step.', function (eb) {
                        eb.megacredits(18).steel(1).startAction.moonRoad().secondaryTag(CardRenderItem_1.AltSecondaryTag.MOON_LOGISTICS_RATE);
                    });
                }),
            },
        }; }
        var _this = _super.call(this, properties) || this;
        _this.tilesBuilt = [TileType_1.TileType.MOON_ROAD];
        return _this;
    }
    MoonRoadStandardProject.prototype.discount = function (player) {
        if (player.playedCards.find(function (card) { return card.name === CardName_1.CardName.MOONCRATE_BLOCK_FACTORY; })) {
            return 4;
        }
        return _super.prototype.discount.call(this, player);
    };
    MoonRoadStandardProject.prototype.canAct = function (player) {
        var moonData = MoonExpansion_1.MoonExpansion.moonData(player.game);
        var spaces = moonData.moon.getAvailableSpacesOnLand(player);
        if (spaces.length === 0) {
            return false;
        }
        return _super.prototype.canAct.call(this, player);
    };
    MoonRoadStandardProject.prototype.actionEssence = function (player) {
        var adjustedReserveUnits = MoonExpansion_1.MoonExpansion.adjustedReserveCosts(player, this);
        player.deductUnits(adjustedReserveUnits);
        player.game.defer(new PlaceMoonRoadTile_1.PlaceMoonRoadTile(player));
    };
    return MoonRoadStandardProject;
}(StandardProjectCard_1.StandardProjectCard));
exports.MoonRoadStandardProject = MoonRoadStandardProject;
