"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var CardTitle_vue_1 = require("./CardTitle.vue");
var CardNumber_vue_1 = require("./CardNumber.vue");
var CardResourceCounter_vue_1 = require("./CardResourceCounter.vue");
var CardCost_vue_1 = require("./CardCost.vue");
var CardExtraContent_vue_1 = require("./CardExtraContent.vue");
var CardExpansion_vue_1 = require("./CardExpansion.vue");
var CardTags_vue_1 = require("./CardTags.vue");
var CardType_1 = require("@/cards/CardType");
var CardContent_vue_1 = require("./CardContent.vue");
var Tags_1 = require("@/cards/Tags");
var AllCards_1 = require("@/cards/AllCards");
var PreferencesManager_1 = require("@/client/utils/PreferencesManager");
exports.default = vue_1.default.extend({
    name: 'Card',
    components: {
        CardTitle: CardTitle_vue_1.default,
        CardResourceCounter: CardResourceCounter_vue_1.default,
        CardCost: CardCost_vue_1.default,
        CardExtraContent: CardExtraContent_vue_1.default,
        CardExpansion: CardExpansion_vue_1.default,
        CardTags: CardTags_vue_1.default,
        CardContent: CardContent_vue_1.default,
        CardNumber: CardNumber_vue_1.default,
    },
    props: {
        'card': {
            type: Object,
            required: true,
        },
        'actionUsed': {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: function () {
        var cardInstance;
        var cardName = this.card.name;
        var expansion;
        for (var _i = 0, ALL_CARD_MANIFESTS_1 = AllCards_1.ALL_CARD_MANIFESTS; _i < ALL_CARD_MANIFESTS_1.length; _i++) {
            var manifest = ALL_CARD_MANIFESTS_1[_i];
            var decks = [
                manifest.corporationCards,
                manifest.projectCards,
                manifest.preludeCards,
                manifest.standardProjects,
                manifest.standardActions,
            ];
            for (var _a = 0, decks_1 = decks; _a < decks_1.length; _a++) {
                var deck = decks_1[_a];
                var factory = deck.findByCardName(cardName);
                if (factory !== undefined) {
                    cardInstance = new factory.Factory();
                    expansion = manifest.module;
                    break;
                }
            }
            if (expansion !== undefined) {
                break;
            }
        }
        if (cardInstance === undefined || expansion === undefined) {
            throw new Error("Can't find card " + cardName);
        }
        return {
            cardInstance: cardInstance,
            expansion: expansion,
        };
    },
    methods: {
        getCardExpansion: function () {
            return this.expansion;
        },
        getCard: function () {
            return this.cardInstance;
        },
        getTags: function () {
            var _a;
            var result = [];
            var type = this.getCardType();
            var tags = (_a = this.getCard()) === null || _a === void 0 ? void 0 : _a.tags;
            if (tags !== undefined) {
                result = result.concat(tags);
            }
            if (type === CardType_1.CardType.EVENT) {
                result.push(Tags_1.Tags.EVENT);
            }
            return result;
        },
        getCost: function () {
            var _a;
            var cost = (_a = this.getCard()) === null || _a === void 0 ? void 0 : _a.cost;
            var type = this.getCardType();
            return cost === undefined || type === CardType_1.CardType.PRELUDE || type === CardType_1.CardType.CORPORATION ? undefined : cost;
        },
        getReducedCost: function () {
            var cost = this.card.calculatedCost;
            var type = this.getCardType();
            return cost === undefined || type === CardType_1.CardType.PRELUDE || type === CardType_1.CardType.CORPORATION ? undefined : cost;
        },
        getCardType: function () {
            var _a;
            return (_a = this.getCard()) === null || _a === void 0 ? void 0 : _a.cardType;
        },
        getCardNumber: function () {
            var _a;
            return String((_a = this.getCardMetadata()) === null || _a === void 0 ? void 0 : _a.cardNumber);
        },
        getCardClasses: function (card) {
            var classes = ['card-container', 'filterDiv', 'hover-hide-res'];
            classes.push('card-' + card.name.toLowerCase().replace(/ /g, '-'));
            if (this.actionUsed || card.isDisabled) {
                classes.push('card-unavailable');
            }
            if (this.isStandardProject()) {
                classes.push('card-standard-project');
            }
            var learnerModeOff = PreferencesManager_1.PreferencesManager.load('learner_mode') === '0';
            if (learnerModeOff && this.isStandardProject() && card.isDisabled) {
                classes.push('card-hide');
            }
            return classes.join(' ');
        },
        getCardMetadata: function () {
            var _a;
            return (_a = this.getCard()) === null || _a === void 0 ? void 0 : _a.metadata;
        },
        getCardRequirements: function () {
            var _a;
            return (_a = this.getCard()) === null || _a === void 0 ? void 0 : _a.requirements;
        },
        getResourceAmount: function (card) {
            return card.resources !== undefined ? card.resources : 0;
        },
        isCorporationCard: function () {
            return this.getCardType() === CardType_1.CardType.CORPORATION;
        },
        isStandardProject: function () {
            return this.getCardType() === CardType_1.CardType.STANDARD_PROJECT || this.getCardType() === CardType_1.CardType.STANDARD_ACTION;
        },
    },
});
