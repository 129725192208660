"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MagneticFieldGenerators = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var constants_1 = require("../../constants");
var CardRenderer_1 = require("../render/CardRenderer");
var Units_1 = require("../../Units");
var MagneticFieldGenerators = (function (_super) {
    __extends(MagneticFieldGenerators, _super);
    function MagneticFieldGenerators() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.MAGNETIC_FIELD_GENERATORS,
            tags: [Tags_1.Tags.BUILDING],
            cost: 20,
            productionBox: Units_1.Units.of({ energy: -4, plants: 2 }),
            metadata: {
                cardNumber: '165',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.minus().energy(4).digit.br;
                        pb.plus().plants(2);
                    }).br;
                    b.tr(3);
                }),
                description: 'Decrease your Energy production 4 steps and increase your Plant production 2 steps. Raise your TR 3 steps.',
            },
        }) || this;
    }
    MagneticFieldGenerators.prototype.canPlay = function (player) {
        var meetsEnergyRequirements = player.getProduction(Resources_1.Resources.ENERGY) >= 4;
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS)) {
            return player.canAfford(player.getCardCost(this) + constants_1.REDS_RULING_POLICY_COST * 3, { steel: true }) && meetsEnergyRequirements;
        }
        return meetsEnergyRequirements;
    };
    MagneticFieldGenerators.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.ENERGY, -4);
        player.addProduction(Resources_1.Resources.PLANTS, 2);
        player.increaseTerraformRatingSteps(3);
        return undefined;
    };
    return MagneticFieldGenerators;
}(Card_1.Card));
exports.MagneticFieldGenerators = MagneticFieldGenerators;
