"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiningCard = void 0;
var Card_1 = require("../Card");
var CardName_1 = require("../../CardName");
var CardType_1 = require("../../cards/CardType");
var Resources_1 = require("../../Resources");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var SpaceBonus_1 = require("../../SpaceBonus");
var Tags_1 = require("../../cards/Tags");
var TileType_1 = require("../../TileType");
var MiningCard = (function (_super) {
    __extends(MiningCard, _super);
    function MiningCard(name, cost, metadata) {
        var _this = _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: name,
            tags: [Tags_1.Tags.BUILDING],
            cost: cost,
            metadata: metadata,
        }) || this;
        _this.bonusResource = undefined;
        return _this;
    }
    MiningCard.prototype.canPlay = function (player) {
        return this.getAvailableSpaces(player).length > 0;
    };
    MiningCard.prototype.isAres = function () {
        return this.name === CardName_1.CardName.MINING_AREA_ARES ||
            this.name === CardName_1.CardName.MINING_RIGHTS_ARES;
    };
    MiningCard.prototype.getAdjacencyBonus = function (bonusType) {
        if (this.isAres()) {
            return { bonus: [bonusType] };
        }
        return undefined;
    };
    MiningCard.prototype.getAvailableSpaces = function (player) {
        return player.game.board.getAvailableSpacesOnLand(player)
            .filter(function (space) { return space.tile === undefined; })
            .filter(function (space) { return space.bonus.includes(SpaceBonus_1.SpaceBonus.STEEL) || space.bonus.includes(SpaceBonus_1.SpaceBonus.TITANIUM); });
    };
    MiningCard.prototype.getSelectTitle = function () {
        var result = 'Select a space with a steel or titanium bonus';
        if (this.name === CardName_1.CardName.MINING_AREA || this.name === CardName_1.CardName.MINING_AREA_ARES) {
            result += ' adjacent to one of your tiles';
        }
        return result;
    };
    MiningCard.prototype.getTileType = function (bonus) {
        if (this.isAres()) {
            return bonus === SpaceBonus_1.SpaceBonus.STEEL ? TileType_1.TileType.MINING_STEEL_BONUS : TileType_1.TileType.MINING_TITANIUM_BONUS;
        }
        if (this.name === CardName_1.CardName.MINING_RIGHTS) {
            return TileType_1.TileType.MINING_RIGHTS;
        }
        return TileType_1.TileType.MINING_AREA;
    };
    MiningCard.prototype.produce = function (player) {
        if (this.bonusResource === undefined) {
            return;
        }
        player.addProduction(this.bonusResource, 1, { log: true });
    };
    MiningCard.prototype.play = function (player) {
        var _this = this;
        return new SelectSpace_1.SelectSpace(this.getSelectTitle(), this.getAvailableSpaces(player), function (space) {
            var grantTitanium = space.bonus.includes(SpaceBonus_1.SpaceBonus.TITANIUM);
            _this.bonusResource = grantTitanium ? Resources_1.Resources.TITANIUM : Resources_1.Resources.STEEL;
            _this.produce(player);
            var spaceBonus = grantTitanium ? SpaceBonus_1.SpaceBonus.TITANIUM : SpaceBonus_1.SpaceBonus.STEEL;
            player.game.addTile(player, space.spaceType, space, { tileType: _this.getTileType(spaceBonus) });
            space.adjacency = _this.getAdjacencyBonus(spaceBonus);
            return undefined;
        });
    };
    return MiningCard;
}(Card_1.Card));
exports.MiningCard = MiningCard;
