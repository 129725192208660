"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoholeLake = void 0;
var Tags_1 = require("./../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("./../CardType");
var CardName_1 = require("../../CardName");
var ResourceType_1 = require("../../ResourceType");
var SelectCard_1 = require("../../inputs/SelectCard");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var constants_1 = require("../../constants");
var PlaceOceanTile_1 = require("../../deferredActions/PlaceOceanTile");
var CardRenderer_1 = require("../render/CardRenderer");
var MoholeLake = (function (_super) {
    __extends(MoholeLake, _super);
    function MoholeLake() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.MOHOLE_LAKE,
            tags: [Tags_1.Tags.BUILDING],
            cost: 31,
            metadata: {
                cardNumber: 'X27',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Add a microbe or animal to ANOTHER card.', function (eb) {
                        eb.empty().startAction.microbes(1).asterix();
                        eb.nbsp.or().nbsp.animals(1).asterix();
                    }).br;
                    b.plants(3).temperature(1).oceans(1);
                }),
                description: 'Gain 3 plants. Raise temperature 1 step, and place 1 ocean tile.',
            },
        }) || this;
    }
    MoholeLake.prototype.canPlay = function (player) {
        var temperatureStep = player.game.getTemperature() < constants_1.MAX_TEMPERATURE ? 1 : 0;
        var oceanStep = player.game.board.getOceansOnBoard() < constants_1.MAX_OCEAN_TILES ? 1 : 0;
        var totalSteps = temperatureStep + oceanStep;
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS)) {
            return player.canAfford(constants_1.REDS_RULING_POLICY_COST * totalSteps, { steel: true });
        }
        return true;
    };
    MoholeLake.prototype.play = function (player) {
        player.game.increaseTemperature(player, 1);
        player.game.defer(new PlaceOceanTile_1.PlaceOceanTile(player));
        player.plants += 3;
        return undefined;
    };
    MoholeLake.prototype.canAct = function () {
        return true;
    };
    MoholeLake.prototype.action = function (player) {
        var availableCards = player.getResourceCards(ResourceType_1.ResourceType.MICROBE).concat(player.getResourceCards(ResourceType_1.ResourceType.ANIMAL));
        if (availableCards.length === 0) {
            return undefined;
        }
        if (availableCards.length === 1) {
            player.addResourceTo(availableCards[0], { log: true });
            return undefined;
        }
        return new SelectCard_1.SelectCard('Select card to add microbe or animal', 'Add resource', availableCards, function (foundCards) {
            player.addResourceTo(foundCards[0], { log: true });
            return undefined;
        });
    };
    return MoholeLake;
}(Card_1.Card));
exports.MoholeLake = MoholeLake;
