"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.GreatDamPromo = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var SelectSpace_1 = require("../../inputs/SelectSpace");
var TileType_1 = require("../../TileType");
var Board_1 = require("../../boards/Board");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var Units_1 = require("../../Units");
var GreatDamPromo = (function (_super) {
    __extends(GreatDamPromo, _super);
    function GreatDamPromo() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.GREAT_DAM_PROMO,
            cost: 15,
            tags: [Tags_1.Tags.ENERGY, Tags_1.Tags.BUILDING],
            productionBox: Units_1.Units.of({ energy: 2 }),
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.oceans(4); }),
            metadata: {
                cardNumber: 'X32',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.energy(2); }).tile(TileType_1.TileType.GREAT_DAM, true, false).asterix();
                }),
                description: 'Requires 4 ocean tiles. Increase your Energy production 2 steps. Place this tile ADJACENT TO an ocean tile.',
                victoryPoints: 1,
            },
        }) || this;
    }
    GreatDamPromo.prototype.canPlay = function (player) {
        if (!_super.prototype.canPlay.call(this, player)) {
            return false;
        }
        return this.getAvailableSpaces(player).length > 0;
    };
    GreatDamPromo.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.ENERGY, 2);
        var availableSpaces = this.getAvailableSpaces(player);
        if (availableSpaces.length < 1)
            return undefined;
        return new SelectSpace_1.SelectSpace('Select space for tile', availableSpaces, function (foundSpace) {
            player.game.addTile(player, foundSpace.spaceType, foundSpace, { tileType: TileType_1.TileType.GREAT_DAM });
            return undefined;
        });
    };
    GreatDamPromo.prototype.getVictoryPoints = function () {
        return 1;
    };
    GreatDamPromo.prototype.getAvailableSpaces = function (player) {
        return player.game.board.getAvailableSpacesOnLand(player)
            .filter(function (space) { return player.game.board.getAdjacentSpaces(space).filter(function (adjacentSpace) { return Board_1.Board.isOceanSpace(adjacentSpace); }).length > 0; });
    };
    return GreatDamPromo;
}(Card_1.Card));
exports.GreatDamPromo = GreatDamPromo;
