"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Turmoil = exports.ALL_PARTIES = void 0;
var PartyName_1 = require("./parties/PartyName");
var MarsFirst_1 = require("./parties/MarsFirst");
var Scientists_1 = require("./parties/Scientists");
var Unity_1 = require("./parties/Unity");
var Kelvinists_1 = require("./parties/Kelvinists");
var Reds_1 = require("./parties/Reds");
var Greens_1 = require("./parties/Greens");
var GlobalEventDealer_1 = require("./globalEvents/GlobalEventDealer");
var constants_1 = require("../constants");
var PoliticalAgendas_1 = require("./PoliticalAgendas");
var CardName_1 = require("../CardName");
var DeferredAction_1 = require("../deferredActions/DeferredAction");
exports.ALL_PARTIES = [
    { partyName: PartyName_1.PartyName.MARS, Factory: MarsFirst_1.MarsFirst },
    { partyName: PartyName_1.PartyName.SCIENTISTS, Factory: Scientists_1.Scientists },
    { partyName: PartyName_1.PartyName.UNITY, Factory: Unity_1.Unity },
    { partyName: PartyName_1.PartyName.GREENS, Factory: Greens_1.Greens },
    { partyName: PartyName_1.PartyName.REDS, Factory: Reds_1.Reds },
    { partyName: PartyName_1.PartyName.KELVINISTS, Factory: Kelvinists_1.Kelvinists },
];
var UNINITIALIZED_POLITICAL_AGENDAS_DATA = {
    agendas: new Map(),
    agendaStyle: PoliticalAgendas_1.AgendaStyle.CHAIRMAN,
};
var Turmoil = (function () {
    function Turmoil(rulingPartyName, chairman, dominantPartyName, globalEventDealer) {
        this.chairman = undefined;
        this.lobby = new Set();
        this.delegateReserve = [];
        this.parties = exports.ALL_PARTIES.map(function (cf) { return new cf.Factory(); });
        this.playersInfluenceBonus = new Map();
        this.politicalAgendasData = UNINITIALIZED_POLITICAL_AGENDAS_DATA;
        this.rulingParty = this.getPartyByName(rulingPartyName);
        this.chairman = chairman;
        this.dominantParty = this.getPartyByName(dominantPartyName);
        this.globalEventDealer = globalEventDealer;
    }
    Turmoil.newInstance = function (game, agendaStyle) {
        if (agendaStyle === void 0) { agendaStyle = PoliticalAgendas_1.AgendaStyle.STANDARD; }
        var dealer = GlobalEventDealer_1.GlobalEventDealer.newInstance(game);
        var turmoil = new Turmoil(PartyName_1.PartyName.GREENS, 'NEUTRAL', PartyName_1.PartyName.GREENS, dealer);
        game.log('A neutral delegate is the new chairman.');
        game.log('Greens are in power in the first generation.');
        turmoil.parties = exports.ALL_PARTIES.map(function (cf) { return new cf.Factory(); });
        game.getPlayers().forEach(function (player) {
            turmoil.lobby.add(player.id);
            for (var i = 0; i < constants_1.PLAYER_DELEGATES_COUNT - 1; i++) {
                turmoil.delegateReserve.push(player.id);
            }
        });
        for (var i = 0; i < 13; i++) {
            turmoil.delegateReserve.push('NEUTRAL');
        }
        turmoil.politicalAgendasData = PoliticalAgendas_1.PoliticalAgendas.newInstance(agendaStyle, turmoil.parties);
        turmoil.onAgendaSelected(game);
        turmoil.initGlobalEvent(game);
        return turmoil;
    };
    Turmoil.getTurmoil = function (game) {
        if (game.turmoil === undefined) {
            throw new Error("Assertion error: Turmoil not defined for " + game.id);
        }
        return game.turmoil;
    };
    Turmoil.ifTurmoil = function (game, cb) {
        if (game.gameOptions.turmoilExtension !== false) {
            if (game.turmoil === undefined) {
                console.log("Assertion failure: game.turmoil is undefined for " + game.id);
            }
            else {
                return cb(game.turmoil);
            }
        }
    };
    Turmoil.ifTurmoilElse = function (game, cb, elseCb) {
        if (game.gameOptions.turmoilExtension !== false) {
            if (game.turmoil === undefined) {
                console.log("Assertion failure: game.turmoil is undefined for " + game.id);
            }
            else {
                return cb(game.turmoil);
            }
        }
        return elseCb();
    };
    Turmoil.prototype.initGlobalEvent = function (game) {
        this.comingGlobalEvent = this.globalEventDealer.draw();
        if (this.comingGlobalEvent !== undefined) {
            this.sendDelegateToParty('NEUTRAL', this.comingGlobalEvent.revealedDelegate, game);
        }
        this.distantGlobalEvent = this.globalEventDealer.draw();
        if (this.distantGlobalEvent !== undefined) {
            this.sendDelegateToParty('NEUTRAL', this.distantGlobalEvent.revealedDelegate, game);
        }
    };
    Turmoil.prototype.getPartyByName = function (name) {
        var party = this.parties.find(function (party) { return party.name === name; });
        if (party === undefined) {
            throw new Error("Cannot find party with name {" + name + "}");
        }
        return party;
    };
    Turmoil.prototype.sendDelegateToParty = function (playerId, partyName, game, source) {
        if (source === void 0) { source = 'lobby'; }
        var party = this.getPartyByName(partyName);
        if (playerId !== 'NEUTRAL' && this.lobby.has(playerId) && source === 'lobby') {
            this.lobby.delete(playerId);
        }
        else {
            var index = this.delegateReserve.indexOf(playerId);
            if (index > -1) {
                this.delegateReserve.splice(index, 1);
            }
        }
        party.sendDelegate(playerId, game);
        this.checkDominantParty(party);
    };
    Turmoil.prototype.removeDelegateFromParty = function (playerId, partyName, game) {
        var party = this.getPartyByName(partyName);
        this.delegateReserve.push(playerId);
        party.removeDelegate(playerId, game);
        this.checkDominantParty(party);
    };
    Turmoil.prototype.replaceDelegateFromParty = function (outgoingPlayerId, incomingPlayerId, source, partyName, game) {
        if (source === void 0) { source = 'lobby'; }
        var party = this.getPartyByName(partyName);
        this.delegateReserve.push(outgoingPlayerId);
        party.removeDelegate(outgoingPlayerId, game);
        this.sendDelegateToParty(incomingPlayerId, partyName, game, source);
    };
    Turmoil.prototype.checkDominantParty = function (party) {
        if (this.dominantParty) {
            var sortParties = __spreadArray([], this.parties).sort(function (p1, p2) { return p2.delegates.length - p1.delegates.length; });
            var max = sortParties[0].delegates.length;
            if (this.dominantParty.delegates.length !== max) {
                this.setNextPartyAsDominant(this.dominantParty);
            }
        }
        else {
            this.dominantParty = party;
        }
    };
    Turmoil.prototype.setNextPartyAsDominant = function (currentDominantParty) {
        var _this = this;
        var sortParties = __spreadArray([], this.parties).sort(function (p1, p2) { return p2.delegates.length - p1.delegates.length; });
        var max = sortParties[0].delegates.length;
        var currentIndex = this.parties.indexOf(currentDominantParty);
        var partiesToCheck = [];
        if (currentIndex === 0) {
            partiesToCheck = this.parties.slice(currentIndex + 1);
        }
        else if (currentIndex === this.parties.length - 1) {
            partiesToCheck = this.parties.slice(0, currentIndex);
        }
        else {
            var left = this.parties.slice(0, currentIndex);
            var right = this.parties.slice(currentIndex + 1);
            partiesToCheck = right.concat(left);
        }
        var partiesOrdered = partiesToCheck.reverse();
        partiesOrdered.some(function (newParty) {
            if (newParty.delegates.length === max) {
                _this.dominantParty = newParty;
                return true;
            }
            return false;
        });
    };
    Turmoil.prototype.endGeneration = function (game) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            player.decreaseTerraformRating();
        });
        if (this.currentGlobalEvent !== undefined) {
            var currentGlobalEvent_1 = this.currentGlobalEvent;
            game.log('Resolving global event ${0}', function (b) { return b.globalEvent(currentGlobalEvent_1); });
            currentGlobalEvent_1.resolve(game, this);
        }
        this.rulingParty = this.dominantParty;
        if (this.rulingParty) {
            this.setRulingParty(game);
        }
        this.setNextPartyAsDominant(this.rulingParty);
        this.lobby.forEach(function (playerId) {
            _this.delegateReserve.push(playerId);
        });
        this.lobby = new Set();
        game.getPlayers().forEach(function (player) {
            if (_this.getDelegatesInReserve(player.id) > 0) {
                var index = _this.delegateReserve.indexOf(player.id);
                if (index > -1) {
                    _this.delegateReserve.splice(index, 1);
                }
                _this.lobby.add(player.id);
            }
        });
        if (this.currentGlobalEvent) {
            this.globalEventDealer.discardedGlobalEvents.push(this.currentGlobalEvent);
        }
        this.currentGlobalEvent = this.comingGlobalEvent;
        if (this.currentGlobalEvent) {
            this.sendDelegateToParty('NEUTRAL', this.currentGlobalEvent.currentDelegate, game);
        }
        this.comingGlobalEvent = this.distantGlobalEvent;
        this.distantGlobalEvent = this.globalEventDealer.draw();
        if (this.distantGlobalEvent) {
            this.sendDelegateToParty('NEUTRAL', this.distantGlobalEvent.revealedDelegate, game);
        }
        game.log('Turmoil phase has been resolved');
    };
    Turmoil.prototype.setRulingParty = function (game) {
        var _a;
        if (this.rulingParty !== undefined) {
            game.getPlayers().forEach(function (player) { return player.hasTurmoilScienceTagBonus = false; });
            if (this.chairman) {
                this.delegateReserve.push(this.chairman);
            }
            this.chairman = this.rulingParty.partyLeader || 'NEUTRAL';
            var index = this.rulingParty.delegates.indexOf(this.rulingParty.partyLeader);
            this.rulingParty.delegates.splice(index, 1);
            this.delegateReserve = this.delegateReserve.concat(this.rulingParty.delegates);
            this.rulingParty.partyLeader = undefined;
            this.rulingParty.delegates = [];
            PoliticalAgendas_1.PoliticalAgendas.setNextAgenda(this, game);
            if (this.chairman !== 'NEUTRAL') {
                var player_1 = game.getPlayerById(this.chairman);
                var steps_1 = ((_a = player_1.corporationCard) === null || _a === void 0 ? void 0 : _a.name) === CardName_1.CardName.TEMPEST_CONSULTANCY ? 2 : 1;
                game.defer(new DeferredAction_1.DeferredAction(player_1, function () {
                    player_1.increaseTerraformRatingSteps(steps_1);
                    game.log('${0} is the new chairman and gains ${1} TR', function (b) { return b.player(player_1).number(steps_1); });
                    return undefined;
                }));
            }
            else {
                game.log('A neutral delegate is the new chairman.');
            }
        }
    };
    Turmoil.prototype.onAgendaSelected = function (game) {
        var rulingParty = this.rulingParty;
        var bonusId = PoliticalAgendas_1.PoliticalAgendas.currentAgenda(this).bonusId;
        var bonus = rulingParty.bonuses.find(function (b) { return b.id === bonusId; });
        if (bonus === undefined) {
            throw new Error("Bonus id " + bonusId + " not found in party " + rulingParty.name);
        }
        game.log('The ruling bonus is: ${0}', function (b) { return b.string(bonus.description); });
        bonus.grant(game);
        var policyId = PoliticalAgendas_1.PoliticalAgendas.currentAgenda(this).policyId;
        var policy = rulingParty.policies.find(function (p) { return p.id === policyId; });
        if (policy === undefined) {
            throw new Error("Policy id " + policyId + " not found in party " + rulingParty.name);
        }
        game.log('The ruling policy is: ${0}', function (b) { return b.string(policy.description); });
        if (policy.apply !== undefined) {
            policy.apply(game);
        }
    };
    Turmoil.prototype.getPlayerInfluence = function (player) {
        var influence = 0;
        if (this.chairman !== undefined && this.chairman === player.id)
            influence++;
        var dominantParty = this.dominantParty;
        var isPartyLeader = dominantParty.partyLeader === player.id;
        var delegateCount = dominantParty.delegates.filter(function (delegate) { return delegate === player.id; }).length;
        if (isPartyLeader) {
            influence++;
            if (delegateCount > 1)
                influence++;
        }
        else {
            if (delegateCount > 0)
                influence++;
        }
        if (this.playersInfluenceBonus.has(player.id)) {
            var bonus = this.playersInfluenceBonus.get(player.id);
            if (bonus) {
                influence += bonus;
            }
        }
        return influence;
    };
    Turmoil.prototype.addInfluenceBonus = function (player, bonus) {
        if (bonus === void 0) { bonus = 1; }
        if (this.playersInfluenceBonus.has(player.id)) {
            var current = this.playersInfluenceBonus.get(player.id);
            if (current) {
                current += bonus;
                this.playersInfluenceBonus.set(player.id, current);
            }
        }
        else {
            this.playersInfluenceBonus.set(player.id, bonus);
        }
    };
    Turmoil.prototype.canPlay = function (player, partyName) {
        if (this.rulingParty.name === partyName) {
            return true;
        }
        var party = this.getPartyByName(partyName);
        if (party.getDelegates(player.id) >= 2) {
            return true;
        }
        return false;
    };
    Turmoil.prototype.getPresentPlayers = function () {
        return Array.from(new Set(this.delegateReserve));
    };
    Turmoil.prototype.getDelegatesInReserve = function (playerId) {
        var delegates = this.delegateReserve.filter(function (p) { return p === playerId; }).length;
        return delegates;
    };
    Turmoil.prototype.hasAvailableDelegates = function (playerId) {
        return this.getDelegatesInReserve(playerId) > 0;
    };
    Turmoil.prototype.getPlayerVictoryPoints = function (player) {
        var victory = 0;
        if (this.chairman !== undefined && this.chairman === player.id)
            victory++;
        this.parties.forEach(function (party) {
            if (party.partyLeader === player.id) {
                victory++;
            }
        });
        return victory;
    };
    Turmoil.prototype.serialize = function () {
        var _a, _b;
        var result = {
            chairman: this.chairman,
            rulingParty: this.rulingParty.name,
            dominantParty: this.dominantParty.name,
            lobby: Array.from(this.lobby),
            delegateReserve: this.delegateReserve,
            parties: this.parties.map(function (p) {
                return {
                    name: p.name,
                    delegates: p.delegates,
                    partyLeader: p.partyLeader,
                };
            }),
            playersInfluenceBonus: Array.from(this.playersInfluenceBonus.entries()),
            globalEventDealer: this.globalEventDealer.serialize(),
            distantGlobalEvent: (_a = this.distantGlobalEvent) === null || _a === void 0 ? void 0 : _a.name,
            comingGlobalEvent: (_b = this.comingGlobalEvent) === null || _b === void 0 ? void 0 : _b.name,
            politicalAgendasData: PoliticalAgendas_1.PoliticalAgendas.serialize(this.politicalAgendasData),
        };
        if (this.currentGlobalEvent !== undefined) {
            result.currentGlobalEvent = this.currentGlobalEvent.name;
        }
        return result;
    };
    Turmoil.deserialize = function (d) {
        var dealer = GlobalEventDealer_1.GlobalEventDealer.deserialize(d.globalEventDealer);
        var turmoil = new Turmoil(d.rulingParty, d.chairman || 'NEUTRAL', d.dominantParty, dealer);
        turmoil.lobby = new Set(d.lobby);
        turmoil.delegateReserve = d.delegateReserve;
        turmoil.politicalAgendasData = PoliticalAgendas_1.PoliticalAgendas.deserialize(d.politicalAgendasData);
        d.parties.forEach(function (sp) {
            var tp = turmoil.getPartyByName(sp.name);
            if (tp === undefined) {
                throw new Error('huh? unknown party: ' + sp.name);
            }
            tp.delegates = sp.delegates;
            tp.partyLeader = sp.partyLeader;
        });
        turmoil.playersInfluenceBonus = new Map(d.playersInfluenceBonus);
        turmoil.playersInfluenceBonus = new Map(d.playersInfluenceBonus);
        if (d.distantGlobalEvent) {
            turmoil.distantGlobalEvent = GlobalEventDealer_1.getGlobalEventByName(d.distantGlobalEvent);
        }
        if (d.comingGlobalEvent) {
            turmoil.comingGlobalEvent = GlobalEventDealer_1.getGlobalEventByName(d.comingGlobalEvent);
        }
        if (d.currentGlobalEvent) {
            turmoil.currentGlobalEvent = GlobalEventDealer_1.getGlobalEventByName(d.currentGlobalEvent);
        }
        return turmoil;
    };
    return Turmoil;
}());
exports.Turmoil = Turmoil;
