"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DarksideMiningSyndicate = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var Resources_1 = require("../../Resources");
var Card_1 = require("../Card");
var DarksideMiningSyndicate = (function (_super) {
    __extends(DarksideMiningSyndicate, _super);
    function DarksideMiningSyndicate() {
        return _super.call(this, {
            name: CardName_1.CardName.DARKSIDE_MINING_SYNDICATE,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.MOON, Tags_1.Tags.SPACE],
            cost: 18,
            metadata: {
                description: 'Increase your Titanium production 2 steps, or ' +
                    '1 step if the Mining Rate is at least 2. And then raise the Mining Rate 1 step.',
                cardNumber: 'M66',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.titanium(2); }).or().br;
                    b.moonMiningRate({ amount: 2 }).colon().production(function (pb) { return pb.titanium(1); }).br;
                    b.moonMiningRate().br;
                }),
            },
        }) || this;
    }
    ;
    DarksideMiningSyndicate.prototype.play = function (player) {
        var productionBonus = (MoonExpansion_1.MoonExpansion.moonData(player.game).miningRate >= 2) ? 1 : 2;
        player.addProduction(Resources_1.Resources.TITANIUM, productionBonus, { log: true });
        MoonExpansion_1.MoonExpansion.raiseMiningRate(player);
        return undefined;
    };
    return DarksideMiningSyndicate;
}(Card_1.Card));
exports.DarksideMiningSyndicate = DarksideMiningSyndicate;
