"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.GreatEscarpmentConsortium = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var DecreaseAnyProduction_1 = require("../../deferredActions/DecreaseAnyProduction");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var GreatEscarpmentConsortium = (function (_super) {
    __extends(GreatEscarpmentConsortium, _super);
    function GreatEscarpmentConsortium() {
        return _super.call(this, {
            cardType: CardType_1.CardType.AUTOMATED,
            name: CardName_1.CardName.GREAT_ESCARPMENT_CONSORTIUM,
            cost: 6,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.production(Resources_1.Resources.STEEL); }),
            metadata: {
                cardNumber: '061',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) {
                        pb.minus().steel(-1).any.br;
                        pb.plus().steel(1);
                    });
                }),
                description: 'Requires that you have steel production. Decrease any steel production 1 step and increase your own 1 step.',
            },
        }) || this;
    }
    GreatEscarpmentConsortium.prototype.play = function (player) {
        player.game.defer(new DecreaseAnyProduction_1.DecreaseAnyProduction(player, Resources_1.Resources.STEEL, 1));
        player.addProduction(Resources_1.Resources.STEEL, 1);
        return undefined;
    };
    return GreatEscarpmentConsortium;
}(Card_1.Card));
exports.GreatEscarpmentConsortium = GreatEscarpmentConsortium;
