"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeworldSupport = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Resources_1 = require("../../Resources");
var Tags_1 = require("../../cards/Tags");
var HomeworldSupport = (function () {
    function HomeworldSupport() {
        this.name = GlobalEventName_1.GlobalEventName.HOMEWORLD_SUPPORT;
        this.description = 'Gain 2 M€ for each Earth tag (max 5) and influence.';
        this.revealedDelegate = PartyName_1.PartyName.REDS;
        this.currentDelegate = PartyName_1.PartyName.UNITY;
    }
    HomeworldSupport.prototype.resolve = function (game, turmoil) {
        var _this = this;
        game.getPlayers().forEach(function (player) {
            var amount = Math.min(5, player.getTagCount(Tags_1.Tags.EARTH, false, false)) + turmoil.getPlayerInfluence(player);
            if (amount > 0) {
                player.addResource(Resources_1.Resources.MEGACREDITS, 2 * amount, { log: true, from: _this.name });
            }
        });
    };
    return HomeworldSupport;
}());
exports.HomeworldSupport = HomeworldSupport;
