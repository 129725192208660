"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FirstLunarSettlement = void 0;
var CardName_1 = require("../../CardName");
var Tags_1 = require("../Tags");
var PreludeCard_1 = require("../prelude/PreludeCard");
var Resources_1 = require("../../Resources");
var PlaceMoonColonyTile_1 = require("../../moon/PlaceMoonColonyTile");
var CardRenderer_1 = require("../render/CardRenderer");
var TileType_1 = require("../../TileType");
var CardRenderItem_1 = require("../render/CardRenderItem");
var FirstLunarSettlement = (function (_super) {
    __extends(FirstLunarSettlement, _super);
    function FirstLunarSettlement() {
        var _this = _super.call(this, {
            name: CardName_1.CardName.FIRST_LUNAR_SETTLEMENT,
            tags: [Tags_1.Tags.CITY, Tags_1.Tags.MOON],
            metadata: {
                description: 'Place a colony tile on the Moon and Raise the Colony Rate 1 step. Increase your M€ production 1 step.',
                cardNumber: '',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.production(function (pb) { return pb.megacredits(1); }).moonColony().secondaryTag(CardRenderItem_1.AltSecondaryTag.MOON_COLONY_RATE);
                }),
            },
        }) || this;
        _this.tilesBuilt = [TileType_1.TileType.MOON_COLONY];
        return _this;
    }
    FirstLunarSettlement.prototype.play = function (player) {
        player.addProduction(Resources_1.Resources.MEGACREDITS, 1, { log: true });
        player.game.defer(new PlaceMoonColonyTile_1.PlaceMoonColonyTile(player));
        return undefined;
    };
    return FirstLunarSettlement;
}(PreludeCard_1.PreludeCard));
exports.FirstLunarSettlement = FirstLunarSettlement;
