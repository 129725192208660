"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MOON_CARD_MANIFEST = void 0;
var CardName_1 = require("../../CardName");
var GameModule_1 = require("../../GameModule");
var CardManifest_1 = require("../CardManifest");
var AIControlledMineNetwork_1 = require("./AIControlledMineNetwork");
var AlgaeBioreactors_1 = require("./AlgaeBioreactors");
var AncientShipyards_1 = require("./AncientShipyards");
var AnOfferYouCantRefuse_1 = require("./AnOfferYouCantRefuse");
var ArchimedesHydroponicsStation_1 = require("./ArchimedesHydroponicsStation");
var AristarchusRoadNetwork_1 = require("./AristarchusRoadNetwork");
var BasicInfrastructure_1 = require("./BasicInfrastructure");
var ColonistShuttles_1 = require("./ColonistShuttles");
var CopernicusSolarArrays_1 = require("./CopernicusSolarArrays");
var CopernicusTower_1 = require("./CopernicusTower");
var CoreMine_1 = require("./CoreMine");
var CosmicRadiation_1 = require("./CosmicRadiation");
var CrescentResearchAssociation_1 = require("./CrescentResearchAssociation");
var DarksideIncubationPlant_1 = require("./DarksideIncubationPlant");
var DarksideMeteorBombardment_1 = require("./DarksideMeteorBombardment");
var DarksideMiningSyndicate_1 = require("./DarksideMiningSyndicate");
var DarksideObservatory_1 = require("./DarksideObservatory");
var DeepLunarMining_1 = require("./DeepLunarMining");
var EarthEmbassy_1 = require("./EarthEmbassy");
var FirstLunarSettlement_1 = require("./FirstLunarSettlement");
var GeodesicTents_1 = require("./GeodesicTents");
var GrandLunaAcademy_1 = require("./GrandLunaAcademy");
var Habitat14_1 = require("./Habitat14");
var HE3FusionPlant_1 = require("./HE3FusionPlant");
var HE3Lobbyists_1 = require("./HE3Lobbyists");
var HE3ProductionQuotas_1 = require("./HE3ProductionQuotas");
var HE3Refinery_1 = require("./HE3Refinery");
var HeavyDutyRovers_1 = require("./HeavyDutyRovers");
var HeliostatMirrorArray_1 = require("./HeliostatMirrorArray");
var HypersensitiveSiliconChipFactory_1 = require("./HypersensitiveSiliconChipFactory");
var ImprovedMoonConcrete_1 = require("./ImprovedMoonConcrete");
var IntragenSanctuaryHeadquarters_1 = require("./IntragenSanctuaryHeadquarters");
var IronExtractionCenter_1 = require("./IronExtractionCenter");
var LTFHeadquarters_1 = require("./LTFHeadquarters");
var LTFPrivileges_1 = require("./LTFPrivileges");
var LunaArchives_1 = require("./LunaArchives");
var LunaConference_1 = require("./LunaConference");
var LunaEcumenopolis_1 = require("./LunaEcumenopolis");
var LunaFirstIncorporated_1 = require("./LunaFirstIncorporated");
var LunaHyperloopCorporation_1 = require("./LunaHyperloopCorporation");
var LunaMiningHub_1 = require("./LunaMiningHub");
var LunaPoliticalInstitute_1 = require("./LunaPoliticalInstitute");
var LunaProjectOffice_1 = require("./LunaProjectOffice");
var LunarDustProcessingPlant_1 = require("./LunarDustProcessingPlant");
var LunaResort_1 = require("./LunaResort");
var LunarIndustryComplex_1 = require("./LunarIndustryComplex");
var LunarMineUrbanization_1 = require("./LunarMineUrbanization");
var LunarObservationPost_1 = require("./LunarObservationPost");
var LunarPlanningOffice_1 = require("./LunarPlanningOffice");
var LunarSecurityStations_1 = require("./LunarSecurityStations");
var LunarSteel_1 = require("./LunarSteel");
var LunarTradeFleet_1 = require("./LunarTradeFleet");
var LunaSenate_1 = require("./LunaSenate");
var LunaStagingStation_1 = require("./LunaStagingStation");
var LunaTradeStation_1 = require("./LunaTradeStation");
var LunaTrainStation_1 = require("./LunaTrainStation");
var MareImbriumMine_1 = require("./MareImbriumMine");
var MareNectarisMine_1 = require("./MareNectarisMine");
var MareNubiumMine_1 = require("./MareNubiumMine");
var MareSerenitatisMine_1 = require("./MareSerenitatisMine");
var MicrosingularityPlant_1 = require("./MicrosingularityPlant");
var MiningComplex_1 = require("./MiningComplex");
var MiningRobotsManufCenter_1 = require("./MiningRobotsManufCenter");
var MomentumViriumHabitat_1 = require("./MomentumViriumHabitat");
var MoonColonyStandardProject_1 = require("./MoonColonyStandardProject");
var MoonStandardProjectVariants_1 = require("./MoonStandardProjectVariants");
var MooncrateBlockFactory_1 = require("./MooncrateBlockFactory");
var MooncrateConvoysToMars_1 = require("./MooncrateConvoysToMars");
var MoonMineStandardProject_1 = require("./MoonMineStandardProject");
var MoonStandardProjectVariants_2 = require("./MoonStandardProjectVariants");
var MoonRoadStandardProject_1 = require("./MoonRoadStandardProject");
var MoonStandardProjectVariants_3 = require("./MoonStandardProjectVariants");
var MoonTether_1 = require("./MoonTether");
var NanotechIndustries_1 = require("./NanotechIndustries");
var NewColonyPlanningInitiaitives_1 = require("./NewColonyPlanningInitiaitives");
var OffWorldCityLiving_1 = require("./OffWorldCityLiving");
var PreliminaryDarkside_1 = require("./PreliminaryDarkside");
var OrbitalPowerGrid_1 = require("./OrbitalPowerGrid");
var PrideoftheEarthArkship_1 = require("./PrideoftheEarthArkship");
var ProcessorFactory_1 = require("./ProcessorFactory");
var RevoltingColonists_1 = require("./RevoltingColonists");
var RoverDriversUnion_1 = require("./RoverDriversUnion");
var RustEatingBacteria_1 = require("./RustEatingBacteria");
var SinusIridiumRoadNetwork_1 = require("./SinusIridiumRoadNetwork");
var SmallDutyRovers_1 = require("./SmallDutyRovers");
var SolarPanelFoundry_1 = require("./SolarPanelFoundry");
var SphereHabitats_1 = require("./SphereHabitats");
var StagingStationBehemoth_1 = require("./StagingStationBehemoth");
var SteelMarketMonopolists_1 = require("./SteelMarketMonopolists");
var SubterraneanHabitats_1 = require("./SubterraneanHabitats");
var SyndicatePirateRaids_1 = require("./SyndicatePirateRaids");
var TempestConsultancy_1 = require("./TempestConsultancy");
var TheArchaicFoundationInstitute_1 = require("./TheArchaicFoundationInstitute");
var TheDarksideofTheMoonSyndicate_1 = require("./TheDarksideofTheMoonSyndicate");
var TheGrandLunaCapitalGroup_1 = require("./TheGrandLunaCapitalGroup");
var TheWomb_1 = require("./TheWomb");
var ThoriumRush_1 = require("./ThoriumRush");
var TitaniumExtractionCenter_1 = require("./TitaniumExtractionCenter");
var TitaniumMarketMonopolists_1 = require("./TitaniumMarketMonopolists");
var TychoRoadNetwork_1 = require("./TychoRoadNetwork");
var UndergroundDetonators_1 = require("./UndergroundDetonators");
var UndermoonDrugLordsNetwork_1 = require("./UndermoonDrugLordsNetwork");
var WaterTreatmentComplex_1 = require("./WaterTreatmentComplex");
var WeGrowAsOne_1 = require("./WeGrowAsOne");
exports.MOON_CARD_MANIFEST = new CardManifest_1.CardManifest({
    module: GameModule_1.GameModule.Moon,
    projectCards: [
        { cardName: CardName_1.CardName.MARE_NECTARIS_MINE, Factory: MareNectarisMine_1.MareNectarisMine },
        { cardName: CardName_1.CardName.MARE_NUBIUM_MINE, Factory: MareNubiumMine_1.MareNubiumMine },
        { cardName: CardName_1.CardName.MARE_IMBRIUM_MINE, Factory: MareImbriumMine_1.MareImbriumMine },
        { cardName: CardName_1.CardName.MARE_SERENITATIS_MINE, Factory: MareSerenitatisMine_1.MareSerenitatisMine },
        { cardName: CardName_1.CardName.HABITAT_14, Factory: Habitat14_1.Habitat14 },
        { cardName: CardName_1.CardName.GEODESIC_TENTS, Factory: GeodesicTents_1.GeodesicTents },
        { cardName: CardName_1.CardName.SPHERE_HABITATS, Factory: SphereHabitats_1.SphereHabitats },
        { cardName: CardName_1.CardName.THE_WOMB, Factory: TheWomb_1.TheWomb },
        { cardName: CardName_1.CardName.TYCHO_ROAD_NETWORK, Factory: TychoRoadNetwork_1.TychoRoadNetwork },
        { cardName: CardName_1.CardName.ARISTARCHUS_ROAD_NETWORK, Factory: AristarchusRoadNetwork_1.AristarchusRoadNetwork },
        { cardName: CardName_1.CardName.SINUS_IRIDIUM_ROAD_NETWORK, Factory: SinusIridiumRoadNetwork_1.SinusIridiumRoadNetwork },
        { cardName: CardName_1.CardName.MOMENTUM_VIRUM_HABITAT, Factory: MomentumViriumHabitat_1.MomentumViriumHabitat },
        { cardName: CardName_1.CardName.LUNA_TRADE_STATION, Factory: LunaTradeStation_1.LunaTradeStation },
        { cardName: CardName_1.CardName.LUNA_MINING_HUB, Factory: LunaMiningHub_1.LunaMiningHub },
        { cardName: CardName_1.CardName.LUNA_TRAIN_STATION, Factory: LunaTrainStation_1.LunaTrainStation },
        { cardName: CardName_1.CardName.COLONIST_SHUTTLES, Factory: ColonistShuttles_1.ColonistShuttles },
        { cardName: CardName_1.CardName.LUNAR_DUST_PROCESSING_PLANT, Factory: LunarDustProcessingPlant_1.LunarDustProcessingPlant },
        { cardName: CardName_1.CardName.DEEP_LUNAR_MINING, Factory: DeepLunarMining_1.DeepLunarMining },
        { cardName: CardName_1.CardName.ANCIENT_SHIPYARDS, Factory: AncientShipyards_1.AncientShipyards },
        { cardName: CardName_1.CardName.LUNA_PROJECT_OFFICE, Factory: LunaProjectOffice_1.LunaProjectOffice },
        { cardName: CardName_1.CardName.LUNA_RESORT, Factory: LunaResort_1.LunaResort },
        { cardName: CardName_1.CardName.LUNAR_OBSERVATION_POST, Factory: LunarObservationPost_1.LunarObservationPost },
        { cardName: CardName_1.CardName.MINING_ROBOTS_MANUF_CENTER, Factory: MiningRobotsManufCenter_1.MiningRobotsManufCenter },
        { cardName: CardName_1.CardName.PRIDE_OF_THE_EARTH_ARKSHIP, Factory: PrideoftheEarthArkship_1.PrideoftheEarthArkship },
        { cardName: CardName_1.CardName.IRON_EXTRACTION_CENTER, Factory: IronExtractionCenter_1.IronExtractionCenter },
        { cardName: CardName_1.CardName.TITANIUM_EXTRACTION_CENTER, Factory: TitaniumExtractionCenter_1.TitaniumExtractionCenter },
        { cardName: CardName_1.CardName.ARCHIMEDES_HYDROPONICS_STATION, Factory: ArchimedesHydroponicsStation_1.ArchimedesHydroponicsStation },
        { cardName: CardName_1.CardName.STEEL_MARKET_MONOPOLISTS, Factory: SteelMarketMonopolists_1.SteelMarketMonopolists },
        { cardName: CardName_1.CardName.TITANIUM_MARKET_MONOPOLISTS, Factory: TitaniumMarketMonopolists_1.TitaniumMarketMonopolists },
        { cardName: CardName_1.CardName.LUNA_STAGING_STATION, Factory: LunaStagingStation_1.LunaStagingStation },
        { cardName: CardName_1.CardName.NEW_COLONY_PLANNING_INITIAITIVES, Factory: NewColonyPlanningInitiaitives_1.NewColonyPlanningInitiaitives },
        { cardName: CardName_1.CardName.AI_CONTROLLED_MINE_NETWORK, Factory: AIControlledMineNetwork_1.AIControlledMineNetwork },
        { cardName: CardName_1.CardName.DARKSIDE_METEOR_BOMBARDMENT, Factory: DarksideMeteorBombardment_1.DarksideMeteorBombardment },
        { cardName: CardName_1.CardName.UNDERGROUND_DETONATORS, Factory: UndergroundDetonators_1.UndergroundDetonators },
        { cardName: CardName_1.CardName.LUNAR_TRADE_FLEET, Factory: LunarTradeFleet_1.LunarTradeFleet },
        { cardName: CardName_1.CardName.SUBTERRANEAN_HABITATS, Factory: SubterraneanHabitats_1.SubterraneanHabitats },
        { cardName: CardName_1.CardName.IMPROVED_MOON_CONCRETE, Factory: ImprovedMoonConcrete_1.ImprovedMoonConcrete },
        { cardName: CardName_1.CardName.MOONCRATE_BLOCK_FACTORY, Factory: MooncrateBlockFactory_1.MooncrateBlockFactory },
        { cardName: CardName_1.CardName.HEAVY_DUTY_ROVERS, Factory: HeavyDutyRovers_1.HeavyDutyRovers },
        { cardName: CardName_1.CardName.MICROSINGULARITY_PLANT, Factory: MicrosingularityPlant_1.MicrosingularityPlant },
        { cardName: CardName_1.CardName.HELIOSTAT_MIRROR_ARRAY, Factory: HeliostatMirrorArray_1.HeliostatMirrorArray },
        { cardName: CardName_1.CardName.LUNAR_SECURITY_STATIONS, Factory: LunarSecurityStations_1.LunarSecurityStations },
        { cardName: CardName_1.CardName.HYPERSENSITIVE_SILICON_CHIP_FACTORY, Factory: HypersensitiveSiliconChipFactory_1.HypersensitiveSiliconChipFactory },
        { cardName: CardName_1.CardName.COPERNICUS_SOLAR_ARRAYS, Factory: CopernicusSolarArrays_1.CopernicusSolarArrays },
        { cardName: CardName_1.CardName.DARKSIDE_INCUBATION_PLANT, Factory: DarksideIncubationPlant_1.DarksideIncubationPlant },
        { cardName: CardName_1.CardName.WATER_TREATMENT_COMPLEX, Factory: WaterTreatmentComplex_1.WaterTreatmentComplex },
        { cardName: CardName_1.CardName.ALGAE_BIOREACTORS, Factory: AlgaeBioreactors_1.AlgaeBioreactors },
        { cardName: CardName_1.CardName.HE3_FUSION_PLANT, Factory: HE3FusionPlant_1.HE3FusionPlant },
        { cardName: CardName_1.CardName.HE3_REFINERY, Factory: HE3Refinery_1.HE3Refinery },
        { cardName: CardName_1.CardName.HE3_LOBBYISTS, Factory: HE3Lobbyists_1.HE3Lobbyists },
        { cardName: CardName_1.CardName.REVOLTING_COLONISTS, Factory: RevoltingColonists_1.RevoltingColonists },
        { cardName: CardName_1.CardName.COSMIC_RADIATION, Factory: CosmicRadiation_1.CosmicRadiation },
        { cardName: CardName_1.CardName.OFF_WORLD_CITY_LIVING, Factory: OffWorldCityLiving_1.OffWorldCityLiving },
        { cardName: CardName_1.CardName.LUNAR_MINE_URBANIZATION, Factory: LunarMineUrbanization_1.LunarMineUrbanization },
        { cardName: CardName_1.CardName.THORIUM_RUSH, Factory: ThoriumRush_1.ThoriumRush },
        { cardName: CardName_1.CardName.HE3_PRODUCTION_QUOTAS, Factory: HE3ProductionQuotas_1.HE3ProductionQuotas, compatibility: GameModule_1.GameModule.Turmoil },
        { cardName: CardName_1.CardName.LUNA_CONFERENCE, Factory: LunaConference_1.LunaConference, compatibility: GameModule_1.GameModule.Turmoil },
        { cardName: CardName_1.CardName.WE_GROW_AS_ONE, Factory: WeGrowAsOne_1.WeGrowAsOne, compatibility: [GameModule_1.GameModule.Turmoil, GameModule_1.GameModule.Colonies] },
        { cardName: CardName_1.CardName.MOONCRATE_CONVOYS_TO_MARS, Factory: MooncrateConvoysToMars_1.MooncrateConvoysToMars, compatibility: [GameModule_1.GameModule.Turmoil] },
        { cardName: CardName_1.CardName.AN_OFFER_YOU_CANT_REFUSE, Factory: AnOfferYouCantRefuse_1.AnOfferYouCantRefuse, compatibility: GameModule_1.GameModule.Turmoil },
        { cardName: CardName_1.CardName.PRELIMINARY_DARKSIDE, Factory: PreliminaryDarkside_1.PreliminaryDarkside },
        { cardName: CardName_1.CardName.SYNDICATE_PIRATE_RAIDS, Factory: SyndicatePirateRaids_1.SyndicatePirateRaids, compatibility: GameModule_1.GameModule.Colonies },
        { cardName: CardName_1.CardName.DARKSIDE_MINING_SYNDICATE, Factory: DarksideMiningSyndicate_1.DarksideMiningSyndicate },
        { cardName: CardName_1.CardName.STAGING_STATION_BEHEMOTH, Factory: StagingStationBehemoth_1.StagingStationBehemoth, compatibility: GameModule_1.GameModule.Colonies },
        { cardName: CardName_1.CardName.LUNA_ARCHIVES, Factory: LunaArchives_1.LunaArchives },
        { cardName: CardName_1.CardName.LUNA_SENATE, Factory: LunaSenate_1.LunaSenate },
        { cardName: CardName_1.CardName.LUNA_POLITICAL_INSTITUTE, Factory: LunaPoliticalInstitute_1.LunaPoliticalInstitute, compatibility: GameModule_1.GameModule.Turmoil },
        { cardName: CardName_1.CardName.COPERNICUS_TOWER, Factory: CopernicusTower_1.CopernicusTower },
        { cardName: CardName_1.CardName.SMALL_DUTY_ROVERS, Factory: SmallDutyRovers_1.SmallDutyRovers },
        { cardName: CardName_1.CardName.LUNAR_INDUSTRY_COMPLEX, Factory: LunarIndustryComplex_1.LunarIndustryComplex },
        { cardName: CardName_1.CardName.DARKSIDE_OBSERVATORY, Factory: DarksideObservatory_1.DarksideObservatory },
        { cardName: CardName_1.CardName.EARTH_EMBASSY, Factory: EarthEmbassy_1.EarthEmbassy },
        { cardName: CardName_1.CardName.ROVER_DRIVERS_UNION, Factory: RoverDriversUnion_1.RoverDriversUnion },
        { cardName: CardName_1.CardName.LTF_HEADQUARTERS, Factory: LTFHeadquarters_1.LTFHeadquarters, compatibility: GameModule_1.GameModule.Colonies },
        { cardName: CardName_1.CardName.UNDERMOON_DRUG_LORDS_NETWORK, Factory: UndermoonDrugLordsNetwork_1.UndermoonDrugLordsNetwork },
        { cardName: CardName_1.CardName.LTF_PRIVILEGES, Factory: LTFPrivileges_1.LTFPrivileges },
        { cardName: CardName_1.CardName.GRAND_LUNA_ACADEMY, Factory: GrandLunaAcademy_1.GrandLunaAcademy },
        { cardName: CardName_1.CardName.LUNA_ECUMENOPOLIS, Factory: LunaEcumenopolis_1.LunaEcumenopolis },
        { cardName: CardName_1.CardName.ORBITAL_POWER_GRID, Factory: OrbitalPowerGrid_1.OrbitalPowerGrid },
        { cardName: CardName_1.CardName.PROCESSOR_FACTORY, Factory: ProcessorFactory_1.ProcessorFactory },
        { cardName: CardName_1.CardName.LUNAR_STEEL, Factory: LunarSteel_1.LunarSteel },
        { cardName: CardName_1.CardName.RUST_EATING_BACTERIA, Factory: RustEatingBacteria_1.RustEatingBacteria },
        { cardName: CardName_1.CardName.SOLAR_PANEL_FOUNDRY, Factory: SolarPanelFoundry_1.SolarPanelFoundry },
        { cardName: CardName_1.CardName.MOON_TETHER, Factory: MoonTether_1.MoonTether },
    ],
    corporationCards: [
        { cardName: CardName_1.CardName.NANOTECH_INDUSTRIES, Factory: NanotechIndustries_1.NanotechIndustries },
        { cardName: CardName_1.CardName.TEMPEST_CONSULTANCY, Factory: TempestConsultancy_1.TempestConsultancy, compatibility: GameModule_1.GameModule.Turmoil },
        { cardName: CardName_1.CardName.THE_DARKSIDE_OF_THE_MOON_SYNDICATE, Factory: TheDarksideofTheMoonSyndicate_1.TheDarksideofTheMoonSyndicate },
        { cardName: CardName_1.CardName.LUNA_HYPERLOOP_CORPORATION, Factory: LunaHyperloopCorporation_1.LunaHyperloopCorporation },
        { cardName: CardName_1.CardName.CRESCENT_RESEARCH_ASSOCIATION, Factory: CrescentResearchAssociation_1.CrescentResearchAssociation },
        { cardName: CardName_1.CardName.LUNA_FIRST_INCORPORATED, Factory: LunaFirstIncorporated_1.LunaFirstIncorporated },
        { cardName: CardName_1.CardName.THE_GRAND_LUNA_CAPITAL_GROUP, Factory: TheGrandLunaCapitalGroup_1.TheGrandLunaCapitalGroup },
        { cardName: CardName_1.CardName.INTRAGEN_SANCTUARY_HEADQUARTERS, Factory: IntragenSanctuaryHeadquarters_1.IntragenSanctuaryHeadquarters },
        { cardName: CardName_1.CardName.THE_ARCHAIC_FOUNDATION_INSTITUTE, Factory: TheArchaicFoundationInstitute_1.TheArchaicFoundationInstitute },
    ],
    standardProjects: [
        { cardName: CardName_1.CardName.MOON_COLONY_STANDARD_PROJECT, Factory: MoonColonyStandardProject_1.MoonColonyStandardProject },
        { cardName: CardName_1.CardName.MOON_COLONY_STANDARD_PROJECT_V2, Factory: MoonStandardProjectVariants_1.MoonColonyStandardProjectVariant2 },
        { cardName: CardName_1.CardName.MOON_MINE_STANDARD_PROJECT, Factory: MoonMineStandardProject_1.MoonMineStandardProject },
        { cardName: CardName_1.CardName.MOON_MINE_STANDARD_PROJECT_V2, Factory: MoonStandardProjectVariants_2.MoonMineStandardProjectVariant2 },
        { cardName: CardName_1.CardName.MOON_ROAD_STANDARD_PROJECT, Factory: MoonRoadStandardProject_1.MoonRoadStandardProject },
        { cardName: CardName_1.CardName.MOON_ROAD_STANDARD_PROJECT_V2, Factory: MoonStandardProjectVariants_3.MoonRoadStandardProjectVariant2 },
    ],
    preludeCards: [
        { cardName: CardName_1.CardName.FIRST_LUNAR_SETTLEMENT, Factory: FirstLunarSettlement_1.FirstLunarSettlement },
        { cardName: CardName_1.CardName.CORE_MINE, Factory: CoreMine_1.CoreMine },
        { cardName: CardName_1.CardName.BASIC_INFRASTRUCTURE, Factory: BasicInfrastructure_1.BasicInfrastructure, compatibility: GameModule_1.GameModule.Colonies },
        { cardName: CardName_1.CardName.LUNAR_PlANNING_OFFICE, Factory: LunarPlanningOffice_1.LunarPlanningOffice },
        { cardName: CardName_1.CardName.MINING_COMPLEX, Factory: MiningComplex_1.MiningComplex },
    ],
});
