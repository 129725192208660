"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.IndenturedWorkers = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var CardRenderer_1 = require("../render/CardRenderer");
var Size_1 = require("../render/Size");
var IndenturedWorkers = (function (_super) {
    __extends(IndenturedWorkers, _super);
    function IndenturedWorkers() {
        return _super.call(this, {
            cardType: CardType_1.CardType.EVENT,
            name: CardName_1.CardName.INDENTURED_WORKERS,
            cost: 0,
            metadata: {
                cardNumber: '195',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.text('next card', Size_1.Size.SMALL, true).colon().megacredits(-8);
                }),
                description: 'The next card you play this generation costs 8 M€ less.',
                victoryPoints: -1,
            },
        }) || this;
    }
    IndenturedWorkers.prototype.getCardDiscount = function (player) {
        if (player.lastCardPlayed !== undefined && player.lastCardPlayed.name === this.name) {
            return 8;
        }
        return 0;
    };
    IndenturedWorkers.prototype.play = function () {
        return undefined;
    };
    IndenturedWorkers.prototype.getVictoryPoints = function () {
        return -1;
    };
    return IndenturedWorkers;
}(Card_1.Card));
exports.IndenturedWorkers = IndenturedWorkers;
