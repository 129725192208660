"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PowerInfrastructure = void 0;
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var SelectAmount_1 = require("../../inputs/SelectAmount");
var CardName_1 = require("../../CardName");
var Resources_1 = require("../../Resources");
var CardRenderer_1 = require("../render/CardRenderer");
var PowerInfrastructure = (function (_super) {
    __extends(PowerInfrastructure, _super);
    function PowerInfrastructure() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.POWER_INFRASTRUCTURE,
            tags: [Tags_1.Tags.ENERGY, Tags_1.Tags.BUILDING],
            cost: 4,
            metadata: {
                cardNumber: '194',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Spend any amount of Energy and gain that amount of MC.', function (eb) {
                        eb.text('x').energy(1).startAction.megacredits(0).multiplier;
                    });
                }),
            },
        }) || this;
    }
    PowerInfrastructure.prototype.play = function (_player) {
        return undefined;
    };
    PowerInfrastructure.prototype.canAct = function (player) {
        return player.energy > 0;
    };
    PowerInfrastructure.prototype.action = function (player) {
        return new SelectAmount_1.SelectAmount('Select amount of energy to spend', 'Spend energy', function (amount) {
            player.deductResource(Resources_1.Resources.ENERGY, amount);
            player.addResource(Resources_1.Resources.MEGACREDITS, amount, { log: true });
            return undefined;
        }, 1, player.energy);
    };
    return PowerInfrastructure;
}(Card_1.Card));
exports.PowerInfrastructure = PowerInfrastructure;
