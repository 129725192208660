"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var PartyName_1 = require("@/turmoil/parties/PartyName");
var GlobalEvent_vue_1 = require("@/client/components/GlobalEvent.vue");
var AGENDA_HTML = new Map([
    ['mb01',
        "<div class=\"resource money party-resource\">1</div> /\n    <div class=\"resource-tag tag-building party-resource-tag\"></div>"],
    ['mb02',
        "<div class=\"resource money party-resource\">1</div> /\n    <div class=\"tile empty-tile-small\"></div>ON MARS"],
    ['sb01',
        "<div class=\"resource money party-resource\">1</div> /\n    <div class=\"resource-tag tag-science party-resource-tag\"></div>"],
    ['sb02',
        "<div class=\"resource money party-resource\">1</div> / 3\n    <div class=\"resource card card-small\"></div>"],
    ['ub01',
        "<div class=\"resource money party-resource\">1</div> /\n    <div class=\"resource-tag tag-venus party-resource-tag\"></div>\n    <div class=\"resource-tag tag-earth party-resource-tag\"></div>\n    <div class=\"resource-tag tag-jovian party-resource-tag\"></div>"],
    ['ub02',
        "<div class=\"resource money party-resource\">1</div> /\n    <div class=\"resource-tag tag-space party-resource-tag\"></div>"],
    ['kb01',
        "<div class=\"resource money party-resource\">1</div> /\n    <div class=\"production-box party-production-box\">\n      <div class=\"heat production\"></div>\n    </div>"],
    ['kb02',
        "<div class=\"resource heat party-resource\"></div> /\n    <div class=\"production-box party-production-box\">\n      <div class=\"heat production\"></div>\n    </div>"],
    ['rb01',
        "<div class=\"party-inferior-rating tile party-rating party-tile\">&lt;</div> :\n  <div class=\"rating tile party-rating party-tile\"></div>"],
    ['rb02',
        "<div class=\"party-inferior-rating tile party-rating party-tile\">&gt;</div> :\n  <div class=\"rating tile party-rating party-tile red-outline \"></div>"],
    ['gb01',
        "<div class=\"resource money party-resource\">1</div> /\n    <div class=\"resource-tag tag-plant party-resource-tag\"></div>\n    <div class=\"resource-tag tag-microbe party-resource-tag\"></div>\n    <div class=\"resource-tag tag-animal party-resource-tag\"></div>"],
    ['gb02',
        "<div class=\"resource money party-resource\">2</div> /\n    <div class=\"tile greenery-tile greenery-tile-small\"></div>"],
    ['mfp01',
        "<div class=\"policy-top-margin\"><div class=\"tile empty-tile-small\"></div> :\n    <span class=\"steel resource\"></span></div>"],
    ['mfp02',
        "<div class=\"policy-top-margin\"><div class=\"resource-tag tag-building\"></div> : <div class=\"money resource\">2</div></div>"],
    ['mfp03',
        "<div class=\"policy-top-margin\"><div class=\"resource steel\"></div> : +<div class=\"resource money\">1</div></div>"],
    ['mfp04',
        "<span class=\"money resource\">4</span>\n     <span class=\"red-arrow-3x\"></span>\n    <div class=\"resource card card-with-border policy-card-with-tag\"><div class=\"card-icon tag-building\"></div></div>"],
    ['sp01',
        "<span class=\"money resource\">10</span>\n    <span class=\"red-arrow\"></span>\n    <span class=\"card card-with-border resource party-resource\"></span>\n    <span class=\"card card-with-border resource party-resource\"></span>\n    <span class=\"card card-with-border resource party-resource\"></span>"],
    ['sp02',
        "<span>\n    <div class=\"tile oxygen-tile req-tile-small\" style=\"margin: 10px -5px;\"></div>\n    <div class=\"tile ocean-tile req-tile-small\"></div>\n    <div class=\"tile temperature-tile req-tile-small\"></div>\n    : \u00B1 2</span>"],
    ['sp03', "<span>\n    <div class=\"tile oxygen-tile req-tile-small\" style=\"margin: 10px -5px;\"></div>\n    <div class=\"tile ocean-tile req-tile-small\"></div>\n    <div class=\"tile temperature-tile req-tile-small\"></div>\n    : <div class=\"resource card card-with-border\"></div></span>"],
    ['sp04', "<div class=\"scientists-requisite\"><div class=\"resource-tag tag-science party-resource-tag\"></div></div>"],
    ['up01',
        "<div class=\"policy-top-margin\"><div class=\"resource titanium\"></div> :\n    + <div class=\"resource money\">1</div></div>"],
    ['up02',
        "<div class=\"policy-top-margin\">\n    <span class=\"money resource\">4</span>\n    <span class=\"red-arrow-3x\"></span>2<span class=\"titanium resource\"></span> / 2<span class=\"floater resource\"></span>\n    </div>"],
    ['up03',
        "<span class=\"money resource\">4</span>\n    <span class=\"red-arrow-3x\"></span>\n    <div class=\"resource card card-with-border policy-card-with-tag\"><div class=\"card-icon tag-space\"></div></div>"],
    ['up04', "<div class=\"policy-top-margin\"><div class=\"resource-tag tag-space\"></div> : <div class=\"money resource\">-2</div></div>"],
    ['kp01',
        "<span class=\"money resource\">10</span>\n    <span class=\"red-arrow-infinity\"></span>\n    <div class=\"production-box production-box-size2\">\n      <div class=\"energy production\"></div>\n      <div class=\"heat production\"></div>\n    </div>"],
    ['kp02', "<div class=\"tile temperature-tile req-tile-small\" style=\"margin-right:5px;\"></div> : <span class=\"money resource\">3</span>"],
    ['kp03',
        "6 <span class=\"heat resource\"></span>\n    <span class=\"red-arrow-infinity\"></span>\n    <div class=\"tile temperature-tile\"></div>"],
    ['kp04',
        "<div class=\"policy-top-margin\"><div class=\"tile empty-tile-small\"></div> :\n    <span class=\"heat resource\"></span><span class=\"heat resource\"></span></div>"],
    ['rp01',
        "<div class=\"policy-top-margin\">\n    <div class=\"rating tile\"></div> :\n    <div class=\"resource money\">-3</div>\n    </div>"],
    ['rp02', "<div class=\"policy-top-margin\"><div class=\"tile empty-tile-small\"></div> : <span class=\"money resource\">-3</span></div>"],
    ['rp03',
        "<span class=\"money resource\">4</span>\n    <span class=\"red-arrow-3x\"></span>\n    <div class=\"tile oxygen-tile req-tile-small red-outline\" style=\"margin: 10px -5px;\"></div> /\n    <div class=\"tile ocean-tile req-tile-small red-outline\"></div> /\n    <div class=\"tile temperature-tile req-tile-small red-outline\"></div>"],
    ['rp04',
        "<div class=\"tile oxygen-tile req-tile-small\" style=\"margin: 10px -5px;\"></div>\n    <div class=\"tile ocean-tile req-tile-small\"></div>\n    <div class=\"tile temperature-tile req-tile-small\"></div>\n    : <div class=\"production-box production-box-size2\" style=\"margin-left:5px;\">\n      <div class=\"production-prefix minus\"></div><div class=\"money production\">1</div>\n    </div>"],
    ['gp01', "<div class=\"tile greenery-tile\"></div> : <div class=\"resource money\">4</div>"],
    ['gp02',
        "<div class=\"policy-top-margin\"><div class=\"tile empty-tile-small\"></div> :\n    <span class=\"plant resource\"></span></div>"],
    ['gp03',
        "<div class=\"policy-top-margin\">\n    <div class=\"resource-tag tag-plant party-resource-tag\"></div>\n    <div class=\"resource-tag tag-microbe party-resource-tag\"></div>\n    <div class=\"resource-tag tag-animal party-resource-tag\"></div> : <div class=\"resource money\">2</div>\n    </div>"],
    ['gp04',
        "<div class=\"policy-top-margin\">\n    <span class=\"money resource\">5</span>\n    <span class=\"red-arrow-3x\"></span>3<span class=\"plant resource\"></span> / 2<span class=\"microbe resource\"></span>\n    </div>"],
]);
exports.default = vue_1.default.extend({
    name: 'turmoil',
    props: {
        turmoil: {
            type: Object,
        },
    },
    methods: {
        partyNameToCss: function (party) {
            if (party === undefined) {
                console.warn('no party provided');
                return '';
            }
            return party.toLowerCase().split(' ').join('_');
        },
        getBonus: function (party) {
            var politicalAgendas = this.turmoil.politicalAgendas;
            var bonusId = undefined;
            if (politicalAgendas !== undefined) {
                switch (party) {
                    case PartyName_1.PartyName.MARS:
                        bonusId = politicalAgendas.marsFirst.bonusId;
                        break;
                    case PartyName_1.PartyName.SCIENTISTS:
                        bonusId = politicalAgendas.scientists.bonusId;
                        break;
                    case PartyName_1.PartyName.UNITY:
                        bonusId = politicalAgendas.unity.bonusId;
                        break;
                    case PartyName_1.PartyName.KELVINISTS:
                        bonusId = politicalAgendas.kelvinists.bonusId;
                        break;
                    case PartyName_1.PartyName.REDS:
                        bonusId = politicalAgendas.reds.bonusId;
                        break;
                    case PartyName_1.PartyName.GREENS:
                        bonusId = politicalAgendas.greens.bonusId;
                        break;
                }
            }
            if (bonusId !== undefined) {
                var bonus = AGENDA_HTML.get(bonusId);
                return bonus || "No ruling Bonus";
            }
            return "No ruling Bonus";
        },
        getPolicy: function (partyName) {
            var politicalAgendas = this.turmoil.politicalAgendas;
            var policyId = undefined;
            if (politicalAgendas !== undefined) {
                switch (partyName) {
                    case PartyName_1.PartyName.MARS:
                        policyId = politicalAgendas.marsFirst.policyId;
                        break;
                    case PartyName_1.PartyName.SCIENTISTS:
                        policyId = politicalAgendas.scientists.policyId;
                        break;
                    case PartyName_1.PartyName.UNITY:
                        policyId = politicalAgendas.unity.policyId;
                        break;
                    case PartyName_1.PartyName.KELVINISTS:
                        policyId = politicalAgendas.kelvinists.policyId;
                        break;
                    case PartyName_1.PartyName.REDS:
                        policyId = politicalAgendas.reds.policyId;
                        break;
                    case PartyName_1.PartyName.GREENS:
                        policyId = politicalAgendas.greens.policyId;
                        break;
                }
            }
            if (policyId !== undefined) {
                var policy = AGENDA_HTML.get(policyId);
                return policy || "No ruling Policy";
            }
            return '<p>' + this.$t('No ruling Policy') + '</p>';
        },
        toggleMe: function () {
            var currentState = this.isVisible();
            this.$root.setVisibilityState('turmoil_parties', !currentState);
        },
        isVisible: function () {
            return this.$root.getVisibilityState('turmoil_parties');
        },
    },
    components: {
        'global-event': GlobalEvent_vue_1.default,
    },
});
