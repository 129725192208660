"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquatorialMagnetizer = void 0;
var Tags_1 = require("../Tags");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var constants_1 = require("../../constants");
var CardRenderer_1 = require("../render/CardRenderer");
var EquatorialMagnetizer = (function (_super) {
    __extends(EquatorialMagnetizer, _super);
    function EquatorialMagnetizer() {
        return _super.call(this, {
            cardType: CardType_1.CardType.ACTIVE,
            name: CardName_1.CardName.EQUATORIAL_MAGNETIZER,
            tags: [Tags_1.Tags.BUILDING],
            cost: 11,
            metadata: {
                cardNumber: '015',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.action('Decrease your Energy production 1 step to increase your terraform rating 1 step.', function (eb) {
                        eb.production(function (pb) { return pb.energy(1); }).startAction.tr(1);
                    });
                }),
            },
        }) || this;
    }
    EquatorialMagnetizer.prototype.play = function () {
        return undefined;
    };
    EquatorialMagnetizer.prototype.canAct = function (player) {
        var hasEnergyProduction = player.getProduction(Resources_1.Resources.ENERGY) >= 1;
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS)) {
            return player.canAfford(constants_1.REDS_RULING_POLICY_COST) && hasEnergyProduction;
        }
        return hasEnergyProduction;
    };
    EquatorialMagnetizer.prototype.action = function (player) {
        player.addProduction(Resources_1.Resources.ENERGY, -1);
        player.increaseTerraformRating();
        return undefined;
    };
    return EquatorialMagnetizer;
}(Card_1.Card));
exports.EquatorialMagnetizer = EquatorialMagnetizer;
