"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.NeutralizerFactory = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var CardName_1 = require("../../CardName");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var constants_1 = require("../../constants");
var CardRequirements_1 = require("../CardRequirements");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var NeutralizerFactory = (function (_super) {
    __extends(NeutralizerFactory, _super);
    function NeutralizerFactory() {
        return _super.call(this, {
            name: CardName_1.CardName.NEUTRALIZER_FACTORY,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.VENUS],
            cost: 7,
            requirements: CardRequirements_1.CardRequirements.builder(function (b) { return b.venus(10); }),
            metadata: {
                cardNumber: '240',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.venus(1);
                }),
                description: 'Requires Venus 10%. Increase the Venus track 1 step.',
            },
        }) || this;
    }
    ;
    NeutralizerFactory.prototype.canPlay = function (player) {
        var globalRequirementsMet = _super.prototype.canPlay.call(this, player);
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS)) {
            return player.canAfford(player.getCardCost(this) + constants_1.REDS_RULING_POLICY_COST, { floaters: true }) && globalRequirementsMet;
        }
        return globalRequirementsMet;
    };
    NeutralizerFactory.prototype.play = function (player) {
        player.game.increaseVenusScaleLevel(player, 1);
        return undefined;
    };
    return NeutralizerFactory;
}(Card_1.Card));
exports.NeutralizerFactory = NeutralizerFactory;
