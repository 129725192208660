"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreliminaryDarkside = void 0;
var CardName_1 = require("../../CardName");
var CardType_1 = require("../CardType");
var Tags_1 = require("../Tags");
var CardRenderer_1 = require("../render/CardRenderer");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var OrOptions_1 = require("../../inputs/OrOptions");
var SelectOption_1 = require("../../inputs/SelectOption");
var Resources_1 = require("../../Resources");
var Card_1 = require("../Card");
var PreliminaryDarkside = (function (_super) {
    __extends(PreliminaryDarkside, _super);
    function PreliminaryDarkside() {
        return _super.call(this, {
            name: CardName_1.CardName.PRELIMINARY_DARKSIDE,
            cardType: CardType_1.CardType.EVENT,
            tags: [Tags_1.Tags.MOON],
            cost: 13,
            metadata: {
                description: 'Gain 3 titanium or 4 steel. Raise the Mining Rate 1 step.',
                cardNumber: 'M63',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.titanium(3).digit.or().steel(4).digit.br;
                    b.moonMiningRate();
                }),
            },
        }) || this;
    }
    ;
    PreliminaryDarkside.prototype.play = function (player) {
        MoonExpansion_1.MoonExpansion.raiseMiningRate(player);
        return new OrOptions_1.OrOptions(new SelectOption_1.SelectOption('Gain 3 titanium.', 'Gain titanium', function () {
            player.addResource(Resources_1.Resources.TITANIUM, 3, { log: true });
            return undefined;
        }), new SelectOption_1.SelectOption('Gain 4 steel.', 'Gain steel', function () {
            player.addResource(Resources_1.Resources.STEEL, 4, { log: true });
            return undefined;
        }));
    };
    return PreliminaryDarkside;
}(Card_1.Card));
exports.PreliminaryDarkside = PreliminaryDarkside;
