"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrbitalReflectors = void 0;
var Tags_1 = require("../Tags");
var CardType_1 = require("../CardType");
var Resources_1 = require("../../Resources");
var CardName_1 = require("../../CardName");
var constants_1 = require("../../constants");
var PartyHooks_1 = require("../../turmoil/parties/PartyHooks");
var PartyName_1 = require("../../turmoil/parties/PartyName");
var CardRenderer_1 = require("../render/CardRenderer");
var Card_1 = require("../Card");
var OrbitalReflectors = (function (_super) {
    __extends(OrbitalReflectors, _super);
    function OrbitalReflectors() {
        return _super.call(this, {
            name: CardName_1.CardName.ORBITAL_REFLECTORS,
            cardType: CardType_1.CardType.AUTOMATED,
            tags: [Tags_1.Tags.VENUS, Tags_1.Tags.SPACE],
            cost: 26,
            metadata: {
                cardNumber: '242',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.venus(2).br;
                    b.production(function (pb) {
                        pb.heat(2);
                    });
                }),
                description: 'Raise Venus 2 steps. Increase your heat production 2 steps.',
            },
        }) || this;
    }
    ;
    OrbitalReflectors.prototype.canPlay = function (player) {
        var remainingVenusSteps = (constants_1.MAX_VENUS_SCALE - player.game.getVenusScaleLevel()) / 2;
        var stepsRaised = Math.min(remainingVenusSteps, 2);
        if (PartyHooks_1.PartyHooks.shouldApplyPolicy(player, PartyName_1.PartyName.REDS)) {
            return player.canAfford(player.getCardCost(this) + constants_1.REDS_RULING_POLICY_COST * stepsRaised, { titanium: true, floaters: true });
        }
        return true;
    };
    OrbitalReflectors.prototype.play = function (player) {
        player.game.increaseVenusScaleLevel(player, 2);
        player.addProduction(Resources_1.Resources.HEAT, 2);
        return undefined;
    };
    return OrbitalReflectors;
}(Card_1.Card));
exports.OrbitalReflectors = OrbitalReflectors;
