"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LunaHyperloopCorporation = void 0;
var CardName_1 = require("../../CardName");
var MoonExpansion_1 = require("../../moon/MoonExpansion");
var Resources_1 = require("../../Resources");
var TileType_1 = require("../../TileType");
var Card_1 = require("../Card");
var CardType_1 = require("../CardType");
var CardRenderDynamicVictoryPoints_1 = require("../render/CardRenderDynamicVictoryPoints");
var CardRenderer_1 = require("../render/CardRenderer");
var Tags_1 = require("../Tags");
var LunaHyperloopCorporation = (function (_super) {
    __extends(LunaHyperloopCorporation, _super);
    function LunaHyperloopCorporation() {
        return _super.call(this, {
            cardType: CardType_1.CardType.CORPORATION,
            name: CardName_1.CardName.LUNA_HYPERLOOP_CORPORATION,
            tags: [Tags_1.Tags.MOON, Tags_1.Tags.BUILDING],
            startingMegaCredits: 38,
            metadata: {
                description: 'You start with 38 M€ and 4 steel.',
                cardNumber: '',
                renderData: CardRenderer_1.CardRenderer.builder(function (b) {
                    b.megacredits(38).steel(4).br;
                    b.action('Gain 1 M€ for each road tile on the Moon.', function (eb) {
                        eb.empty().startAction.megacredits(1).slash().moonRoad().any;
                    }).br,
                        b.vpText('1 VP for each road tile on the Moon.').br;
                }),
                victoryPoints: CardRenderDynamicVictoryPoints_1.CardRenderDynamicVictoryPoints.moonRoadTile(1, true),
            },
        }) || this;
    }
    LunaHyperloopCorporation.prototype.play = function (player) {
        player.steel += 4;
        return undefined;
    };
    LunaHyperloopCorporation.prototype.canAct = function () {
        return true;
    };
    LunaHyperloopCorporation.prototype.action = function (player) {
        var roadTileCount = MoonExpansion_1.MoonExpansion.tiles(player.game, TileType_1.TileType.MOON_ROAD, { surfaceOnly: true }).length;
        player.addResource(Resources_1.Resources.MEGACREDITS, roadTileCount, { log: true });
        return undefined;
    };
    LunaHyperloopCorporation.prototype.getVictoryPoints = function (player) {
        return MoonExpansion_1.MoonExpansion.tiles(player.game, TileType_1.TileType.MOON_ROAD, { surfaceOnly: true }).length;
    };
    return LunaHyperloopCorporation;
}(Card_1.Card));
exports.LunaHyperloopCorporation = LunaHyperloopCorporation;
