"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Revolution = void 0;
var GlobalEventName_1 = require("./GlobalEventName");
var PartyName_1 = require("../parties/PartyName");
var Tags_1 = require("../../cards/Tags");
var Revolution = (function () {
    function Revolution() {
        this.name = GlobalEventName_1.GlobalEventName.REVOLUTION;
        this.description = 'Count Earth tags and ADD(!) influence. The player(s) with most (at least 1) loses 2 TR, and 2nd most (at least 1) loses 1 TR. SOLO: Lose 2 TR if the sum is 4 or more.';
        this.revealedDelegate = PartyName_1.PartyName.UNITY;
        this.currentDelegate = PartyName_1.PartyName.MARS;
    }
    Revolution.prototype.resolve = function (game, turmoil) {
        var _this = this;
        if (game.isSoloMode()) {
            if (this.getScore(game.getPlayers()[0], turmoil) >= 4) {
                game.getPlayers()[0].decreaseTerraformRating();
                game.getPlayers()[0].decreaseTerraformRating();
            }
        }
        else {
            var players = __spreadArray([], game.getPlayers()).sort(function (p1, p2) { return _this.getScore(p2, turmoil) - _this.getScore(p1, turmoil); });
            if (this.getScore(players[0], turmoil) > this.getScore(players[1], turmoil)) {
                players[0].decreaseTerraformRatingSteps(2);
                players.shift();
                if (players.length === 1 && this.getScore(players[0], turmoil) > 0) {
                    players[0].decreaseTerraformRating();
                }
                else if (players.length > 1) {
                    if (this.getScore(players[0], turmoil) > this.getScore(players[1], turmoil)) {
                        players[0].decreaseTerraformRating();
                    }
                    else {
                        var score = this.getScore(players[0], turmoil);
                        while (players.length > 0 && this.getScore(players[0], turmoil) === score) {
                            if (this.getScore(players[0], turmoil) > 0) {
                                players[0].decreaseTerraformRating();
                            }
                            players.shift();
                        }
                    }
                }
            }
            else {
                var score = this.getScore(players[0], turmoil);
                while (players.length > 0 && this.getScore(players[0], turmoil) === score) {
                    if (this.getScore(players[0], turmoil) > 0) {
                        players[0].decreaseTerraformRatingSteps(2);
                    }
                    players.shift();
                }
            }
        }
    };
    Revolution.prototype.getScore = function (player, turmoil) {
        return player.getTagCount(Tags_1.Tags.EARTH, false, false) + turmoil.getPlayerInfluence(player);
    };
    return Revolution;
}());
exports.Revolution = Revolution;
